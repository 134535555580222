@charset "UTF-8";
/* ------------------------------ media query(pc-first) @include */
/*!
Theme Name: Cocoon Child
Template:   cocoon-master
Version:    1.1.0
*/
/************************************
** 子テーマ用のスタイルを書く　サイト名
************************************/
/*! purgecss start ignore */
.body {
  font-family: "yu gothic medium", "游ゴシック medium", yugothic, "游ゴシック体", "ヒラギノ角ゴ pro w3", "メイリオ", sans-serif;
}
@media (max-width: 1920px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1240px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1024px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1023px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 834px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 480px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 375px) {
  .body {
    background: inherit;
  }
}

.main,
.sidebar {
  padding: 0;
}

.main p {
  margin: 1.2em 0;
}
@media (max-width: 1023px) {
  main.main {
    padding: 0;
  }
}
@media (max-width: 834px) {
  main.main {
    padding: 0;
  }
}

.full {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  width: auto;
}

img {
  vertical-align: middle;
}

.ghorst-lg {
  display: none;
}
@media (max-width: 834px) {
  .ghorst-lg {
    display: block;
  }
}

.ghorst-tab {
  display: none;
}
@media (max-width: 480px) {
  .ghorst-tab {
    display: block;
  }
}

.ghorst-pd {
  display: block;
}
@media (max-width: 1023px) {
  .ghorst-pd {
    display: none;
  }
}

.ghorst-sm {
  display: block;
}
@media (max-width: 480px) {
  .ghorst-sm {
    display: none;
  }
}

.go-to-top-button {
  color: white;
}

.text-idt1 {
  display: inline-block;
  text-indent: -1em;
  padding-left: 1em;
}

.text-idt2 {
  display: inline-block;
  text-indent: -2em;
  padding-left: 2em;
}

.text-idt3 {
  display: inline-block;
  text-indent: -3em;
  padding-left: 3em;
}

/* ------------------------------ パンくず */
.breadcrumbs {
  font-size: 14px;
  color: #5c5c5c;
}
.breadcrumbs a {
  text-decoration: none;
}

.breadcrumb-page.wrap.wrap-padding {
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 480px) {
  .breadcrumb-page.wrap.wrap-padding {
    width: 92%;
  }
}

/* ------------------------------ キャプション */
.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.is-resized > figcaption,
.wp-block-image figcaption {
  text-align: center;
}

/* ------------------------------------------------------------------------------------------ 見出し */
/* ------------------------------------------------------------ 見出しリセット */
.article h2 {
  margin: 0;
  padding: 0;
  background: none;
  border-radius: 0;
}

.article h3 {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
}

.article h4 {
  margin: 0;
  padding: 0;
  border: none;
}

.article h5 {
  margin: 0;
  padding: 0;
  border: none;
}

.article h6 {
  margin: 0;
  padding: 0;
  border: none;
}

.sidebar h3 {
  border-radius: 0;
}

.sidebar h4 {
  border-radius: 0;
}

/* ------------------------------------------------------------ h1 */
.article h1.entry-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .article h1.entry-title {
    margin-bottom: 1.5rem !important;
    font-size: 22px !important;
  }
}
@media (max-width: 480px) {
  .article h1.entry-title {
    font-size: 20px !important;
  }
}
.article h1.entry-title::before, .article h1.entry-title::after {
  color: #ff662e !important;
  font-family: icomoon !important;
  font-size: 22px !important;
  content: "\e926" !important;
}
@media (max-width: 834px) {
  .article h1.entry-title::before, .article h1.entry-title::after {
    font-size: 14px !important;
  }
}
@media (max-width: 480px) {
  .article h1.entry-title::before, .article h1.entry-title::after {
    font-size: 12px !important;
  }
}
.article h1.entry-title::before {
  margin-right: 18px;
}
.article h1.entry-title::after {
  margin-left: 18px;
  transform: scale(-1, 1);
}

/* ------------------------------------------------------------ h2 */
/* ------------------------------------------------------------ h3 */
.article h3 {
  width: 100%;
}

/* ------------------------------ サイドバー　h3 */
/* ------------------------------------------------------------ h4 */
/* ------------------------------------------------------------ h5 */
/* ------------------------------------------------------------ p */
/* ------------------------------ サイドバー　h4 */
/* ------------------------------------------------------------ カテゴリーリンクアイコンラベル色*/
/* ------------------------------------------------------------------------------------------ ヘッダー */
/* ------------------------------------------------------------ メインのヘッダー */
.csz-front-logo .site-logo-image {
  margin-top: 10px;
}

.header-container {
  border-top: 6px solid #ff662e;
}

.header-container-in {
  flex-direction: column;
}
.header-container-in .header .header-in {
  align-items: flex-start;
  position: relative;
}
@media (max-width: 480px) {
  .header-container-in .header .header-in .box-header-title {
    width: 100%;
  }
}
.header-container-in .header .header-in .box-conv-outer {
  position: absolute;
  top: 12px;
  right: 0;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .header-container-in .header .header-in .box-conv-outer {
    top: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 480px) {
  .header-container-in .header .header-in .box-conv-outer {
    display: none;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-tel-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 98px;
  margin-right: 2rem;
  border: 2px solid #ff662e;
  border-radius: 12px;
}
@media (max-width: 1024px) {
  .header-container-in .header .header-in .box-conv-outer .box-tel-outer {
    height: 91px;
    margin-right: 1rem;
  }
}
@media (max-width: 1023px) {
  .header-container-in .header .header-in .box-conv-outer .box-tel-outer {
    margin-right: 8px;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-tel {
  width: 100%;
  padding: 0 6px 0 10px;
  color: white;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  background-color: #ff662e;
  transition: all 0.2s;
}
@media (max-width: 1024px) {
  .header-container-in .header .header-in .box-conv-outer .box-tel {
    font-size: 20px;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-tel:hover {
  color: black;
  background-color: #ffa333;
}
.header-container-in .header .header-in .box-conv-outer .box-tel::before {
  content: "\e918";
  font-family: icomoon;
  font-size: 18px;
  margin-right: 6px;
}
.header-container-in .header .header-in .box-conv-outer .box-tel span {
  font-size: 18px;
}
@media (max-width: 1023px) {
  .header-container-in .header .header-in .box-conv-outer .box-tel span {
    font-size: 14px;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-tel-info {
  padding: 0 12px;
  font-size: 12px;
  line-height: 1.4;
}
.header-container-in .header .header-in .box-conv-outer .box-mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 98px;
  padding: 4px 30px;
  color: white;
  font-size: 21px;
  font-weight: 700;
  background-color: #43896b;
  border-radius: 12px;
  text-decoration: none;
  transition: all 0.2s;
}
@media (max-width: 1024px) {
  .header-container-in .header .header-in .box-conv-outer .box-mail {
    height: 91px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-mail:hover {
  color: black;
  background-color: #ffa333;
}
.header-container-in .header .header-in .box-conv-outer .box-mail span {
  padding: 12px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
@media (max-width: 1023px) {
  .header-container-in .header .header-in .box-conv-outer .box-mail span {
    padding: 6px;
  }
}
.header-container-in .header .header-in .box-conv-outer .box-mail span::before {
  content: "\e905";
  font-family: icomoon;
  font-size: 22px;
  margin-right: 16px;
}
.header-container-in .logo-header {
  padding-top: 0;
}
@media (max-width: 1023px) {
  .header-container-in .logo-header {
    padding: 8px 0;
  }
}
@media (max-width: 480px) {
  .header-container-in .logo-header {
    padding: 4px 0;
  }
}
.header-container-in .logo-header img {
  max-height: 64px;
}
@media (max-width: 1240px) {
  .header-container-in .logo-header img {
    max-height: 48px;
    margin-left: 12px;
  }
}
@media (max-width: 1023px) {
  .header-container-in .logo-header img {
    max-height: 48px;
  }
}
.header-container-in .tagline {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 8px;
  font-size: 1rem;
  color: white;
  background-color: #ff662e;
  border-radius: 0 0 12px 12px;
}
@media (max-width: 834px) {
  .header-container-in .tagline {
    margin-bottom: 0;
    margin-left: 8px;
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .header-container-in .tagline {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* ------------------------------------------------------------------------------------------ ナビ */
.navi {
  margin-top: 6px;
  padding-top: 6px;
  height: 60px;
}
@media (max-width: 1023px) {
  .navi {
    height: auto;
  }
}

.navi-in {
  background-color: white;
}

.navi-in > ul {
  justify-content: flex-start;
}
.navi-in > ul .caption-wrap {
  width: auto;
}

.navi-in a {
  display: flex;
  align-items: center;
  width: auto;
  margin-left: 22px;
  font-size: 18px;
  transition: all 0s;
}
@media (max-width: 1240px) {
  .navi-in a {
    margin-left: 16px;
  }
}
@media (max-width: 1023px) {
  .navi-in a {
    height: auto;
  }
}
.navi-in a:hover {
  background-color: #fff0eb;
  transition: all 0s;
}

#navi .navi-in > ul > li > a {
  padding: 0 1em;
  border: none;
}
@media (max-width: 1240px) {
  #navi .navi-in > ul > li > a {
    padding: 0 0.5em;
  }
}

.navi-in .menu-item:hover .sub-menu {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.5rem;
}
.navi-in .icn-menu-intro.menu-item:hover .sub-menu {
  grid-template-rows: repeat(3, auto);
}
.navi-in .icn-menu-case.menu-item:hover .sub-menu {
  grid-template-rows: repeat(5, auto);
}
.navi-in .icn-menu-about.menu-item:hover .sub-menu {
  grid-template-rows: repeat(2, auto);
}

.navi-in > ul .sub-menu {
  min-width: auto;
  width: auto;
  margin-top: -7px;
  margin-left: 3px;
  padding: 6px 24px;
  background-color: #fff0eb;
  filter: drop-shadow(2px 2px 4px rgba(10, 10, 10, 0.25));
}
.navi-in > ul .sub-menu li {
  height: 48px;
}
.navi-in > ul .sub-menu a {
  width: 100%;
  margin-left: 0;
}
.navi-in > ul .sub-menu a:hover {
  border-top: 1px solid #ffa585;
  border-bottom: 1px solid #ffa585;
  color: #cc3600;
}
.navi-in > ul .sub-menu a:hover .item-label::before {
  color: #ffa585;
}

.header-container-in .navi {
  width: auto;
}

.icn-menu-intro .item-label,
.icn-menu-case .item-label,
.icn-menu-price .item-label,
.icn-menu-about .item-label,
.icn-menu-blog .item-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icn-menu-intro .item-label::before,
.icn-menu-case .item-label::before,
.icn-menu-price .item-label::before,
.icn-menu-about .item-label::before,
.icn-menu-blog .item-label::before {
  font-family: "icomoon";
  margin-right: 10px;
  font-size: 22px;
  color: #cc3600;
}
@media (max-width: 1240px) {
  .icn-menu-intro .item-label::before,
  .icn-menu-case .item-label::before,
  .icn-menu-price .item-label::before,
  .icn-menu-about .item-label::before,
  .icn-menu-blog .item-label::before {
    font-size: 20px;
  }
}

.icn-menu-intro .item-label::before {
  content: "\e902";
}

.icn-menu-case .item-label::before {
  content: "\e91b";
}

.icn-menu-price .item-label::before {
  content: "\e91c";
}

.icn-menu-about .item-label::before {
  content: "\e901";
}

.icn-menu-blog .item-label::before {
  content: "\e903";
}

/* ------------------------------------------------------------ swiper */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.swiper-container {
  width: 90%;
  max-width: 1600px;
  height: 800px;
  position: relative;
  z-index: 50;
}
@media (max-width: 1920px) {
  .swiper-container {
    height: 700px;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    height: 360px;
  }
}
@media (max-width: 480px) {
  .swiper-container {
    width: 100%;
    height: 260px;
  }
}
.swiper-container .swiper-wrapper {
  margin-top: 0;
}
.swiper-container .swiper-wrapper .swiper-slide {
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: cover;
}
.swiper-container .swiper-wrapper .swiper-slide img {
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 1920px;
  object-fit: cover;
  object-position: center;
}
.swiper-container .swiper-wrapper .swiper-slide p {
  display: block;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 10%;
  color: #ffffff;
  text-decoration: none;
  text-align: right;
  font-size: 3.5rem;
  font-weight: bold;
  filter: drop-shadow(4px 4px 4px rgba(10, 10, 10, 0.5));
}
@media (max-width: 1023px) {
  .swiper-container .swiper-wrapper .swiper-slide p {
    font-size: 3rem;
  }
}
@media (max-width: 480px) {
  .swiper-container .swiper-wrapper .swiper-slide p {
    right: 0;
    text-align: center;
    font-size: 2rem;
  }
}
@media (orientation: landscape) and (max-height: 480px) {
  .swiper-container .swiper-wrapper .swiper-slide p {
    top: 200px;
  }
}
.swiper-container .swiper-wrapper .swiper-slide p {
  margin: 0;
}
@media (max-width: 480px) {
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    display: none;
  }
}
.swiper-container .swiper-slide-active p {
  animation-name: fade-in;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: both;
}

/* ------------------------------------------------------------------------------------------ コンテンツ（全体）セクション */
.content {
  margin-top: 0;
}

.icon2-more-arrow {
  margin-left: 8px;
}
.icon2-more-arrow::before {
  color: #8f8f8f;
}

/* ------------------------------------------------------------ ヒーロー */
.area-hero,
.area-hero-treatment,
.area-hero-introduction,
.area-hero-price,
.area-hero-about,
.area-hero-case-head-shoulder-neck,
.area-hero-case-waist-back,
.area-hero-case-arm,
.area-hero-case-leg,
.area-hero-case-fatigue,
.area-hero-case-pelvis,
.area-hero-case-stretch,
.area-hero-case-others,
.area-hero-customer-voice {
  position: relative;
  background-image: linear-gradient(to top, #FFD9CB 0%, white 100%);
  margin-top: 8px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .area-hero,
  .area-hero-treatment,
  .area-hero-introduction,
  .area-hero-price,
  .area-hero-about,
  .area-hero-case-head-shoulder-neck,
  .area-hero-case-waist-back,
  .area-hero-case-arm,
  .area-hero-case-leg,
  .area-hero-case-fatigue,
  .area-hero-case-pelvis,
  .area-hero-case-stretch,
  .area-hero-case-others,
  .area-hero-customer-voice {
    margin-top: 0;
  }
}
.area-hero::before, .area-hero::after,
.area-hero-treatment::before,
.area-hero-treatment::after,
.area-hero-introduction::before,
.area-hero-introduction::after,
.area-hero-price::before,
.area-hero-price::after,
.area-hero-about::before,
.area-hero-about::after,
.area-hero-case-head-shoulder-neck::before,
.area-hero-case-head-shoulder-neck::after,
.area-hero-case-waist-back::before,
.area-hero-case-waist-back::after,
.area-hero-case-arm::before,
.area-hero-case-arm::after,
.area-hero-case-leg::before,
.area-hero-case-leg::after,
.area-hero-case-fatigue::before,
.area-hero-case-fatigue::after,
.area-hero-case-pelvis::before,
.area-hero-case-pelvis::after,
.area-hero-case-stretch::before,
.area-hero-case-stretch::after,
.area-hero-case-others::before,
.area-hero-case-others::after,
.area-hero-customer-voice::before,
.area-hero-customer-voice::after {
  content: "";
  display: block;
  position: absolute;
  mix-blend-mode: multiply;
}
@media (max-width: 1024px) {
  .area-hero::before, .area-hero::after,
  .area-hero-treatment::before,
  .area-hero-treatment::after,
  .area-hero-introduction::before,
  .area-hero-introduction::after,
  .area-hero-price::before,
  .area-hero-price::after,
  .area-hero-about::before,
  .area-hero-about::after,
  .area-hero-case-head-shoulder-neck::before,
  .area-hero-case-head-shoulder-neck::after,
  .area-hero-case-waist-back::before,
  .area-hero-case-waist-back::after,
  .area-hero-case-arm::before,
  .area-hero-case-arm::after,
  .area-hero-case-leg::before,
  .area-hero-case-leg::after,
  .area-hero-case-fatigue::before,
  .area-hero-case-fatigue::after,
  .area-hero-case-pelvis::before,
  .area-hero-case-pelvis::after,
  .area-hero-case-stretch::before,
  .area-hero-case-stretch::after,
  .area-hero-case-others::before,
  .area-hero-case-others::after,
  .area-hero-customer-voice::before,
  .area-hero-customer-voice::after {
    display: none;
  }
}
.area-hero::before,
.area-hero-treatment::before,
.area-hero-introduction::before,
.area-hero-price::before,
.area-hero-about::before,
.area-hero-case-head-shoulder-neck::before,
.area-hero-case-waist-back::before,
.area-hero-case-arm::before,
.area-hero-case-leg::before,
.area-hero-case-fatigue::before,
.area-hero-case-pelvis::before,
.area-hero-case-stretch::before,
.area-hero-case-others::before,
.area-hero-customer-voice::before {
  width: 500px;
  height: 600px;
  background-image: url(../img/parts/hero-back-02.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 5px;
  bottom: 40px;
}
.area-hero::after,
.area-hero-treatment::after,
.area-hero-introduction::after,
.area-hero-price::after,
.area-hero-about::after,
.area-hero-case-head-shoulder-neck::after,
.area-hero-case-waist-back::after,
.area-hero-case-arm::after,
.area-hero-case-leg::after,
.area-hero-case-fatigue::after,
.area-hero-case-pelvis::after,
.area-hero-case-stretch::after,
.area-hero-case-others::after,
.area-hero-customer-voice::after {
  width: 850px;
  height: 600px;
  background-image: url(../img/parts/hero-back-01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: 5px;
  bottom: 40px;
}
.area-hero .frame-hero-up,
.area-hero .frame-hero-down,
.area-hero-treatment .frame-hero-up,
.area-hero-treatment .frame-hero-down,
.area-hero-introduction .frame-hero-up,
.area-hero-introduction .frame-hero-down,
.area-hero-price .frame-hero-up,
.area-hero-price .frame-hero-down,
.area-hero-about .frame-hero-up,
.area-hero-about .frame-hero-down,
.area-hero-case-head-shoulder-neck .frame-hero-up,
.area-hero-case-head-shoulder-neck .frame-hero-down,
.area-hero-case-waist-back .frame-hero-up,
.area-hero-case-waist-back .frame-hero-down,
.area-hero-case-arm .frame-hero-up,
.area-hero-case-arm .frame-hero-down,
.area-hero-case-leg .frame-hero-up,
.area-hero-case-leg .frame-hero-down,
.area-hero-case-fatigue .frame-hero-up,
.area-hero-case-fatigue .frame-hero-down,
.area-hero-case-pelvis .frame-hero-up,
.area-hero-case-pelvis .frame-hero-down,
.area-hero-case-stretch .frame-hero-up,
.area-hero-case-stretch .frame-hero-down,
.area-hero-case-others .frame-hero-up,
.area-hero-case-others .frame-hero-down,
.area-hero-customer-voice .frame-hero-up,
.area-hero-customer-voice .frame-hero-down {
  vertical-align: baseline;
  width: auto;
  height: auto;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 70;
  object-fit: cover;
}
@media (max-width: 480px) {
  .area-hero .frame-hero-up,
  .area-hero .frame-hero-down,
  .area-hero-treatment .frame-hero-up,
  .area-hero-treatment .frame-hero-down,
  .area-hero-introduction .frame-hero-up,
  .area-hero-introduction .frame-hero-down,
  .area-hero-price .frame-hero-up,
  .area-hero-price .frame-hero-down,
  .area-hero-about .frame-hero-up,
  .area-hero-about .frame-hero-down,
  .area-hero-case-head-shoulder-neck .frame-hero-up,
  .area-hero-case-head-shoulder-neck .frame-hero-down,
  .area-hero-case-waist-back .frame-hero-up,
  .area-hero-case-waist-back .frame-hero-down,
  .area-hero-case-arm .frame-hero-up,
  .area-hero-case-arm .frame-hero-down,
  .area-hero-case-leg .frame-hero-up,
  .area-hero-case-leg .frame-hero-down,
  .area-hero-case-fatigue .frame-hero-up,
  .area-hero-case-fatigue .frame-hero-down,
  .area-hero-case-pelvis .frame-hero-up,
  .area-hero-case-pelvis .frame-hero-down,
  .area-hero-case-stretch .frame-hero-up,
  .area-hero-case-stretch .frame-hero-down,
  .area-hero-case-others .frame-hero-up,
  .area-hero-case-others .frame-hero-down,
  .area-hero-customer-voice .frame-hero-up,
  .area-hero-customer-voice .frame-hero-down {
    height: 15px;
  }
}
.area-hero .frame-hero-up,
.area-hero-treatment .frame-hero-up,
.area-hero-introduction .frame-hero-up,
.area-hero-price .frame-hero-up,
.area-hero-about .frame-hero-up,
.area-hero-case-head-shoulder-neck .frame-hero-up,
.area-hero-case-waist-back .frame-hero-up,
.area-hero-case-arm .frame-hero-up,
.area-hero-case-leg .frame-hero-up,
.area-hero-case-fatigue .frame-hero-up,
.area-hero-case-pelvis .frame-hero-up,
.area-hero-case-stretch .frame-hero-up,
.area-hero-case-others .frame-hero-up,
.area-hero-customer-voice .frame-hero-up {
  top: inherit;
  bottom: -1px;
  transform: scale(1, -1);
}
.area-hero .spn-top-mds,
.area-hero-treatment .spn-top-mds,
.area-hero-introduction .spn-top-mds,
.area-hero-price .spn-top-mds,
.area-hero-about .spn-top-mds,
.area-hero-case-head-shoulder-neck .spn-top-mds,
.area-hero-case-waist-back .spn-top-mds,
.area-hero-case-arm .spn-top-mds,
.area-hero-case-leg .spn-top-mds,
.area-hero-case-fatigue .spn-top-mds,
.area-hero-case-pelvis .spn-top-mds,
.area-hero-case-stretch .spn-top-mds,
.area-hero-case-others .spn-top-mds,
.area-hero-customer-voice .spn-top-mds {
  font-family: "メイリオ";
  font-size: 72px;
  font-weight: bold;
  color: white;
  text-align: start;
  letter-spacing: 4px;
  line-height: 1.5;
  padding: 4px;
  filter: drop-shadow(2px 2px 4px rgba(10, 10, 10, 0.7));
  position: absolute;
  bottom: 8%;
  right: 8%;
  z-index: 70;
}
@media (max-width: 1024px) {
  .area-hero .spn-top-mds,
  .area-hero-treatment .spn-top-mds,
  .area-hero-introduction .spn-top-mds,
  .area-hero-price .spn-top-mds,
  .area-hero-about .spn-top-mds,
  .area-hero-case-head-shoulder-neck .spn-top-mds,
  .area-hero-case-waist-back .spn-top-mds,
  .area-hero-case-arm .spn-top-mds,
  .area-hero-case-leg .spn-top-mds,
  .area-hero-case-fatigue .spn-top-mds,
  .area-hero-case-pelvis .spn-top-mds,
  .area-hero-case-stretch .spn-top-mds,
  .area-hero-case-others .spn-top-mds,
  .area-hero-customer-voice .spn-top-mds {
    font-size: 48px;
    bottom: 4%;
    right: 4%;
  }
}
@media (max-width: 480px) {
  .area-hero .spn-top-mds,
  .area-hero-treatment .spn-top-mds,
  .area-hero-introduction .spn-top-mds,
  .area-hero-price .spn-top-mds,
  .area-hero-about .spn-top-mds,
  .area-hero-case-head-shoulder-neck .spn-top-mds,
  .area-hero-case-waist-back .spn-top-mds,
  .area-hero-case-arm .spn-top-mds,
  .area-hero-case-leg .spn-top-mds,
  .area-hero-case-fatigue .spn-top-mds,
  .area-hero-case-pelvis .spn-top-mds,
  .area-hero-case-stretch .spn-top-mds,
  .area-hero-case-others .spn-top-mds,
  .area-hero-customer-voice .spn-top-mds {
    font-size: 36px;
    bottom: 4%;
    left: 3%;
    right: inherit;
  }
}
.area-hero ins,
.area-hero-treatment ins,
.area-hero-introduction ins,
.area-hero-price ins,
.area-hero-about ins,
.area-hero-case-head-shoulder-neck ins,
.area-hero-case-waist-back ins,
.area-hero-case-arm ins,
.area-hero-case-leg ins,
.area-hero-case-fatigue ins,
.area-hero-case-pelvis ins,
.area-hero-case-stretch ins,
.area-hero-case-others ins,
.area-hero-customer-voice ins {
  padding-left: 4px;
  text-decoration: overline;
}
.area-hero .swiper-container,
.area-hero-treatment .swiper-container,
.area-hero-introduction .swiper-container,
.area-hero-price .swiper-container,
.area-hero-about .swiper-container,
.area-hero-case-head-shoulder-neck .swiper-container,
.area-hero-case-waist-back .swiper-container,
.area-hero-case-arm .swiper-container,
.area-hero-case-leg .swiper-container,
.area-hero-case-fatigue .swiper-container,
.area-hero-case-pelvis .swiper-container,
.area-hero-case-stretch .swiper-container,
.area-hero-case-others .swiper-container,
.area-hero-customer-voice .swiper-container {
  position: relative;
}

.area-hero-treatment::before, .area-hero-treatment::after {
  z-index: 1;
}
.area-hero-treatment .box-hero-treatment {
  aspect-ratio: 16/9;
  width: 100%;
  height: 700px;
  text-align: center;
  position: relative;
  z-index: 10;
}
@media (max-width: 1920px) {
  .area-hero-treatment .box-hero-treatment {
    height: 620px;
  }
}
@media (max-width: 1024px) {
  .area-hero-treatment .box-hero-treatment {
    height: auto;
  }
}
.area-hero-treatment .box-hero-treatment img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .area-hero-treatment .box-hero-treatment img {
    height: auto;
  }
}

.area-hero-introduction,
.area-hero-price,
.area-hero-about,
.area-hero-case-head-shoulder-neck,
.area-hero-case-waist-back,
.area-hero-case-arm,
.area-hero-case-leg,
.area-hero-case-fatigue,
.area-hero-case-pelvis,
.area-hero-case-stretch,
.area-hero-case-others,
.area-hero-customer-voice {
  width: 100%;
  height: 560px;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: cover;
  position: relative;
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .area-hero-introduction,
  .area-hero-price,
  .area-hero-about,
  .area-hero-case-head-shoulder-neck,
  .area-hero-case-waist-back,
  .area-hero-case-arm,
  .area-hero-case-leg,
  .area-hero-case-fatigue,
  .area-hero-case-pelvis,
  .area-hero-case-stretch,
  .area-hero-case-others,
  .area-hero-customer-voice {
    height: 480px;
  }
}
@media (max-width: 1023px) {
  .area-hero-introduction,
  .area-hero-price,
  .area-hero-about,
  .area-hero-case-head-shoulder-neck,
  .area-hero-case-waist-back,
  .area-hero-case-arm,
  .area-hero-case-leg,
  .area-hero-case-fatigue,
  .area-hero-case-pelvis,
  .area-hero-case-stretch,
  .area-hero-case-others,
  .area-hero-customer-voice {
    height: 360px;
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .area-hero-introduction,
  .area-hero-price,
  .area-hero-about,
  .area-hero-case-head-shoulder-neck,
  .area-hero-case-waist-back,
  .area-hero-case-arm,
  .area-hero-case-leg,
  .area-hero-case-fatigue,
  .area-hero-case-pelvis,
  .area-hero-case-stretch,
  .area-hero-case-others,
  .area-hero-customer-voice {
    height: 260px;
    margin-top: 0;
  }
}
.area-hero-introduction::before, .area-hero-introduction::after,
.area-hero-price::before,
.area-hero-price::after,
.area-hero-about::before,
.area-hero-about::after,
.area-hero-case-head-shoulder-neck::before,
.area-hero-case-head-shoulder-neck::after,
.area-hero-case-waist-back::before,
.area-hero-case-waist-back::after,
.area-hero-case-arm::before,
.area-hero-case-arm::after,
.area-hero-case-leg::before,
.area-hero-case-leg::after,
.area-hero-case-fatigue::before,
.area-hero-case-fatigue::after,
.area-hero-case-pelvis::before,
.area-hero-case-pelvis::after,
.area-hero-case-stretch::before,
.area-hero-case-stretch::after,
.area-hero-case-others::before,
.area-hero-case-others::after,
.area-hero-customer-voice::before,
.area-hero-customer-voice::after {
  display: none;
}

.area-hero-introduction {
  background-image: url(../img/photo/hero-intro.jpg);
}
@media (max-width: 480px) {
  .area-hero-introduction {
    background-image: url(../img/photo/hero-intro_s.jpg);
  }
}

.area-hero-price {
  background-image: url(../img/photo/hero-price.jpg);
}
@media (max-width: 480px) {
  .area-hero-price {
    background-image: url(../img/photo/hero-price_s.jpg);
  }
}

.area-hero-about {
  background-image: url(../img/photo/hero-about.jpg);
}
@media (max-width: 480px) {
  .area-hero-about {
    background-image: url(../img/photo/hero-about_s.jpg);
  }
}

.area-hero-newsblog,
.area-hero-newsblog {
  width: 100%;
  height: 420px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 8px;
}
@media (max-width: 480px) {
  .area-hero-newsblog,
  .area-hero-newsblog {
    margin-top: 0;
  }
}

.area-hero-newsblog {
  background-image: url(../img/image/image-3-2-1.jpg);
}

.area-hero-newsblog {
  background-image: url(../img/image/image-3-2-1.jpg);
}

/* ------------------------------------------------------------ 営業時間ボックス */
table th,
table td {
  border: none;
}

.areadrawermenu,
.areadrawermenu-page,
.dmfooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 540px;
  padding: 6px;
  background-color: #ff662e;
  position: fixed;
  top: 45%;
  left: -20px;
  z-index: 999;
  border-radius: 0 10px 10px 0;
  transition: all 1s;
}
@media (max-width: 1023px) {
  .areadrawermenu,
  .areadrawermenu-page,
  .dmfooter {
    display: none;
  }
}
.areadrawermenu .box-drawermenu,
.areadrawermenu-page .box-drawermenu,
.dmfooter .box-drawermenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 490px;
  padding: 6px 6px 6px 40px;
  background-color: white;
  border-radius: 0 20px 20px 0;
}
.areadrawermenu .box-drawermenu .box-btn-extres,
.areadrawermenu-page .box-drawermenu .box-btn-extres,
.dmfooter .box-drawermenu .box-btn-extres {
  display: none;
}
.areadrawermenu .box-dm-up,
.areadrawermenu-page .box-dm-up,
.dmfooter .box-dm-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 12px auto 16px;
}
.areadrawermenu .box-dm-up a,
.areadrawermenu-page .box-dm-up a,
.dmfooter .box-dm-up a {
  text-decoration: none;
}
.areadrawermenu .box-dm-tel,
.areadrawermenu-page .box-dm-tel,
.dmfooter .box-dm-tel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 64px;
  padding: 6px 30px;
  font-size: 21px;
  font-weight: bold;
  color: #cc3600;
  background-color: #fff0eb;
  cursor: pointer;
  transition: all 0.2s;
}
.areadrawermenu .box-dm-tel::before,
.areadrawermenu-page .box-dm-tel::before,
.dmfooter .box-dm-tel::before {
  font-family: "icomoon";
  content: "\e918";
  margin-right: 6px;
  font-size: 18px;
}
.areadrawermenu .box-dm-tel:hover,
.areadrawermenu-page .box-dm-tel:hover,
.dmfooter .box-dm-tel:hover {
  background-color: #ffa585;
}
.areadrawermenu .box-dm-mail,
.areadrawermenu-page .box-dm-mail,
.dmfooter .box-dm-mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 64px;
  padding: 6px 30px;
  font-size: 21px;
  font-weight: bold;
  color: #43896b;
  background-color: #f1f8f5;
  cursor: pointer;
  transition: all 0.2s;
}
.areadrawermenu .box-dm-mail::before,
.areadrawermenu-page .box-dm-mail::before,
.dmfooter .box-dm-mail::before {
  font-family: "icomoon";
  content: "\e916";
  margin-right: 6px;
  font-size: 28px;
}
.areadrawermenu .box-dm-mail:hover,
.areadrawermenu-page .box-dm-mail:hover,
.dmfooter .box-dm-mail:hover {
  background-color: #bbddce;
}
.areadrawermenu .box-dm-bottom,
.areadrawermenu-page .box-dm-bottom,
.dmfooter .box-dm-bottom {
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
}
@media (max-width: 375px) {
  .areadrawermenu .box-dm-bottom,
  .areadrawermenu-page .box-dm-bottom,
  .dmfooter .box-dm-bottom {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}
.areadrawermenu .box-dm-bottom .spn-icon-clock,
.areadrawermenu-page .box-dm-bottom .spn-icon-clock,
.dmfooter .box-dm-bottom .spn-icon-clock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 0 auto;
}
.areadrawermenu .box-dm-bottom .spn-icon-clock::before,
.areadrawermenu-page .box-dm-bottom .spn-icon-clock::before,
.dmfooter .box-dm-bottom .spn-icon-clock::before {
  font-family: "icomoon";
  content: "\e909";
  margin-right: 6px;
  font-size: 16px;
}
.areadrawermenu .box-dm-bottom .spn-sml,
.areadrawermenu-page .box-dm-bottom .spn-sml,
.dmfooter .box-dm-bottom .spn-sml {
  display: block;
  margin-bottom: -3px;
  font-size: 14px;
}
.areadrawermenu .box-dmenu-navi,
.areadrawermenu .box-dmenu-navi-page,
.areadrawermenu-page .box-dmenu-navi,
.areadrawermenu-page .box-dmenu-navi-page,
.dmfooter .box-dmenu-navi,
.dmfooter .box-dmenu-navi-page {
  display: none;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0 0 1rem;
  border-top: 1px dotted #ff662e;
}
.areadrawermenu .box-dmenu-navi a,
.areadrawermenu .box-dmenu-navi-page a,
.areadrawermenu-page .box-dmenu-navi a,
.areadrawermenu-page .box-dmenu-navi-page a,
.dmfooter .box-dmenu-navi a,
.dmfooter .box-dmenu-navi-page a {
  text-align: left;
}
.areadrawermenu .box-dmenu-navi.open-dmenu-navi,
.areadrawermenu-page .box-dmenu-navi.open-dmenu-navi,
.dmfooter .box-dmenu-navi.open-dmenu-navi {
  display: block;
}
.areadrawermenu .box-dmenu-navi-page,
.areadrawermenu-page .box-dmenu-navi-page,
.dmfooter .box-dmenu-navi-page {
  display: block;
}
.areadrawermenu .box-schedule,
.areadrawermenu-page .box-schedule,
.dmfooter .box-schedule {
  width: auto;
  margin: 0 auto 4px;
  border-collapse: collapse;
  border: 0;
}
@media (max-width: 375px) {
  .areadrawermenu .box-schedule,
  .areadrawermenu-page .box-schedule,
  .dmfooter .box-schedule {
    display: block;
    width: 92%;
    overflow-x: scroll;
  }
}
.areadrawermenu .box-schedule tr,
.areadrawermenu-page .box-schedule tr,
.dmfooter .box-schedule tr {
  color: #ff662e;
  font-size: 16px;
  border: 1px solid #ff662e;
}
.areadrawermenu .box-schedule td,
.areadrawermenu-page .box-schedule td,
.dmfooter .box-schedule td {
  padding: 2px 10px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ff662e;
}
.areadrawermenu .box-schedule tr.brd-w-tr,
.areadrawermenu-page .box-schedule tr.brd-w-tr,
.dmfooter .box-schedule tr.brd-w-tr {
  background-color: #ff662e;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ff662e;
}
.areadrawermenu .box-schedule tr.brd-w-tr td.brd-w,
.areadrawermenu-page .box-schedule tr.brd-w-tr td.brd-w,
.dmfooter .box-schedule tr.brd-w-tr td.brd-w {
  border-right: 1px solid white !important;
}
.areadrawermenu .box-schedule tr.brd-w-tr td.brd-w:last-of-type,
.areadrawermenu-page .box-schedule tr.brd-w-tr td.brd-w:last-of-type,
.dmfooter .box-schedule tr.brd-w-tr td.brd-w:last-of-type {
  border-right: 1px solid #ff662e !important;
}
.areadrawermenu a,
.areadrawermenu-page a,
.dmfooter a {
  display: block;
  width: 90%;
  font-size: 14px;
  text-align: right;
  text-decoration: nooe;
  cursor: pointer;
}
.areadrawermenu .box-dm-label,
.areadrawermenu-page .box-dm-label,
.dmfooter .box-dm-label {
  display: block;
  width: 28px;
  margin: 0 2px 0 6px;
  text-align: center;
  line-height: 1;
}
.areadrawermenu table tr:nth-of-type(2n + 1),
.areadrawermenu-page table tr:nth-of-type(2n + 1),
.dmfooter table tr:nth-of-type(2n + 1) {
  background: none;
}
.areadrawermenu .spn-notice,
.areadrawermenu .spn-notice_footer,
.areadrawermenu-page .spn-notice,
.areadrawermenu-page .spn-notice_footer,
.dmfooter .spn-notice,
.dmfooter .spn-notice_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.areadrawermenu .spn-notice::before, .areadrawermenu .spn-notice::after,
.areadrawermenu .spn-notice_footer::before,
.areadrawermenu .spn-notice_footer::after,
.areadrawermenu-page .spn-notice::before,
.areadrawermenu-page .spn-notice::after,
.areadrawermenu-page .spn-notice_footer::before,
.areadrawermenu-page .spn-notice_footer::after,
.dmfooter .spn-notice::before,
.dmfooter .spn-notice::after,
.dmfooter .spn-notice_footer::before,
.dmfooter .spn-notice_footer::after {
  font-family: icomoon;
  content: "\e926";
  font-size: 12px;
  color: #ff662e;
}
.areadrawermenu .spn-notice::before,
.areadrawermenu .spn-notice_footer::before,
.areadrawermenu-page .spn-notice::before,
.areadrawermenu-page .spn-notice_footer::before,
.dmfooter .spn-notice::before,
.dmfooter .spn-notice_footer::before {
  margin-right: 6px;
}
.areadrawermenu .spn-notice::after,
.areadrawermenu .spn-notice_footer::after,
.areadrawermenu-page .spn-notice::after,
.areadrawermenu-page .spn-notice_footer::after,
.dmfooter .spn-notice::after,
.dmfooter .spn-notice_footer::after {
  margin-left: 6px;
  transform: scale(-1, 1);
}
.areadrawermenu .spn-notice_footer,
.areadrawermenu-page .spn-notice_footer,
.dmfooter .spn-notice_footer {
  display: none;
}
.areadrawermenu .spn-notice2_footer,
.areadrawermenu-page .spn-notice2_footer,
.dmfooter .spn-notice2_footer {
  display: none;
}

.btn-dm {
  font-size: 20px;
  font-weight: bold;
  color: white;
  writing-mode: vertical-rl;
  cursor: pointer;
}
.btn-dm::after {
  display: inline-block;
  font-family: "icomoon";
  content: "\e900";
  margin: 12px 0 0 0;
  padding: 3px;
  font-size: 16px;
  text-align: center;
  background-color: white;
  color: #ff662e;
  border-radius: 3px;
  transform: scale(-1, 1);
}

.areadrawermenu,
.areadrawermenu-page {
  filter: drop-shadow(1px 1px 3px rgba(10, 10, 10, 0.3));
}

.open-dmenu,
.slidedmenu-animation {
  left: -490px;
  transition: all 0.5s;
}
.open-dmenu .btn-dm::after,
.slidedmenu-animation .btn-dm::after {
  transform: scale(1, 1) rotate(2turn);
  transition: all, 1s;
}

.slidedmenu-animation.open-dmenu.close-dmenu {
  left: -20px;
  transition: all 0.5s;
}
.slidedmenu-animation.open-dmenu.close-dmenu .btn-dm::after {
  transform: scale(-1, 1);
  transition: all, 1s;
}

.areadrawermenu-page {
  left: -490px;
  transition: all 0.5s;
}
.areadrawermenu-page .btn-dm::after {
  transform: scale(1, 1) rotate(2turn);
  transition: all, 1s;
}

.areadrawermenu-page.open-dmenu {
  left: -20px;
  transition: all 0.5s;
}
.areadrawermenu-page.open-dmenu .btn-dm::after {
  transform: scale(1, 1) rotate(2turn);
  transition: all, 1s;
}

.areadrawermenu.open-dmenu,
.areadrawermenu-page.open-dmenu {
  filter: drop-shadow(4px 4px 6px rgba(10, 10, 10, 0.3));
}

/* ------------------------------------------------------------ コンテンツセクション（ベース） */
.wrap-padding {
  padding: 60px;
}
@media (max-width: 834px) {
  .wrap-padding {
    padding: 16px;
  }
}
@media (max-width: 480px) {
  .wrap-padding {
    padding: 0;
  }
}

.area-btn-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.area-btn-link a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 380px;
  padding: 8px;
  margin-bottom: 1.5rem;
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  background-color: white;
  border: 1px solid #ff662e;
  border-radius: 24px;
  transition: all, 0.2s;
  position: relative;
}
@media (max-width: 480px) {
  .area-btn-link a {
    width: 98%;
    font-size: 0.9rem;
  }
}
.area-btn-link a:hover {
  color: white;
  font-weight: 500;
  background-color: #ff662e;
  border: 1px solid #ffa585;
  filter: drop-shadow(4px 4px 6px rgba(10, 10, 10, 0.2));
  transition: all, 0.2s;
}
.area-btn-link a:hover .icon2-more-arrow::before {
  color: white;
}
.area-btn-link a::before {
  font-family: "icomoon";
  margin-right: 6px;
  font-size: 24px;
  line-height: 1;
  color: #ff662e;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}
.area-btn-link a:hover::before {
  color: white;
}
.area-btn-link .btn-link-merit::before {
  content: "\e90e";
}
@media (max-width: 375px) {
  .area-btn-link .btn-link-merit {
    width: 92%;
  }
}
.area-btn-link .btn-link-flow::before {
  content: "\e91c";
}
.area-btn-link .btn-link-about::before {
  content: "\e901";
}
.area-btn-link .btn-link-qa::before {
  content: "\e919";
}
.area-btn-link .btn-link-voice::before {
  content: "\e91e";
}
@media (max-width: 375px) {
  .area-btn-link .btn-link-voice {
    width: 92%;
  }
}

.area-btn-link-solo {
  margin-top: 2rem;
}

/* ------------------------------------------------------------ ニュース */
.sec-news h1 {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: normal;
}

.box-news-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 1rem;
}
@media (max-width: 1023px) {
  .box-news-outer {
    width: 98%;
  }
}

.spn-mds-news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  background-color: white;
}
@media (max-width: 480px) {
  .spn-mds-news {
    justify-content: center;
  }
}
.spn-mds-news::before {
  font-family: "icomoon";
  content: "\e91f";
  margin-right: 12px;
  font-size: 36px;
  color: #ff662e;
}

.area-items-news {
  width: 100%;
}
.area-items-news .widget-entry-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .area-items-news .widget-entry-cards {
    flex-direction: column;
  }
}
.area-items-news .widget-entry-cards a {
  display: block;
  width: 32%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #cccccc;
}
@media (max-width: 480px) {
  .area-items-news .widget-entry-cards a {
    width: 90%;
    margin-bottom: 12px;
  }
}
.area-items-news .widget-entry-cards .widget-entry-card {
  display: flex;
  flex-direction: column;
}
.area-items-news .widget-entry-cards figure {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}
.area-items-news .widget-entry-cards figure img {
  width: 100%;
}
.area-items-news .widget-entry-cards .widget-entry-card-content {
  display: flex;
  flex-direction: column-reverse;
  margin-left: 0;
}
.area-items-news .widget-entry-cards .widget-entry-card-title {
  margin-top: 8px;
  font-size: 1.2rem;
  line-height: 1.4;
}
.area-items-news .widget-entry-cards .widget-entry-card-date {
  display: block;
  width: 100%;
  text-align: right;
}

.btn-newslist {
  display: block;
  padding: 4px 16px;
  font-size: 14px;
  text-decoration: none;
  color: #474747;
  background-color: white;
  border: 1px solid #8f8f8f;
  border-radius: 24px;
  transition: all, 0.2s;
}
.btn-newslist:hover {
  border: 1px solid #c60000;
  transition: all, 0.2s;
}

.box-news-notice {
  display: block;
  width: 60%;
  padding: 16px 30px;
  margin: 2rem auto;
  text-align: center;
  border: 2px solid #ff8e92;
  border-radius: 6px;
  background-color: #ffebeb;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .box-news-notice {
    width: 74%;
  }
}
@media (max-width: 834px) {
  .box-news-notice {
    width: 90%;
  }
}
.box-news-notice::before, .box-news-notice::after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  z-index: 5;
  opacity: 0.1;
}
.box-news-notice::before {
  background-image: url(../img/parts/safe-4.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 20px;
}
.box-news-notice::after {
  background-image: url(../img/parts/safe-1.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  bottom: 3px;
  right: 20px;
}
.box-news-notice h2 {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 500;
  position: relative;
  z-index: 10;
}
.box-news-notice h2::before {
  font-family: "icomoon";
  content: "\e909";
  margin-right: 6px;
  font-size: 16px;
}
.box-news-notice a {
  width: auto;
  padding: 6px 12px;
  margin: 20px 0 8px;
  background-color: white;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.box-pickup {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
@media (max-width: 480px) {
  .box-pickup {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
.box-pickup a {
  display: block;
  width: 31%;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
}
@media (max-width: 480px) {
  .box-pickup a {
    width: 90%;
    margin: 0 auto 12px;
  }
}
.box-pickup a img {
  border-radius: 12px 12px 0 0;
}
.box-pickup a:hover {
  cursor: pointer;
  color: #cc3600;
}
.box-pickup a:hover img {
  opacity: 0.8;
  transition: all 0.2s;
}
.box-pickup p {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  text-align: center !important;
  border-top: 1px solid #ff662e;
  border-bottom: 1px solid #ff662e;
  background-color: white;
}
@media (max-width: 1023px) {
  .box-pickup p {
    font-size: 1rem;
  }
}

.box-pickup-tpt-c {
  margin: 3rem auto 0;
  padding: 2rem;
  background-color: white;
  border: 2px solid #ff662e;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .box-pickup-tpt-c {
    width: 98%;
    padding: 2rem 1rem;
    border: none;
  }
}
.box-pickup-tpt-c h2 {
  margin-top: 0 !important;
}
.box-pickup-tpt-c .box-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
@media (max-width: 1023px) {
  .box-pickup-tpt-c .box-inner {
    flex-direction: column;
  }
}
.box-pickup-tpt-c .box-inner .box-txt {
  width: 56%;
}
@media (max-width: 1023px) {
  .box-pickup-tpt-c .box-inner .box-txt {
    width: 100%;
  }
}
.box-pickup-tpt-c .box-inner .box-txt p {
  width: 90%;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .box-pickup-tpt-c .box-inner .box-txt p {
    width: 100%;
  }
}
.box-pickup-tpt-c .box-inner figure {
  width: 50%;
  height: 100%;
}
@media (max-width: 1023px) {
  .box-pickup-tpt-c .box-inner figure {
    width: 100%;
  }
}

.box-extra-notice,
.box-extra-notice-2nd {
  display: block;
  width: 90%;
  margin: 4rem auto;
  text-align: center;
}
@media (max-width: 1023px) {
  .box-extra-notice,
  .box-extra-notice-2nd {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .box-extra-notice,
  .box-extra-notice-2nd {
    width: 90%;
    padding: 16px;
  }
}
.box-extra-notice a,
.box-extra-notice-2nd a {
  display: block;
  margin-bottom: 2rem;
  border: 1px solid #fff;
  transition: all, 0.1s;
}
.box-extra-notice a:hover,
.box-extra-notice-2nd a:hover {
  border: 1px solid #ffa585;
  opacity: 0.7;
  transition: all, 0.1s;
}

.box-extra-notice-2nd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box-extra-notice-2nd a {
  width: 48%;
}
@media (max-width: 480px) {
  .box-extra-notice-2nd a {
    width: 100%;
  }
}

/* ------------------------------------------------------------ お知らせ */
.box-top-notice {
  display: block;
  border: 2px solid #ff662e;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .box-top-notice {
    margin: 12px 8px 0;
  }
}
.box-top-notice .box-title {
  padding: 4px 0;
  color: white;
  font-size: 1.3rem;
  font-family: "メイリオ";
  font-weight: 700;
  text-align: center;
  background-color: #ff662e;
}
.box-top-notice .box-title::before {
  font-family: "icomoon";
  content: "\e90e";
  margin-right: 10px;
  font-size: 22px;
  color: white;
}
.box-top-notice .box-info-outer {
  padding: 12px;
}
.box-top-notice .box-info-outer .box-info {
  display: flex;
  justify-content: space-around;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dotted #8f8f8f;
}
@media (max-width: 480px) {
  .box-top-notice .box-info-outer .box-info {
    flex-direction: column;
  }
}
.box-top-notice .box-info-outer .box-info:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.box-top-notice .box-info-outer .info-mds {
  width: 33%;
  font-family: "メイリオ";
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}
@media (max-width: 480px) {
  .box-top-notice .box-info-outer .info-mds {
    width: 100%;
  }
}
.box-top-notice .box-info-outer .info-mds::before {
  content: "・";
  margin-right: 6px;
  font-size: 1.2rem;
  color: #ff662e;
}
.box-top-notice .box-info-outer .info-text {
  width: 55%;
  padding: 0 6px;
  text-align: left;
  line-height: 1.5;
}
@media (max-width: 480px) {
  .box-top-notice .box-info-outer .info-text {
    width: 100%;
  }
}
.box-top-notice .box-info-outer .info-date {
  width: 12%;
  text-align: right;
  font-size: 0.9rem;
}
@media (max-width: 480px) {
  .box-top-notice .box-info-outer .info-date {
    width: 100%;
  }
}

/* ------------------------------------------------------------ YouTubeリスト */
.box-video-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding-bottom: 1rem;
}
@media (max-width: 1023px) {
  .box-video-outer {
    width: 98%;
  }
}

.box-ytvideo-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media (max-width: 480px) {
  .box-ytvideo-outer {
    flex-direction: column;
  }
}
.box-ytvideo-outer .box-ytvideo {
  width: 32%;
}
@media (max-width: 480px) {
  .box-ytvideo-outer .box-ytvideo {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
}
.box-ytvideo-outer .box-ytvideo iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

/* ------------------------------------------------------------ オファー */
.sec-offer .area-page,
.sec-offer-price .area-page {
  margin-top: 20px;
  padding: 20px 0;
  background-color: #ff662e;
}
@media (max-width: 1024px) {
  .sec-offer .area-page,
  .sec-offer-price .area-page {
    padding-bottom: 16px;
  }
}
.sec-offer .area-page .wrap-padding,
.sec-offer-price .area-page .wrap-padding {
  margin: 30px auto;
  padding: 40px 30px;
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 1024px) {
  .sec-offer .area-page .wrap-padding,
  .sec-offer-price .area-page .wrap-padding {
    margin: 0 16px;
    padding: 20px 16px;
  }
}
@media (max-width: 1023px) {
  .sec-offer .area-page .wrap-padding,
  .sec-offer-price .area-page .wrap-padding {
    padding-top: 12px;
    padding-bottom: 32px;
  }
}
@media (max-width: 480px) {
  .sec-offer .area-page .wrap-padding,
  .sec-offer-price .area-page .wrap-padding {
    padding: 12px 8px 8px;
  }
}
@media (max-width: 375px) {
  .sec-offer .area-page .wrap-padding,
  .sec-offer-price .area-page .wrap-padding {
    margin: 0 8px;
  }
}
.sec-offer h2,
.sec-offer-price h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font-family: "メイリオ";
  font-size: 1.8em;
  font-weight: 500;
  color: white;
  line-height: 1.3;
}
.sec-offer h2::before, .sec-offer h2::after,
.sec-offer-price h2::before,
.sec-offer-price h2::after {
  font-family: "icomoon";
  content: "\e91f";
  font-size: 22px;
  color: white;
}
.sec-offer h2::before,
.sec-offer-price h2::before {
  margin-right: 16px;
}
.sec-offer h2::after,
.sec-offer-price h2::after {
  margin-left: 16px;
}
@media (max-width: 1024px) {
  .sec-offer h2,
  .sec-offer-price h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 480px) {
  .sec-offer h2,
  .sec-offer-price h2 {
    margin-top: 0;
    font-size: 22px;
    text-align: center;
    line-height: 1.5;
  }
}
.sec-offer .box-offer-outer,
.sec-offer-price .box-offer-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 24px;
}
@media (max-width: 480px) {
  .sec-offer .box-offer-outer,
  .sec-offer-price .box-offer-outer {
    flex-direction: column;
  }
}
.sec-offer .box-offer,
.sec-offer-price .box-offer {
  padding: 6px;
  background-color: white;
  border: 6px solid white;
  overflow: hidden;
}
@media (max-width: 480px) {
  .sec-offer .box-offer,
  .sec-offer-price .box-offer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
}
.sec-offer .box-offer span,
.sec-offer-price .box-offer span {
  display: block;
  font-size: 36px;
  color: #c60000;
  text-align: center;
}
.sec-offer .box-offer .btn-resv,
.sec-offer-price .box-offer .btn-resv {
  display: block;
  width: 80%;
  margin: 20px auto 8px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: white;
  border-bottom: 7px solid hsl(197, 99%, 31%);
  border-radius: 6px;
  background-image: linear-gradient(to right, rgb(57, 210, 248) 0%, #01995f 100%);
  text-decoration: none;
  vertical-align: middle;
}
@media (max-width: 480px) {
  .sec-offer .box-offer .btn-resv,
  .sec-offer-price .box-offer .btn-resv {
    width: 90%;
    padding: 4px 16px;
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .sec-offer .box-offer .btn-resv,
  .sec-offer-price .box-offer .btn-resv {
    font-size: 20px;
  }
}
.sec-offer .box-offer .btn-resv:hover,
.sec-offer-price .box-offer .btn-resv:hover {
  margin-top: 25px;
  border-bottom: 2px solid #00662d;
  color: black;
  background-image: linear-gradient(to right, #ffed59 0%, #fff18a 100%);
  transition: all 0.2s;
}
.sec-offer .box-offer img,
.sec-offer-price .box-offer img {
  max-height: 360px;
  margin: 0;
  border-radius: 4px;
  transition: all 0.1s;
  filter: drop-shadow(2px 2px 4px rgba(10, 10, 10, 0.2));
}
.sec-offer .box-offer img:hover,
.sec-offer-price .box-offer img:hover {
  transform: scale(1.1);
  transition: all 0.2s;
}
.sec-offer .area-singlepost,
.sec-offer-price .area-singlepost {
  margin-bottom: 2rem;
}
.sec-offer .area-singlepost h2,
.sec-offer-price .area-singlepost h2 {
  padding-top: 18px;
  margin-bottom: 12px;
  color: inherit;
  border-top: 1px dotted #ff662e !important;
  border-bottom: none !important;
}
.sec-offer .area-singlepost .box-offer,
.sec-offer-price .area-singlepost .box-offer {
  flex-direction: column;
}
.sec-offer .area-singlepost .box-offer img,
.sec-offer-price .area-singlepost .box-offer img {
  width: 100%;
  max-width: 100%;
}
.sec-offer .area-singlepost .box-offer .box-txt,
.sec-offer-price .area-singlepost .box-offer .box-txt {
  width: 100%;
  margin-left: 0;
}

@media (max-width: 1023px) {
  .sec-offer {
    margin-bottom: 2rem;
  }
}

.sec-offer-price {
  margin-bottom: 2rem;
}
.sec-offer-price .area-page {
  padding: 2rem 0;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .sec-offer-price .area-page {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.sec-offer-price .area-page h2 {
  margin-bottom: 20px;
}
.sec-offer-price .area-page h2::before {
  font-family: "icomoon";
  content: "\e91f";
  font-size: 22px;
  color: white;
}
.sec-offer-price .box-offer img {
  max-height: 240px;
}

/* ------------------------------------------------------------ 人気の施術 */
.area-singlepost h2,
.area-frontpage h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
  margin-bottom: 2rem;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.3;
}
.area-singlepost h2::before,
.area-frontpage h2::before {
  font-family: "icomoon";
  content: "\e91f";
  margin-right: 10px;
  font-size: 22px;
  color: #ff662e;
}
@media (max-width: 834px) {
  .area-singlepost h2,
  .area-frontpage h2 {
    margin-top: 2rem;
    margin-bottom: 8px;
  }
}
@media (max-width: 480px) {
  .area-singlepost h2,
  .area-frontpage h2 {
    font-size: 22px;
    text-align: center;
    line-height: 1.5;
  }
}
@media (max-width: 375px) {
  .area-singlepost h2,
  .area-frontpage h2 {
    font-size: 20px;
  }
}
.area-singlepost .box-treatments-outer-outer,
.area-frontpage .box-treatments-outer-outer {
  padding: 2rem 1rem 1rem;
  border-radius: 12px;
  background-color: white;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (max-width: 1024px) {
  .area-singlepost .box-treatments-outer-outer,
  .area-frontpage .box-treatments-outer-outer {
    margin-bottom: 1rem;
  }
}
@media (max-width: 480px) {
  .area-singlepost .box-treatments-outer-outer,
  .area-frontpage .box-treatments-outer-outer {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
}
.area-singlepost .box-treatments-outer,
.area-frontpage .box-treatments-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.area-singlepost .box-treatment,
.area-frontpage .box-treatment {
  width: 32%;
  margin-bottom: 1.5rem;
  overflow: hidden;
}
@media (max-width: 834px) {
  .area-singlepost .box-treatment,
  .area-frontpage .box-treatment {
    width: 48%;
    margin-bottom: 1rem;
  }
}
.area-singlepost .box-treatment img,
.area-frontpage .box-treatment img {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.1s;
  border-radius: 8px;
}
@media (max-width: 834px) {
  .area-singlepost .box-treatment img,
  .area-frontpage .box-treatment img {
    width: 100%;
    max-width: 100%;
  }
}
.area-singlepost .box-treatment img:hover,
.area-frontpage .box-treatment img:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
.area-singlepost .area-singlepost .box-treatment,
.area-frontpage .area-singlepost .box-treatment {
  flex-direction: column;
}
.area-singlepost .area-singlepost .box-treatment img,
.area-frontpage .area-singlepost .box-treatment img {
  width: 100%;
  max-width: 100%;
}

/* ------------------------------------------------------------ ふじさわサーチ */
.intro-search {
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.fujisawa-listsbox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 auto 2rem;
  padding: 40px 0;
  border-radius: 12px;
  background-color: white;
}
@media (max-width: 1023px) {
  .fujisawa-listsbox {
    flex-direction: column;
  }
}
.fujisawa-listsbox .listbox-symptom,
.fujisawa-listsbox .listbox-treatment,
.fujisawa-listsbox .listbox-cases {
  display: block;
  padding: 1rem 6rem;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 12px;
  background-size: 200% auto;
  text-shadow: 1px 1px 1px rgba(10, 10, 10, 0.5), 1px -1px 1px rgba(10, 10, 10, 0.5), -1px 1px 1px rgba(10, 10, 10, 0.5), -1px -1px 1px rgba(10, 10, 10, 0.5);
  box-shadow: 0 0 20px #eee;
  transition: all, 0.2s;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .fujisawa-listsbox .listbox-symptom,
  .fujisawa-listsbox .listbox-treatment,
  .fujisawa-listsbox .listbox-cases {
    margin-bottom: 1rem;
  }
}
.fujisawa-listsbox .listbox-symptom:hover,
.fujisawa-listsbox .listbox-treatment:hover,
.fujisawa-listsbox .listbox-cases:hover {
  transition: all, 0.2s;
  background-position: right center;
  filter: drop-shadow(8px 8px 4px rgba(10, 10, 10, 0.2));
}
.fujisawa-listsbox .listbox-symptom {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}
.fujisawa-listsbox .listbox-cases {
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
}
.fujisawa-listsbox .listbox-treatment {
  background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
}

@media (max-width: 480px) {
  .box-searchform {
    padding: 0 4px;
  }
}
.box-searchform p {
  margin-top: 12px;
  margin-bottom: 1.5rem;
  text-align: right;
  font-size: 0.9rem;
}
.box-searchform p strong {
  color: #c60000;
}

.box-rcmdkwd {
  width: 42%;
  margin: 2em auto;
  position: relative;
  padding: 0.5em 1.5em;
  background: white;
  border-top: solid 2px #ff662e;
  border-bottom: solid 2px #ff662e;
}
@media (max-width: 1023px) {
  .box-rcmdkwd {
    width: 64%;
  }
}
@media (max-width: 480px) {
  .box-rcmdkwd {
    width: 100%;
  }
}
.box-rcmdkwd::before, .box-rcmdkwd::after {
  content: "";
  position: absolute;
  top: -10px;
  width: 2px;
  height: -webkit-calc(100% + 20px);
  height: calc(100% + 20px);
  background-color: #ff662e;
}
.box-rcmdkwd::before {
  left: 10px;
}
.box-rcmdkwd::after {
  right: 10px;
}
.box-rcmdkwd p {
  margin: 8px 0 16px;
  padding-bottom: 4px;
  text-align: center;
  border-bottom: 3px dotted #ff662e;
}
.box-rcmdkwd p span {
  color: #ff662e;
}
.box-rcmdkwd ul {
  width: auto;
  margin-bottom: 0;
  padding-left: 1rem;
}
@media (max-width: 1023px) {
  .box-rcmdkwd ul {
    padding-left: 0.5rem;
  }
}
.box-rcmdkwd li {
  padding-left: 0.8em;
  list-style: none;
  position: relative;
  text-align: left;
}
.box-rcmdkwd li::before {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #ff662e;
}

.search-submit-fs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1.8rem auto 0;
  padding: 1em 2em;
  width: 360px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  background-color: #ffa585;
  border: 1px solid #ffa585;
  border-radius: 50vh;
  transition: all 0.2s;
  cursor: pointer;
  /* 
  &::after {
      content: '';
      position: absolute;
      top: 48%;
      right: 30px;
      width: 5px;
      height: 5px;
      border-top: 5px solid white;
      border-right: 5px solid white;
      transform: rotate(45deg) translateY(-50%);
      transition: all 0.3s;
  }
   */
}
.search-submit-fs::before {
  content: "";
  position: absolute;
  top: -7px;
  left: -8px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px solid #cc3600;
  border-radius: 50vh;
  transition: all 0.2s;
}
.search-submit-fs::after {
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  right: 60px;
  background-image: url(../img/svg/icon-search.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.search-submit-fs:hover {
  justify-content: center;
  color: white;
  text-decoration: none;
  background-color: #ff662e;
  filter: drop-shadow(6px 6px 5px rgba(10, 10, 10, 0.2));
  transition: all 0.3s;
}
.search-submit-fs:hover::before {
  top: 0;
  left: 0;
  border-color: white;
  border-width: 2px;
}
.search-submit-fs:hover::after {
  border-color: white;
  rotate: 360deg;
  transition: all 0.2s;
}

/* ------------------------------ サイドバーのウィジェット用検索ボックス */
.searchform-widget {
  justify-content: flex-start;
  width: 240px;
  margin: 1rem auto 0;
}
.searchform-widget::after {
  width: 22px;
  height: 22px;
  right: 30px;
}

/* ------------------------------------------------------------ ふじさわデータベース */
@media (max-width: 480px) {
  .h1-small {
    margin-top: 16px;
    margin-bottom: 32px !important;
    font-size: 18px !important;
  }
  .h1-small::before, .h1-small::after {
    font-size: 14px !important;
  }
}

.area-fujisawasearchpage .section-fujisawalists {
  margin-bottom: 40px !important;
}
@media (max-width: 480px) {
  .area-fujisawasearchpage {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}

.h2-fujisawasaerch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem !important;
  padding: 10px 0 !important;
  font-size: 32px;
  font-weight: 500;
  color: white;
  background-color: #ff662e !important;
  border-radius: 50px !important;
}
@media (max-width: 480px) {
  .h2-fujisawasaerch {
    width: 92%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
  }
}
.h2-fujisawasaerch::before, .h2-fujisawasaerch::after {
  color: white;
  font-family: icomoon;
  font-size: 22px;
  content: "\e91f";
}
.h2-fujisawasaerch::before {
  margin-right: 18px;
  transform: scale(-1, 1);
}
.h2-fujisawasaerch::after {
  margin-left: 18px;
}

.spn-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.spn-star::before, .spn-star::after {
  color: #ff662e;
  font-family: icomoon;
  font-size: 18px;
  content: "\e91f";
}
.spn-star::before {
  margin-right: 18px;
  transform: scale(-1, 1);
}
.spn-star::after {
  margin-left: 18px;
}

.label-blogcat,
.label-cpostterm,
.label-cpostterm2 {
  display: inline-block;
  margin-left: 16px;
  padding-right: 6px;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 4px;
}

.label-blogcat {
  background-color: #bbddce;
}

.label-cpostterm {
  background-color: #fdd7c9;
}

.label-cpostterm2 {
  background-color: #c9eefd;
}

.section-fujisawalists {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 5rem;
}
.section-fujisawalists .outer-listgroup {
  width: 48%;
}
@media (max-width: 480px) {
  .section-fujisawalists .outer-listgroup {
    width: 92%;
    margin: 0 auto;
  }
}
.section-fujisawalists .spn-mds-head,
.section-fujisawalists .spn-mds-back,
.section-fujisawalists .spn-mds-arm,
.section-fujisawalists .spn-mds-leg,
.section-fujisawalists .spn-mds-fatigue,
.section-fujisawalists .spn-mds-pelvis,
.section-fujisawalists .spn-mds-stretch,
.section-fujisawalists .spn-mds-others {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  border-bottom: 3px dotted #ff662e;
  padding-bottom: 8px;
  margin: 2rem 0 1rem;
  width: 100%;
}
.section-fujisawalists .spn-mds-head::before,
.section-fujisawalists .spn-mds-back::before,
.section-fujisawalists .spn-mds-arm::before,
.section-fujisawalists .spn-mds-leg::before,
.section-fujisawalists .spn-mds-fatigue::before,
.section-fujisawalists .spn-mds-pelvis::before,
.section-fujisawalists .spn-mds-stretch::before,
.section-fujisawalists .spn-mds-others::before {
  content: "";
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section-fujisawalists .spn-mds-head::before {
  background-image: url(../img/case-icon/icon-kubi-kata.svg);
}
.section-fujisawalists .spn-mds-back::before {
  background-image: url(../img/case-icon/icon-senaka-koshi.svg);
}
.section-fujisawalists .spn-mds-arm::before {
  background-image: url(../img/case-icon/icon-ude-te.svg);
}
.section-fujisawalists .spn-mds-leg::before {
  background-image: url(../img/case-icon/icon-ashi-hiza.svg);
}
.section-fujisawalists .spn-mds-fatigue::before {
  background-image: url(../img/case-icon/icon-hirou-teleork.svg);
}
.section-fujisawalists .spn-mds-pelvis::before {
  background-image: url(../img/case-icon/icon-kotsuban.svg);
}
.section-fujisawalists .spn-mds-stretch::before {
  background-image: url(../img/case-icon/icon-strecth-bodycare.svg);
}
.section-fujisawalists .spn-mds-others::before {
  background-image: url(../img/case-icon/icon-other.svg);
}
.section-fujisawalists ul {
  padding-left: 10px;
}
@media (max-width: 480px) {
  .section-fujisawalists ul {
    padding-left: 0;
  }
}
.section-fujisawalists li {
  list-style-type: none;
  position: relative;
  padding-left: 0.6em;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px dotted #ff662e !important;
}
.section-fujisawalists li::before {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #ff662e;
}
.section-fujisawalists a {
  display: block;
  text-decoration: none;
  color: inherit;
  text-indent: 0.4rem;
  transition: all, 0.2s;
}
.section-fujisawalists a:hover {
  color: #c60000;
  background-color: #fff0eb;
}

.box-results {
  width: 90%;
  margin: -2rem auto 1.5rem;
  font-size: 0.9rem;
  padding: 1rem;
  border: 2px dotted #43896b;
}
@media (max-width: 480px) {
  .box-results {
    width: 100%;
    margin-top: 1rem;
  }
}
.box-results .box-result strong {
  color: #c60000;
}

.listgroup {
  width: 100%;
}
@media (max-width: 480px) {
  .listgroup ul {
    margin-bottom: 0;
  }
}

/* ------------------------------------------------------------ お悩みどこ？ */
.sec-case-top,
.sec-case-pro {
  padding: 30px 0;
  background-color: #fff0eb;
  position: relative;
}
.sec-case-top .frame-sec-up,
.sec-case-top .frame-sec-bottom,
.sec-case-pro .frame-sec-up,
.sec-case-pro .frame-sec-bottom {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
}
.sec-case-top .frame-sec-up,
.sec-case-pro .frame-sec-up {
  top: 0;
  left: auto;
}
.sec-case-top .frame-sec-bottom,
.sec-case-pro .frame-sec-bottom {
  bottom: 0;
  left: auto;
}
.sec-case-top .wrap-padding,
.sec-case-pro .wrap-padding {
  text-align: center;
}
.sec-case-top h2,
.sec-case-pro h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.8em;
  font-weight: 500;
}
.sec-case-top h2:last-of-type,
.sec-case-pro h2:last-of-type {
  margin-top: 5rem;
}
.sec-case-top h2::before,
.sec-case-pro h2::before {
  font-family: "icomoon";
  content: "\e91f";
  margin-right: 10px;
  font-size: 22px;
  color: #ff662e;
}
@media (max-width: 480px) {
  .sec-case-top h2,
  .sec-case-pro h2 {
    font-size: 22px;
    text-align: center;
    line-height: 1.5;
  }
}
@media (max-width: 375px) {
  .sec-case-top h2,
  .sec-case-pro h2 {
    font-size: 20px;
  }
}
.sec-case-top p,
.sec-case-pro p {
  text-align: left;
}
.sec-case-top .p-92,
.sec-case-pro .p-92 {
  margin: 1.2em auto;
  text-align: center;
}
@media (max-width: 480px) {
  .sec-case-top .p-92,
  .sec-case-pro .p-92 {
    width: 92%;
    text-align: left;
  }
}
.sec-case-top .area-case-top,
.sec-case-pro .area-case-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4rem;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top,
  .sec-case-pro .area-case-top {
    margin-top: 2rem;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}
.sec-case-top .area-case-top .box-case,
.sec-case-pro .area-case-top .box-case {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 22%;
  margin-bottom: 2rem;
  padding-top: 8px;
  background-color: white;
  border: 2px solid white;
  border-radius: 12px;
  text-decoration: none;
  color: #474747;
  cursor: pointer;
  transition: all, 0.2s;
}
@media (max-width: 1240px) {
  .sec-case-top .area-case-top .box-case,
  .sec-case-pro .area-case-top .box-case {
    width: 32%;
  }
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case,
  .sec-case-pro .area-case-top .box-case {
    width: 48%;
  }
}
@media (max-width: 375px) {
  .sec-case-top .area-case-top .box-case,
  .sec-case-pro .area-case-top .box-case {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}
.sec-case-top .area-case-top .box-case:hover,
.sec-case-pro .area-case-top .box-case:hover {
  border: 2px solid #73ba9b;
  filter: drop-shadow(6px 6px 6px rgba(10, 10, 10, 0.1));
  transition: all, 0.2s;
}
.sec-case-top .area-case-top .box-case div .spn-fz-lg,
.sec-case-pro .area-case-top .box-case div .spn-fz-lg {
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-lg,
  .sec-case-pro .area-case-top .box-case div .spn-fz-lg {
    font-size: 28px;
  }
}
.sec-case-top .area-case-top .box-case div .spn-fz-md,
.sec-case-pro .area-case-top .box-case div .spn-fz-md {
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-md,
  .sec-case-pro .area-case-top .box-case div .spn-fz-md {
    font-size: 24px;
  }
}
.sec-case-top .area-case-top .box-case div .spn-fz-mmd,
.sec-case-pro .area-case-top .box-case div .spn-fz-mmd {
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-mmd,
  .sec-case-pro .area-case-top .box-case div .spn-fz-mmd {
    font-size: 22px;
  }
}
.sec-case-top .area-case-top .box-case div .spn-fz-msm,
.sec-case-pro .area-case-top .box-case div .spn-fz-msm {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-msm,
  .sec-case-pro .area-case-top .box-case div .spn-fz-msm {
    font-size: 24px;
  }
}
.sec-case-top .area-case-top .box-case div .spn-fz-smsm,
.sec-case-pro .area-case-top .box-case div .spn-fz-smsm {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-smsm,
  .sec-case-pro .area-case-top .box-case div .spn-fz-smsm {
    font-size: 22px;
  }
}
.sec-case-top .area-case-top .box-case div .spn-fz-sm,
.sec-case-pro .area-case-top .box-case div .spn-fz-sm {
  font-size: 18px;
}
@media (max-width: 480px) {
  .sec-case-top .area-case-top .box-case div .spn-fz-sm,
  .sec-case-pro .area-case-top .box-case div .spn-fz-sm {
    font-size: 16px;
  }
}
.sec-case-top .area-case-top .box-case img,
.sec-case-pro .area-case-top .box-case img {
  width: 144px;
  height: 144px;
}
.sec-case-top .area-case-top .box-case p,
.sec-case-pro .area-case-top .box-case p {
  margin: 1.2em 0;
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.5;
}
.sec-case-top .box-notice-case-top,
.sec-case-pro .box-notice-case-top {
  width: 92%;
  margin: 0 auto 4rem;
  text-align: left;
}
@media (max-width: 480px) {
  .sec-case-top .box-notice-case-top p,
  .sec-case-pro .box-notice-case-top p {
    margin-top: 0;
  }
}
.sec-case-top .spn-strong-top-treatment,
.sec-case-pro .spn-strong-top-treatment {
  display: inline-block;
  width: auto;
  margin: 12px 0;
  border-bottom: 2px dotted #ff662e;
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 834px) {
  .sec-case-top .spn-strong-top-treatment,
  .sec-case-pro .spn-strong-top-treatment {
    margin: 4px 0;
    font-size: 1rem;
  }
}

.sec-introfront .p-92 {
  margin: 1.2em auto;
  text-align: center;
}
@media (max-width: 480px) {
  .sec-introfront .p-92 {
    width: 92%;
    text-align: left;
  }
}

.sec-case-pro .wrap {
  width: 100%;
}

/* ------------------------------------------------------------ 初めての方へ */
.sec-introduction-top {
  padding: 30px 0;
  position: relative;
}
.sec-introduction-top .frame-sec-up,
.sec-introduction-top .frame-sec-bottom {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
}
.sec-introduction-top .frame-sec-up {
  top: 0;
  left: auto;
}
.sec-introduction-top .frame-sec-bottom {
  bottom: 0;
  left: auto;
}
.sec-introduction-top .wrap-padding {
  padding: 30px;
  text-align: center;
  background-image: url(../img/parts/blue-en-2.svg);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .sec-introduction-top .wrap-padding {
    padding: 16px;
  }
}
.sec-introduction-top .area-intro-intro {
  position: relative;
  width: 100%;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
}
@media (max-width: 834px) {
  .sec-introduction-top .area-intro-intro {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}
.sec-introduction-top .area-intro-intro::before, .sec-introduction-top .area-intro-intro::after {
  content: "";
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
}
@media (max-width: 834px) {
  .sec-introduction-top .area-intro-intro::before, .sec-introduction-top .area-intro-intro::after {
    opacity: 0;
  }
}
.sec-introduction-top .area-intro-intro::before {
  background-image: url(../img/parts/kumo-1-2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: -20px;
  left: 0;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-intro-intro::before {
    top: 40px;
  }
}
.sec-introduction-top .area-intro-intro::after {
  background-image: url(../img/parts/kumo-2-2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 80px;
  right: 40px;
}
.sec-introduction-top h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.5;
}
.sec-introduction-top h2:last-of-type {
  width: 92%;
  margin: 5rem auto 0;
}
@media (max-width: 834px) {
  .sec-introduction-top h2:last-of-type {
    margin-top: 3rem;
  }
}
.sec-introduction-top h2::before {
  font-family: "icomoon";
  content: "\e926";
  margin-right: 10px;
  font-size: 20px;
  color: #43896b;
}
@media (max-width: 480px) {
  .sec-introduction-top h2::before {
    font-size: 14px;
  }
}
@media (max-width: 1023px) {
  .sec-introduction-top h2 {
    font-size: 1.6em;
  }
}
@media (max-width: 480px) {
  .sec-introduction-top h2 {
    font-size: 24px;
    text-align: center;
    line-height: 1.5;
  }
}
@media (max-width: 375px) {
  .sec-introduction-top h2 {
    font-size: 18px;
  }
}
.sec-introduction-top p {
  display: block;
  width: 80%;
  margin: 1rem auto 1rem;
  text-align: left;
  line-height: 1.7;
}
@media (max-width: 834px) {
  .sec-introduction-top p {
    width: 90%;
  }
}
.sec-introduction-top .p-narrow {
  width: 60%;
}
@media (max-width: 1023px) {
  .sec-introduction-top .p-narrow {
    width: 90%;
  }
}
.sec-introduction-top .spn-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  line-height: 1.5;
}
.sec-introduction-top .spn-check::before {
  margin-right: 16px;
  font-family: icomoon;
  font-size: 16px;
  content: "\e908";
}
.sec-introduction-top .area-merit-top .box-mds-merit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 3rem;
  font-size: 28px;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-mds-merit {
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .sec-introduction-top .area-merit-top .box-mds-merit {
    font-size: 16px;
  }
}
.sec-introduction-top .area-merit-top .box-mds-merit::before {
  font-family: "icomoon";
  content: "\e910";
  margin-right: 6px;
  font-size: 28px;
  color: #ff662e;
}
@media (max-width: 375px) {
  .sec-introduction-top .area-merit-top .box-mds-merit::before {
    font-size: 22px;
  }
}
.sec-introduction-top .area-merit-top .box-mds-merit span {
  font-size: 60px;
  font-weight: bold;
  color: #cc3600;
  line-height: 1;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-mds-merit span {
    font-size: 48px;
  }
}
@media (max-width: 375px) {
  .sec-introduction-top .area-merit-top .box-mds-merit span {
    font-size: 36px;
  }
}
.sec-introduction-top .area-merit-top .box-merit-top-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-merit-top-outer {
    flex-direction: column;
    justify-content: center;
  }
}
.sec-introduction-top .area-merit-top .box-merit-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 31%;
  padding: 16px;
  margin-bottom: 60px;
  border: 1px solid #adadad;
  border-radius: 10px;
  background-color: white;
  position: relative;
  /* &::after {
      width: 60px;
      height: 60px;
      padding: 10px;
      font-style: italic;
      font-size: 60px;
      line-height: 1;
      color: i.$primary-d;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: -44px;
      right: 10px;
  } */
}
@media (max-width: 1023px) {
  .sec-introduction-top .area-merit-top .box-merit-top {
    width: 48%;
    margin-bottom: 48px;
  }
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-merit-top {
    width: 100%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sec-introduction-top .area-merit-top .box-merit-top::before {
  font-family: "icomoon";
  content: "\e90c";
  margin-right: 6px;
  font-size: 48px;
  color: #ff662e;
  opacity: 0.3;
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 1;
}
.sec-introduction-top .area-merit-top .box-merit-top .num-merit {
  display: block;
  text-align: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  right: -20px;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-merit-top .num-merit {
    width: 52px;
    height: 52px;
    top: -26px;
    right: -7px;
  }
}
.sec-introduction-top .area-merit-top .box-merit-top span {
  margin-top: 24px;
  font-size: 14px;
}
.sec-introduction-top .area-merit-top .box-merit-top h3 {
  margin-top: 8px;
  font-size: 24px;
  font-weight: normal;
  font-style: italic;
  line-height: 1.4;
}
@media (max-width: 375px) {
  .sec-introduction-top .area-merit-top .box-merit-top h3 {
    font-size: 20px;
  }
}
.sec-introduction-top .area-merit-top .box-merit-top p {
  margin: 1.5rem 0;
  padding-bottom: 0;
  font-size: 16px;
  border: none;
}
@media (max-width: 480px) {
  .sec-introduction-top .area-merit-top .box-merit-top p {
    margin-bottom: 0;
  }
}
.sec-introduction-top .box-pickup-tpt-c {
  border-color: #73ba9b;
}
@media (max-width: 480px) {
  .sec-introduction-top .box-pickup-tpt-c {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
}
.sec-introduction-top .box-pickup-tpt-c h2 {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
  line-height: 1.4;
}
.sec-introduction-top .box-pickup-tpt-c h2:last-of-type {
  width: 100%;
}
.sec-introduction-top .box-pickup-tpt-c h3 {
  width: 90%;
  margin: 0.5rem 0 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  border-bottom: 2px dotted #73ba9b;
}
@media (max-width: 480px) {
  .sec-introduction-top .box-pickup-tpt-c h3 {
    width: 100%;
    font-size: 1.4em;
  }
}
.sec-introduction-top .box-pickup-tpt-c h3::before {
  font-family: "icomoon";
  content: "\e926";
  margin-right: 10px;
  font-size: 16px;
  color: #43896b;
}
@media (max-width: 480px) {
  .sec-introduction-top .box-pickup-tpt-c h3::before {
    font-size: 14px;
  }
}
.sec-introduction-top .box-pickup-tpt-c .box-inner:first-of-type {
  margin-bottom: 2em;
}
.sec-introduction-top .box-pickup-tpt-c p {
  width: 90%;
}
@media (max-width: 1023px) {
  .sec-introduction-top .box-pickup-tpt-c p {
    width: 100%;
  }
}
.sec-introduction-top .box-pickup-tpt-c img {
  margin-top: 1em;
}

.box-movie {
  width: 72%;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 834px) {
  .box-movie {
    width: 92%;
  }
}

.box-movie-topmes {
  margin: 5rem auto;
}
@media (max-width: 1023px) {
  .box-movie-topmes {
    margin-top: 3rem auto;
  }
}

.box-top-message {
  width: 70%;
  margin: 1rem auto 7rem;
}
@media (max-width: 480px) {
  .box-top-message {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.box-top-message span {
  display: block;
  margin-bottom: 2rem;
  padding-bottom: 12px;
  font-size: 1.8em;
  font-weight: 500;
  text-align: center;
  line-height: 1.4;
  border-bottom: 2px dotted #ff662e;
}
@media (max-width: 480px) {
  .box-top-message span {
    font-size: 24px;
  }
}
.box-top-message p {
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .box-top-message p {
    width: 100%;
  }
}
.box-top-message figure {
  display: block;
  margin: 3rem auto;
  text-align: center;
}
@media (max-width: 480px) {
  .box-top-message figure {
    margin-top: 1.5rem;
  }
}
.box-top-message figcaption {
  margin-top: 8px;
}

/* ------------------------------------------------------------ 料金 */
.sec-price-top {
  padding: 30px 0;
  background-color: #fff6eb;
  position: relative;
}
.sec-price-top .frame-sec-up,
.sec-price-top .frame-sec-bottom {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
}
.sec-price-top .frame-sec-up {
  top: 0;
  left: auto;
}
.sec-price-top .frame-sec-bottom {
  bottom: 0;
  left: auto;
}
.sec-price-top h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 2em;
  font-weight: 500;
}
.sec-price-top h2::before {
  font-family: "icomoon";
  content: "\e91c";
  margin-right: 10px;
  font-size: 26px;
  color: #43896b;
}

.area-price-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .area-price-outer {
    flex-direction: column;
  }
}

.area-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 5rem;
  border-radius: 10px;
  background-color: white;
}
@media (max-width: 834px) {
  .area-price {
    margin-bottom: 3rem;
  }
}
.area-price h3 {
  display: block;
  width: 100%;
  padding: 10px 0;
  font-size: 26px;
  text-align: center;
  color: white;
  background-color: #73ba9b;
  border-radius: 10px 10px 0 0;
}
.area-price .box-price-price {
  display: block;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  background-color: #f1f8f5;
}
.area-price .box-price-price .spn-price {
  margin-right: 8px;
  font-size: 54px;
  font-weight: 500;
}
.area-price .box-price-about {
  width: 31%;
  padding: 0 20px;
}
@media (max-width: 1023px) {
  .area-price .box-price-about {
    width: 100%;
    text-align: center;
  }
}
.area-price .box-price-about span {
  display: block;
  padding: 16px 0;
  line-height: 1.4;
  border-bottom: 1px dotted #43896b;
}
.area-price img {
  margin: 0;
}
@media (max-width: 1023px) {
  .area-price img {
    margin: 1rem auto;
    text-align: center;
  }
}
.area-price .box-info-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}
@media (max-width: 1023px) {
  .area-price .box-info-outer {
    flex-direction: column;
  }
}
.area-price .box-price-outer {
  width: 100%;
}
@media (max-width: 1024px) {
  .area-price .box-price-outer {
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .area-price .box-price-outer {
    width: 100%;
  }
}
.area-price .box-price-outer .box-next,
.area-price .box-price-outer .box-ticket-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}
.area-price .box-price-outer .box-next {
  border-top: 1px dotted #43896b;
  border-bottom: 1px dotted #43896b;
}
.area-price .box-price-outer .box-ticket-outer {
  border-bottom: 1px dotted #43896b;
}
.area-price .box-price-outer .spn-md {
  width: auto;
  font-size: 18px;
}
.area-price .box-price-outer .box-outer-l {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  padding-right: 2rem;
  text-align: right;
}
.area-price .box-price-outer .box-ticket-outer .spn-md {
  padding-right: 2rem;
}
.area-price .box-price-outer .box-outer-r {
  width: 50%;
  padding-right: 2rem;
}
.area-price .box-price-outer .spn-lg {
  font-size: 42px;
  margin-right: 1rem;
}
.area-price .box-price-outer .box-tickets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.area-price .box-price-outer .box-ticket {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.area-price .box-price-outer .box-ticket span:first-of-type {
  margin-right: 1rem;
  font-size: 18px;
}
.area-price .box-price-outer .box-ticket span:last-of-type {
  margin-right: 1rem;
  font-size: 36px;
  line-height: 1.2;
}
.area-price span {
  font-size: 18px;
}
.area-price .box-btn {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: #73ba9b;
  border-radius: 0 0 10px 10px;
  text-align: center;
}
.area-price .box-btn a {
  padding: 8px 36px;
  background-color: white;
  text-align: center;
  text-decoration: none;
  color: inherit;
  border-radius: 20px;
  cursor: pointer;
  transition: all, 0.2s;
}
.area-price .box-btn a:hover {
  background-color: #fff6eb;
  filter: drop-shadow(4px 4px 6px rgba(10, 10, 10, 0.2));
  transition: all, 0.2s;
}

/* ------------------------------------------------------------ 採用ページ */
.sec-recruitment .wrap-padding {
  padding-top: 0;
}
.sec-recruitment .box-sec-intro {
  width: 98%;
}
@media (max-width: 480px) {
  .sec-recruitment h1 {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .sec-recruitment h1::before, .sec-recruitment h1::after {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .sec-recruitment .h2-nowing {
    font-size: 20px;
    text-align: left;
  }
}
.sec-recruitment p,
.sec-recruitment table {
  font-size: 16px;
}
.sec-recruitment img {
  display: block;
  margin: 1rem auto;
}
.sec-recruitment .p-question {
  font-size: 1.1rem;
  font-weight: bold;
  color: #cc3600;
}
.sec-recruitment .p-separate {
  display: block;
  width: 100%;
  margin: 1rem auto 3rem;
  color: #ff662e;
  text-align: center;
}

p.box-recruit-notice {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: 3px dotted #c60000;
}

.btn-here {
  display: block;
  width: 50%;
  margin: 1rem auto;
  padding: 20px 40px;
  background-color: #43896b;
  border: 1px solid #43896b;
  border-radius: 20px;
  text-decoration: none;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  transition: all, 0.1s;
}
@media (max-width: 1023px) {
  .btn-here {
    width: 90%;
  }
}
.btn-here:hover {
  background-color: #fff6eb;
  border: 1px solid #ffa333;
  filter: drop-shadow(3px 3px 8px rgba(10, 10, 10, 0.1));
  transition: all, 0.1s;
}

.outer-movie-recruit {
  width: 100%;
  aspect-ratio: 16/9;
}

.movie-recruit {
  width: 100%;
  height: 100%;
}

.tbl-youkou tr {
  border-bottom: dotted 1px #ff662e;
}
.tbl-youkou tr:nth-of-type(1) {
  border-top: dotted 1px #ff662e;
}
.tbl-youkou tr:nth-of-type(2n+1) {
  background-color: white;
}
.tbl-youkou th {
  width: 180px;
  font-weight: normal;
  background-color: #fff0eb;
}
@media (max-width: 480px) {
  .tbl-youkou th {
    width: auto;
  }
}
.tbl-youkou td {
  padding: 8px;
  line-height: 1.4;
}

/* ------------------------------------------------------------ コンタクトボックス */
.sec-contactbox-front,
.sec-contactbox {
  padding: 30px 0 30px;
  position: relative;
}
.sec-contactbox-front .frame-sec-up,
.sec-contactbox-front .frame-sec-down,
.sec-contactbox .frame-sec-up,
.sec-contactbox .frame-sec-down {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
}
.sec-contactbox-front .frame-sec-up,
.sec-contactbox .frame-sec-up {
  top: 0;
  left: auto;
}
.sec-contactbox-front .frame-sec-down,
.sec-contactbox .frame-sec-down {
  bottom: inherit;
  left: auto;
}
@media (max-width: 480px) {
  .sec-contactbox-front .frame-sec-down,
  .sec-contactbox .frame-sec-down {
    bottom: 0;
  }
}
.sec-contactbox-front .frame-sec-down,
.sec-contactbox .frame-sec-down {
  transform: scale(1, -1);
}
.sec-contactbox-front .box-contact-yohaku,
.sec-contactbox .box-contact-yohaku {
  margin-left: auto;
  margin-right: auto;
  padding: 24px 48px;
  background-color: white;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-yohaku,
  .sec-contactbox .box-contact-yohaku {
    width: 94%;
    padding: 20px;
  }
}
.sec-contactbox-front h2,
.sec-contactbox h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  padding-top: 1rem;
  font-family: "メイリオ";
  font-size: 1.8em;
  font-weight: 500;
}
.sec-contactbox-front h2::before,
.sec-contactbox h2::before {
  font-family: "icomoon";
  content: "\e905";
  margin-right: 10px;
  font-size: 26px;
  color: #43896b;
}
.sec-contactbox-front .box-contact-outer,
.sec-contactbox .box-contact-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer,
  .sec-contactbox .box-contact-outer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail,
.sec-contactbox-front .box-contact-outer .box-contact-tel,
.sec-contactbox .box-contact-outer .box-contact-mail,
.sec-contactbox .box-contact-outer .box-contact-tel {
  display: block;
  width: 40%;
  text-align: center;
}
@media (max-width: 1023px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail,
  .sec-contactbox-front .box-contact-outer .box-contact-tel,
  .sec-contactbox .box-contact-outer .box-contact-mail,
  .sec-contactbox .box-contact-outer .box-contact-tel {
    width: 42%;
  }
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail,
  .sec-contactbox-front .box-contact-outer .box-contact-tel,
  .sec-contactbox .box-contact-outer .box-contact-mail,
  .sec-contactbox .box-contact-outer .box-contact-tel {
    width: 90%;
    margin-bottom: 2rem;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .spn-mid,
.sec-contactbox-front .box-contact-outer .box-contact-tel .spn-mid,
.sec-contactbox .box-contact-outer .box-contact-mail .spn-mid,
.sec-contactbox .box-contact-outer .box-contact-tel .spn-mid {
  font-size: 24px;
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form,
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form,
.sec-contactbox .box-contact-outer .box-contact-mail .btn-form,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form {
  display: flex;
  justify-content: center;
  width: auto;
  height: 108px;
  padding: 12px;
  font-weight: bold;
  color: white;
  background-color: #43896b;
  border: 2px solid #43896b;
  border-radius: 12px;
  transition: all, 0.2s;
  cursor: pointer;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form,
  .sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form,
  .sec-contactbox .box-contact-outer .box-contact-mail .btn-form,
  .sec-contactbox .box-contact-outer .box-contact-tel .btn-form {
    height: 80px;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form:hover,
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form:hover,
.sec-contactbox .box-contact-outer .box-contact-mail .btn-form:hover,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form:hover {
  color: #43896b;
  background-color: white;
  transition: all, 0.2s;
  filter: drop-shadow(4px 4px 6px rgba(10, 10, 10, 0.2));
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form:hover span::before,
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form:hover span::before,
.sec-contactbox .box-contact-outer .box-contact-mail .btn-form:hover span::before,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form:hover span::before {
  color: #43896b;
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form span,
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form span,
.sec-contactbox .box-contact-outer .box-contact-mail .btn-form span,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form span,
  .sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form span,
  .sec-contactbox .box-contact-outer .box-contact-mail .btn-form span,
  .sec-contactbox .box-contact-outer .box-contact-tel .btn-form span {
    font-size: 26px;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .btn-form span::before,
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form span::before,
.sec-contactbox .box-contact-outer .box-contact-mail .btn-form span::before,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form span::before {
  content: "\e916";
  font-family: "icomoon";
  margin-right: 6px;
  font-size: 46px;
  color: white;
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .spn-sm-r,
.sec-contactbox-front .box-contact-outer .box-contact-tel .spn-sm-r,
.sec-contactbox .box-contact-outer .box-contact-mail .spn-sm-r,
.sec-contactbox .box-contact-outer .box-contact-tel .spn-sm-r {
  display: block;
  margin: 1rem auto 0;
  font-size: 18px;
  color: #c60000;
}
@media (max-width: 834px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail .spn-sm-r,
  .sec-contactbox-front .box-contact-outer .box-contact-tel .spn-sm-r,
  .sec-contactbox .box-contact-outer .box-contact-mail .spn-sm-r,
  .sec-contactbox .box-contact-outer .box-contact-tel .spn-sm-r {
    font-size: 16px;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail .spn-sm,
.sec-contactbox-front .box-contact-outer .box-contact-tel .spn-sm,
.sec-contactbox .box-contact-outer .box-contact-mail .spn-sm,
.sec-contactbox .box-contact-outer .box-contact-tel .spn-sm {
  display: block;
  margin: 0 auto 1rem;
  font-size: 14px;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail .spn-sm,
  .sec-contactbox-front .box-contact-outer .box-contact-tel .spn-sm,
  .sec-contactbox .box-contact-outer .box-contact-mail .spn-sm,
  .sec-contactbox .box-contact-outer .box-contact-tel .spn-sm {
    margin-bottom: 0;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-mail a,
.sec-contactbox-front .box-contact-outer .box-contact-tel a,
.sec-contactbox .box-contact-outer .box-contact-mail a,
.sec-contactbox .box-contact-outer .box-contact-tel a {
  font-size: 16px;
  text-decoration: none;
}
@media (max-width: 480px) {
  .sec-contactbox-front .box-contact-outer .box-contact-mail a,
  .sec-contactbox-front .box-contact-outer .box-contact-tel a,
  .sec-contactbox .box-contact-outer .box-contact-mail a,
  .sec-contactbox .box-contact-outer .box-contact-tel a {
    font-size: 14px;
  }
}
.sec-contactbox-front .box-contact-outer .box-contact-tel .btn-form span::before,
.sec-contactbox .box-contact-outer .box-contact-tel .btn-form span::before {
  content: "\e918";
  font-size: 30px;
}
.sec-contactbox-front .box-contact-outer .box-contact-tel table,
.sec-contactbox .box-contact-outer .box-contact-tel table {
  width: auto;
  margin: 0 auto;
}
.sec-contactbox-front .box-contact-outer .box-contact-tel table tr:nth-of-type(2n + 1),
.sec-contactbox-front .box-contact-outer .box-contact-tel th,
.sec-contactbox .box-contact-outer .box-contact-tel table tr:nth-of-type(2n + 1),
.sec-contactbox .box-contact-outer .box-contact-tel th {
  background: none;
}
.sec-contactbox-front .box-contact-outer .box-contact-tel th,
.sec-contactbox .box-contact-outer .box-contact-tel th {
  padding: 0;
  text-align: right;
  font-weight: normal;
}
.sec-contactbox-front .box-contact-outer .box-contact-tel td,
.sec-contactbox .box-contact-outer .box-contact-tel td {
  padding: 0;
  text-align: left;
}

.sec-contactbox {
  background-color: #ff662e;
}
.sec-contactbox h2 {
  margin-bottom: 1rem;
}
.sec-contactbox .box-contact-outer {
  padding-top: 24px;
}

/* ------------------------------------------------------------ お客さまの声 */
.sec-voice-top {
  padding: 30px 0 30px;
  background-color: #fff6eb;
  position: relative;
}
.sec-voice-top .frame-sec-up,
.sec-voice-top .frame-sec-down {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
}
.sec-voice-top .frame-sec-up {
  top: 0;
  left: auto;
}
.sec-voice-top .frame-sec-down {
  bottom: 0;
  left: auto;
}
.sec-voice-top h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 28px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .sec-voice-top h2 {
    margin-bottom: 2rem;
  }
}
.sec-voice-top h2::before {
  font-family: "icomoon";
  content: "\e91e";
  margin-right: 10px;
  font-size: 30px;
  color: #ff662e;
}
.sec-voice-top .area-voice-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .sec-voice-top .area-voice-top {
    justify-content: space-around;
    margin: 0 8px;
  }
}
.sec-voice-top .area-voice-top .box-voice {
  display: flex;
  justify-content: flex-start;
  width: 49%;
  padding: 16px;
  margin-bottom: 2rem;
  background-color: white;
  border-radius: 10px;
}
@media (max-width: 1023px) {
  .sec-voice-top .area-voice-top .box-voice {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 375px) {
  .sec-voice-top .area-voice-top .box-voice {
    margin-left: auto;
    margin-right: auto;
  }
}
.sec-voice-top .area-voice-top .box-voiceinfo-outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.sec-voice-top .area-voice-top .box-voice-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
}
@media (max-width: 480px) {
  .sec-voice-top .area-voice-top .box-voice-title {
    font-size: 16px;
  }
}
.sec-voice-top .area-voice-top .box-voice-title::before {
  font-family: "icomoon";
  content: "\e91e";
  margin-right: 6px;
  font-size: 16px;
}
.sec-voice-top .area-voice-top img {
  width: 100px;
  height: 100%;
  margin-right: 1rem;
}
.sec-voice-top .area-voice-top .box-voice-info {
  text-align: right;
}
@media (max-width: 480px) {
  .sec-voice-top .area-voice-top .box-voice-info {
    font-size: 14px;
  }
}
.sec-voice-top .area-voice-top .box-btn {
  display: block;
  width: 100%;
  text-align: right;
}
.sec-voice-top .area-voice-top a {
  text-decoration: none;
  color: #474747;
  border: 1px solid white;
  transition: all, 0.2s;
}
.sec-voice-top .area-voice-top a:hover {
  background-color: #fff6eb;
  border: 1px solid #ff662e;
  filter: drop-shadow(4px 4px 4px rgba(10, 10, 10, 0.2));
  transition: all, 0.2s;
}
.sec-voice-top .area-voice-top .a-toptovoice {
  border: none;
  border-radius: 0;
  font-size: 1rem;
}
.sec-voice-top .area-btn-link {
  margin-top: 2rem;
}

/* ------------------------------ お客様の声　カスタム記事 */
.single-voices .voice-info {
  margin-bottom: 2rem;
}
.single-voices p {
  margin: 12px 0 3rem;
}
.single-voices .spn-mds {
  display: block;
  width: auto;
  padding-bottom: 4px;
  font-size: 1rem;
  border-bottom: 1px solid #ffa585;
}

/* ------------------------------------------------------------------------------------------ 個別ページ */
/* ------------------------------------------------------------ 共通 */
.icon-logo,
.icon-bed-big,
.icon-logo-light,
.icon-logo-sheet,
.icon-logo-sheet-r,
.icon-logo-q,
.icon-logo-voice {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 64px;
}
@media (max-width: 480px) {
  .icon-logo,
  .icon-bed-big,
  .icon-logo-light,
  .icon-logo-sheet,
  .icon-logo-sheet-r,
  .icon-logo-q,
  .icon-logo-voice {
    font-size: 48px;
  }
}

.icon-logo:before {
  content: "\e924";
  color: #ff662e;
  font-size: 72px;
}

.icon-bed-big:before {
  content: "\e901";
  color: #ff662e;
}

.icon-logo-light:before {
  content: "\e910";
  color: #ff662e;
}

.icon-logo-sheet:before {
  content: "\e91d";
  color: #ff662e;
}

.icon-logo-sheet-r:before {
  content: "\e91d";
  color: #73ba9b;
}

.icon-logo-q:before {
  content: "\e919";
  color: #73ba9b;
}

.icon-logo-voice:before {
  content: "\e91e";
  color: #ff662e;
}

@media (max-width: 480px) {
  .sec-about,
  .sec-introduction,
  .sec-price {
    margin-top: 2rem;
  }
}

.box-sec-top,
.box-sec-intro {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .box-sec-top,
  .box-sec-intro {
    width: 90%;
  }
}
.box-sec-top h1,
.box-sec-top h2,
.box-sec-intro h1,
.box-sec-intro h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  font-weight: 500;
  margin-bottom: 3rem;
  line-height: 1.6;
}
@media (max-width: 480px) {
  .box-sec-top h1,
  .box-sec-top h2,
  .box-sec-intro h1,
  .box-sec-intro h2 {
    font-size: 22px;
    text-align: center;
    line-height: 1.5;
  }
}
@media (max-width: 375px) {
  .box-sec-top h1,
  .box-sec-top h2,
  .box-sec-intro h1,
  .box-sec-intro h2 {
    font-size: 20px;
  }
}
.box-sec-top h1::before, .box-sec-top h1::after,
.box-sec-top h2::before,
.box-sec-top h2::after,
.box-sec-intro h1::before,
.box-sec-intro h1::after,
.box-sec-intro h2::before,
.box-sec-intro h2::after {
  font-family: "icomoon";
  content: "\e926";
  font-size: 22px;
  color: #ff662e;
}
@media (max-width: 480px) {
  .box-sec-top h1::before, .box-sec-top h1::after,
  .box-sec-top h2::before,
  .box-sec-top h2::after,
  .box-sec-intro h1::before,
  .box-sec-intro h1::after,
  .box-sec-intro h2::before,
  .box-sec-intro h2::after {
    font-size: 20px;
  }
}
.box-sec-top h1::before,
.box-sec-top h2::before,
.box-sec-intro h1::before,
.box-sec-intro h2::before {
  margin-right: 18px;
}
@media (max-width: 480px) {
  .box-sec-top h1::before,
  .box-sec-top h2::before,
  .box-sec-intro h1::before,
  .box-sec-intro h2::before {
    margin-right: 14px;
  }
}
.box-sec-top h1::after,
.box-sec-top h2::after,
.box-sec-intro h1::after,
.box-sec-intro h2::after {
  margin-left: 18px;
  transform: scale(-1, 1);
}
@media (max-width: 480px) {
  .box-sec-top h1::after,
  .box-sec-top h2::after,
  .box-sec-intro h1::after,
  .box-sec-intro h2::after {
    margin-left: 14px;
  }
}
.box-sec-top .h1-r::before, .box-sec-top .h1-r::after,
.box-sec-top .h2-r::before,
.box-sec-top .h2-r::after,
.box-sec-intro .h1-r::before,
.box-sec-intro .h1-r::after,
.box-sec-intro .h2-r::before,
.box-sec-intro .h2-r::after {
  color: #73ba9b;
}
.box-sec-top .h2-nowing,
.box-sec-intro .h2-nowing {
  justify-content: flex-start;
  margin: 3rem 0 2rem;
  border-bottom: 2px dotted #ff662e;
  font-size: 1.3rem;
  font-weight: 500;
}
@media (max-width: 375px) {
  .box-sec-top .h2-nowing,
  .box-sec-intro .h2-nowing {
    font-size: 20px;
  }
}
.box-sec-top .h2-nowing::before,
.box-sec-intro .h2-nowing::before {
  margin-right: 12px;
  color: #ff662e;
  font-family: icomoon;
  font-size: 22px;
  content: "\e907";
}
.box-sec-top .h2-nowing::after,
.box-sec-intro .h2-nowing::after {
  content: none;
}
.box-sec-top .h2-about-omoi,
.box-sec-intro .h2-about-omoi {
  margin-top: 6rem;
}
.box-sec-top .h3-about-omoi,
.box-sec-intro .h3-about-omoi {
  justify-content: flex-start;
  margin: 3rem 0 2rem;
  border-bottom: 2px dotted #ff662e;
  font-size: 1.5rem;
  font-weight: 500;
}
.box-sec-top .h3-about-omoi::before,
.box-sec-intro .h3-about-omoi::before {
  margin-right: 12px;
  color: #ff662e;
  font-family: icomoon;
  font-size: 22px;
  content: "\e91f";
}
@media (max-width: 480px) {
  .box-sec-top .h3-about-omoi,
  .box-sec-intro .h3-about-omoi {
    font-size: 20px;
  }
}
.box-sec-top p,
.box-sec-intro p {
  line-height: 1.6;
}
.box-sec-top .img-intro-intro,
.box-sec-intro .img-intro-intro {
  display: block;
  margin: 1rem auto;
}
.box-sec-top .img-about-omoi,
.box-sec-intro .img-about-omoi {
  display: block;
  margin: 3rem auto 1rem;
  border-radius: 10px;
}

.box-sec-top {
  width: 100%;
}
@media (max-width: 834px) {
  .box-sec-top {
    margin-top: 48px;
  }
}
@media (max-width: 480px) {
  .box-sec-top h1::before, .box-sec-top h1::after {
    display: none;
  }
}
@media (max-width: 480px) {
  .box-sec-top .icon-logo {
    margin-top: 1rem;
  }
}

.spn-strong-fjs {
  color: #ff662e;
  font-size: 1.1rem;
  font-weight: 700;
}

/* ------------------------------------------------------------ 店舗紹介 */
.area-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-sec-main {
  width: 92%;
  margin-bottom: 2rem;
}

.box-item-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 60px 0 80px;
}
@media (max-width: 480px) {
  .box-item-outer {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}

.box-item {
  width: 30%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(../img/parts/balloon-p.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (max-width: 1023px) {
  .box-item {
    width: 30%;
  }
}
@media (max-width: 480px) {
  .box-item {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.box-item span {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  z-index: 30;
}
.box-item p {
  position: relative;
  z-index: 30;
}

.box-kuchikomi {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  font-size: 32px;
  border: 1px solid #ff662e;
}

/* ------------------------------ スタッフ */
.sec-staff {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px dotted #ff662e;
}

.area-staff {
  width: 100%;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}
.area-staff:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 1023px) {
  .area-staff {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 834px) {
  .area-staff {
    width: 92%;
    margin-bottom: 2rem;
  }
}
.area-staff .spn-mds-about {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 4px 4px 4px 12px;
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: 500;
  color: white;
  background-color: #ffa585;
  border-radius: 30px;
}
.area-staff .spn-mds-about::before {
  font-family: "icomoon";
  content: "\e915";
  margin-right: 6px;
  font-size: 24px;
  color: white;
  transform: scale(-1, 1);
}
.area-staff .spn-mds-pro {
  color: #474747;
  background-color: #bbddce;
}
.area-staff .spn-mds-pro::before {
  color: #474747;
}
.area-staff .box-staff {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 12px dotted #ffa585;
}
.area-staff .box-staff:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
@media (max-width: 834px) {
  .area-staff .box-staff {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 6px dotted #ffa585;
  }
}
.area-staff .btn-link-mystory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid #ff662e;
  border-radius: 10px;
}
.area-staff .btn-link-mystory .icon2-more-arrow {
  margin-bottom: 0 !important;
  font-size: 1rem !important;
}
.area-staff .box-staff-up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 480px) {
  .area-staff .box-staff-up {
    flex-direction: column;
  }
}
.area-staff .box-staff-up .staff-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  border: 1px solid #ff662e;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .area-staff .box-staff-up .staff-image {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.area-staff .box-staff-up .staff-image img {
  border-radius: 12px 12px 0 0;
}
.area-staff .box-staff-up .staff-image .box-staff-info {
  padding: 12px;
  width: 100%;
  text-align: center;
}
.area-staff .box-staff-up .staff-image .box-staff-info span {
  display: block;
  width: 100%;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-work {
  font-size: 14px;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-name {
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-name::before, .area-staff .box-staff-up .staff-image .box-staff-info .spn-name::after {
  flex-grow: 1;
  content: "";
  height: 1px;
  background-color: #ffa585;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-name::before {
  margin-right: 1rem;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-name::after {
  margin-left: 1rem;
}
.area-staff .box-staff-up .staff-image .box-staff-info .spn-name-sub {
  font-size: 12px;
}
.area-staff .box-staff-up .staff-speech {
  display: block;
  width: 55%;
}
@media (max-width: 480px) {
  .area-staff .box-staff-up .staff-speech {
    width: 100%;
  }
}
.area-staff .box-staff-up .staff-speech span {
  display: block;
  font-size: 28px;
  line-height: 1.4;
  color: #521600;
  margin-bottom: 3rem;
}
@media (max-width: 1023px) {
  .area-staff .box-staff-up .staff-speech span {
    margin-bottom: 1.5rem;
    font-size: 24px;
  }
}
.area-staff .box-staff-up .staff-speech p {
  padding-right: 2rem;
  line-height: 1.6;
}
@media (max-width: 480px) {
  .area-staff .box-staff-up .staff-speech p {
    padding-right: 0;
  }
}
.area-staff .box-staff-low {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #ff662e;
  border-radius: 10px;
  margin-top: 2rem;
  padding: 16px 40px;
}
@media (max-width: 480px) {
  .area-staff .box-staff-low {
    flex-direction: column;
    margin-top: 0;
    padding: 16px;
  }
}
.area-staff .box-staff-low span {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px dotted #ff662e;
}
@media (max-width: 480px) {
  .area-staff .box-staff-low span {
    font-size: 20px;
    line-height: 1.5;
  }
}
.area-staff .box-staff-low span::before {
  font-family: "icomoon";
  content: "\e915";
  margin-right: 6px;
  font-size: 24px;
  color: #ff662e;
  transform: scale(-1, 1);
}
.area-staff .box-staff-low .box-l {
  width: 48%;
}
@media (max-width: 480px) {
  .area-staff .box-staff-low .box-l {
    width: 100%;
  }
}
.area-staff .box-staff-low .box-l p,
.area-staff .box-staff-low .box-l li {
  font-size: 0.9rem;
}
.area-staff .box-staff-low .box-r {
  width: 48%;
}
@media (max-width: 480px) {
  .area-staff .box-staff-low .box-r {
    width: 100%;
  }
}
.area-staff .box-staff-low .box-r p {
  font-size: 0.9rem;
}
.area-staff .box-staff-low .box-r li {
  list-style: none;
  font-size: 0.9rem;
}
.area-staff .box-staff-low table tr:nth-of-type(2n+1) {
  background: none;
}
.area-staff .box-staff-low table td {
  font-size: 0.9rem;
}
.area-staff .box-staffs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .area-staff .box-staffs {
    flex-direction: column;
  }
}
.area-staff .box-staffs .staff-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 32%;
  border: 1px solid #ff662e;
  border-radius: 12px;
}
@media (max-width: 1023px) {
  .area-staff .box-staffs .staff-image {
    width: 49%;
    margin-bottom: 1.2rem;
  }
}
@media (max-width: 480px) {
  .area-staff .box-staffs .staff-image {
    width: 100%;
  }
}
.area-staff .box-staffs .staff-image img {
  border-radius: 12px 12px 0 0;
}
.area-staff .box-staffs .staff-image .box-staff-info {
  padding: 12px;
  width: 100%;
  text-align: center;
}
.area-staff .box-staffs .staff-image .box-staff-info span {
  display: block;
  width: 100%;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-work {
  font-size: 14px;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-name {
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-name::before, .area-staff .box-staffs .staff-image .box-staff-info .spn-name::after {
  flex-grow: 1;
  content: "";
  height: 1px;
  background-color: #ffa585;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-name::before {
  margin-right: 1rem;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-name::after {
  margin-left: 1rem;
}
.area-staff .box-staffs .staff-image .box-staff-info .spn-name-sub {
  font-size: 12px;
}
.area-staff .box-staffs .staff-image .box-staff-info p {
  padding: 0 20px;
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
}
@media (max-width: 480px) {
  .area-staff .box-staffs .staff-image .box-staff-info p {
    margin-bottom: 0;
  }
}
.area-staff .box-pro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
@media (max-width: 480px) {
  .area-staff .box-pro {
    flex-direction: column-reverse;
  }
}
.area-staff .box-pro .box-pro-img {
  display: block;
  width: 140px;
  height: 140px;
}
.area-staff .box-pro .box-pro-img img {
  border-radius: 10px;
  object-fit: cover;
}
.area-staff .box-pro .box-pro-voice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 1rem;
  border: 1px solid #73ba9b;
  border-radius: 10px;
  position: relative;
}
@media (max-width: 1023px) {
  .area-staff .box-pro .box-pro-voice {
    flex-direction: column;
    width: 74%;
  }
}
@media (max-width: 480px) {
  .area-staff .box-pro .box-pro-voice {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.area-staff .box-pro .box-pro-voice::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  left: -24px;
  top: 25px;
  border-right: 24px solid #73ba9b;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
}
@media (max-width: 480px) {
  .area-staff .box-pro .box-pro-voice::before {
    left: 50px;
    top: inherit;
    bottom: -50px;
    border-right: 24px solid transparent;
    border-left: 24px solid transparent;
    border-top: 24px solid #73ba9b;
  }
}
.area-staff .box-pro .box-pro-voice::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  left: -22px;
  top: 25px;
  border-right: 24px solid white;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
}
@media (max-width: 480px) {
  .area-staff .box-pro .box-pro-voice::after {
    left: 50px;
    top: inherit;
    bottom: -48px;
    border-right: 24px solid transparent;
    border-left: 24px solid transparent;
    border-top: 24px solid white;
  }
}
.area-staff .box-pro .box-l {
  width: 70%;
  text-align: right;
}
@media (max-width: 1023px) {
  .area-staff .box-pro .box-l {
    width: 100%;
  }
}
.area-staff .box-pro .spn-lg {
  display: block;
  text-align: left;
  font-size: 28px;
  line-height: 1.4;
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .area-staff .box-pro .spn-lg {
    font-size: 24px;
  }
}
.area-staff .box-pro .spn-sm {
  display: block;
  font-size: 16px;
}
.area-staff .box-pro a {
  display: block;
  padding: 4px 16px;
  background-color: #f1f8f5;
  border: 1px solid #73ba9b;
  border-radius: 20px;
  text-decoration: none;
  color: #474747;
  transition: all, 0.2s;
}
@media (max-width: 1023px) {
  .area-staff .box-pro a {
    margin-top: 1rem;
  }
}
.area-staff .box-pro a:hover {
  color: #c60000;
  filter: drop-shadow(4px 4px 5px rgba(10, 10, 10, 0.2));
  transition: all, 0.2s;
}

/* ------------------------------------------------------------ 設備 */
.sec-setsubi {
  padding-top: 3rem;
  margin: 3rem auto 5rem;
  border-top: 1px dotted #ff662e;
}
@media (max-width: 480px) {
  .sec-setsubi {
    width: 92%;
  }
}
@media (max-width: 480px) {
  .sec-setsubi h2 {
    font-size: 22px;
  }
}

.box-setsubi-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .box-setsubi-outer {
    flex-direction: column;
  }
}
.box-setsubi-outer .box-setsubi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 32%;
}
@media (max-width: 480px) {
  .box-setsubi-outer .box-setsubi {
    width: 100%;
  }
}
.box-setsubi-outer img {
  border-radius: 10px;
}
.box-setsubi-outer span {
  font-size: 18px;
}
.box-setsubi-outer p {
  margin: 0.3rem 0 1.5rem;
  font-size: 14px;
}

/* ------------------------------------------------------------------------------------------ 初めての方へ */
.box-somosomo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 20px 40px;
  border: 1px solid #cc3600;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .box-somosomo {
    flex-direction: column;
    padding: 12px 10px;
    margin-bottom: 0;
  }
}
.box-somosomo .box-l {
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .box-somosomo .box-l {
    width: 92%;
  }
}
.box-somosomo span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #521600;
}
@media (max-width: 480px) {
  .box-somosomo span {
    font-size: 26px;
  }
}
@media (max-width: 375px) {
  .box-somosomo span {
    font-size: 22px;
  }
}
.box-somosomo span::before {
  font-family: "icomoon";
  content: "\e927";
  margin-right: 6px;
  font-size: 32px;
  color: #ff662e;
}
.box-somosomo p {
  width: 80%;
  margin: 1rem auto;
}
@media (max-width: 1023px) {
  .box-somosomo p {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .box-somosomo p {
    width: 100%;
    line-height: 1.6;
  }
}
.box-somosomo img {
  display: block;
  margin-top: 1rem;
  border-radius: 12px;
}

/* ------------------------------------------------------------ メリット */
.box-more-merit {
  padding: 5rem 0 3rem;
  margin: 3rem auto;
  border-top: 1px dotted #ff662e;
}
@media (max-width: 480px) {
  .box-more-merit {
    padding-top: 3rem;
    margin-top: 2rem;
  }
}
.box-more-merit h3 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .box-more-merit h3 {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    font-size: 24px;
  }
}
.box-more-merit h3::before {
  font-family: "icomoon";
  content: "\e910";
  margin-right: 6px;
  font-size: 40px;
  color: #ff662e;
}
.box-more-merit h3 .spn-num-lg {
  font-size: 60px;
  font-style: italic;
  color: #ff662e;
  margin: 0 4px;
  line-height: 1;
}
.box-more-merit .box-mmerit-outer {
  width: 100%;
  margin-top: 5rem;
  padding: 30px 48px;
  margin-bottom: 5rem;
  border: 1px solid #ff662e;
  border-radius: 12px;
  position: relative;
  /* 
  .spn-num {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      font-size: 72px;
      font-style: italic;
      text-align: left;
      color: white;
      background-color: i.$primary;
      border-radius: 50%;
      position: absolute;
      top: -40px;
      left: -40px;

      @include i.tab {
          width: 50px;
          height: 50px;
          font-size: 42px;
          top: -25px;
          left: -8px;
      }
  }
  */
}
@media (max-width: 834px) {
  .box-more-merit .box-mmerit-outer {
    width: 92%;
    margin: 5rem auto 0;
    padding: 30px 1rem;
  }
}
.box-more-merit .box-mmerit-outer .num-merit {
  display: block;
  text-align: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  left: -40px;
}
@media (max-width: 480px) {
  .box-more-merit .box-mmerit-outer .num-merit {
    width: 52px;
    height: 52px;
    top: -26px;
    left: -10px;
  }
}
.box-more-merit .box-mmerit-outer .spn-title {
  display: block;
  width: auto;
  padding: 2px 12px;
  font-size: 28px;
  font-weight: 500;
  background-color: white;
  position: absolute;
  top: -24px;
  left: 56px;
  line-height: 1.3;
}
@media (max-width: 1023px) {
  .box-more-merit .box-mmerit-outer .spn-title {
    font-size: 24px;
  }
}
@media (max-width: 834px) {
  .box-more-merit .box-mmerit-outer .spn-title {
    top: -20px;
  }
}
@media (max-width: 480px) {
  .box-more-merit .box-mmerit-outer .spn-title {
    font-size: 20px;
    line-height: 1.4;
  }
}
.box-more-merit .box-mmerit-outer .box-mmerit,
.box-more-merit .box-mmerit-outer .box-mmerit-r {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 1023px) {
  .box-more-merit .box-mmerit-outer .box-mmerit,
  .box-more-merit .box-mmerit-outer .box-mmerit-r {
    flex-direction: column;
  }
}
.box-more-merit .box-mmerit-outer .box-mmerit .box-l,
.box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
  width: 56%;
  padding-right: 3rem;
}
@media (max-width: 1023px) {
  .box-more-merit .box-mmerit-outer .box-mmerit .box-l,
  .box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 834px) {
  .box-more-merit .box-mmerit-outer .box-mmerit .box-l,
  .box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
    width: 90%;
    padding-right: 0;
  }
}
@media (max-width: 375px) {
  .box-more-merit .box-mmerit-outer .box-mmerit .box-l,
  .box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .box-more-merit .box-mmerit-outer .box-mmerit .p-topmargin-large,
  .box-more-merit .box-mmerit-outer .box-mmerit-r .p-topmargin-large {
    margin-top: 42px;
  }
}
.box-more-merit .box-mmerit-outer .box-mmerit img,
.box-more-merit .box-mmerit-outer .box-mmerit-r img {
  display: block;
  width: 42%;
  border-radius: 8px;
}
@media (max-width: 1023px) {
  .box-more-merit .box-mmerit-outer .box-mmerit img,
  .box-more-merit .box-mmerit-outer .box-mmerit-r img {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .box-more-merit .box-mmerit-outer .box-mmerit img,
  .box-more-merit .box-mmerit-outer .box-mmerit-r img {
    width: 100%;
  }
}
.box-more-merit .box-mmerit-outer .box-mmerit-r {
  flex-direction: row-reverse;
}
@media (max-width: 834px) {
  .box-more-merit .box-mmerit-outer .box-mmerit-r {
    flex-direction: column;
  }
}
.box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
  padding-left: 3rem;
}
@media (max-width: 834px) {
  .box-more-merit .box-mmerit-outer .box-mmerit-r .box-l {
    padding-left: 0;
  }
}
.box-more-merit .p-92 {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 480px) {
  .box-more-merit .p-92 {
    width: 80%;
  }
}

/* ------------------------------------------------------------ 流れ */
.sec-flow {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #fff9f6;
}
@media (max-width: 480px) {
  .sec-flow {
    padding-top: 3rem;
  }
}

.box-flow-outer {
  margin-top: 3rem;
}
@media (max-width: 1023px) {
  .box-flow-outer {
    width: 92%;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.box-flow-outer .box-flow,
.box-flow-outer .box-flow-r {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3rem;
}
@media (max-width: 480px) {
  .box-flow-outer .box-flow,
  .box-flow-outer .box-flow-r {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}
.box-flow-outer .box-flow img,
.box-flow-outer .box-flow-r img {
  display: block;
  width: 32%;
  border-radius: 8px;
}
@media (max-width: 480px) {
  .box-flow-outer .box-flow img,
  .box-flow-outer .box-flow-r img {
    width: 100%;
  }
}
.box-flow-outer .box-flow .box-flow-text,
.box-flow-outer .box-flow-r .box-flow-text {
  width: 44%;
  margin-left: 2rem;
}
@media (max-width: 480px) {
  .box-flow-outer .box-flow .box-flow-text,
  .box-flow-outer .box-flow-r .box-flow-text {
    width: 100%;
    margin: 1rem 0 0;
  }
}
.box-flow-outer .box-flow .box-flow-text .spn-num,
.box-flow-outer .box-flow-r .box-flow-text .spn-num {
  margin-right: 1rem;
  font-size: 72px;
  font-weight: 500;
  font-style: italic;
  color: #ff662e;
  line-height: 1;
}
.box-flow-outer .box-flow .box-flow-text .spn-title,
.box-flow-outer .box-flow-r .box-flow-text .spn-title {
  font-size: 28px;
  font-weight: 500;
}
.box-flow-outer .box-flow-r {
  justify-content: flex-end;
}

/* ------------------------------------------------------------ よくある質問 */
.sec-qa {
  padding-top: 2rem;
  background-color: #f1f8f5;
}

.box-pa-label {
  display: block;
  width: 50%;
  margin: 0 auto;
  padding: 4px 12px;
  text-align: center;
  font-size: 18px;
  background-color: white;
  border-top: 1px solid #43896b;
  border-left: 1px solid #43896b;
  border-right: 1px solid #43896b;
  border-radius: 10px 10px 0 0;
}

.box-qa-outer {
  display: block;
  width: 100%;
  margin: 0 auto 5rem;
  padding: 20px 40px;
  background-color: white;
  border: 1px solid #43896b;
  border-radius: 10px;
}
@media (max-width: 834px) {
  .box-qa-outer {
    width: 92%;
    padding: 20px;
  }
}
.box-qa-outer .box-qa {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px dotted #43896b;
}
.box-qa-outer .box-qa .box-up {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.box-qa-outer .box-qa .box-up .box-q {
  display: block;
  width: 32px;
  margin-right: 12px;
  font-size: 26px;
  color: #43896b;
}
.box-qa-outer .box-qa .box-up .box-q-title {
  display: block;
  font-size: 20px;
  font-weight: 500;
}
.box-qa-outer .box-qa .box-down {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.box-qa-outer .box-qa .box-down .box-a {
  display: block;
  width: 40px;
  margin-right: 12px;
  font-size: 26px;
  color: #5c5c5c;
}
.box-qa-outer .box-qa .box-down .box-q-answer {
  display: block;
  font-size: 16px;
  line-height: 1.5;
}

/* ------------------------------------------------------------------------------------------ サービスと料金 */
.area-price-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 40px;
  margin-bottom: 3rem;
  background-color: #73ba9b;
  border-radius: 10px;
}
@media (max-width: 1023px) {
  .area-price-page {
    padding: 20px;
  }
}
@media (max-width: 834px) {
  .area-price-page {
    flex-direction: column;
    width: 92%;
    margin: 0 auto 2rem;
  }
}
.area-price-page .box-l {
  width: 66%;
  padding: 20px 40px;
  background-color: white;
}
@media (max-width: 1240px) {
  .area-price-page .box-l {
    width: 60%;
    padding: 20px;
  }
}
@media (max-width: 834px) {
  .area-price-page .box-l {
    width: 100%;
  }
}
.area-price-page .box-l h3 {
  display: block;
  width: 100%;
  font-size: 32px;
  text-align: center;
  margin-bottom: 1rem;
  border-bottom: 1px dotted #224435;
}
.area-price-page .box-l .spn-title {
  width: 90%;
  margin: 0 auto;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 1023px) {
  .area-price-page .box-l .spn-title {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .area-price-page .box-l .spn-title {
    font-size: 20px;
    line-height: 1.5;
  }
}
.area-price-page .box-l .spn-title::before {
  font-family: "icomoon";
  content: "\e915";
  margin-right: 6px;
  font-size: 22px;
  color: #73ba9b;
}
.area-price-page .box-l p {
  width: 80%;
  margin: 8px auto 2rem;
  font-size: 1rem;
  line-height: 1.7;
}
@media (max-width: 1023px) {
  .area-price-page .box-l p {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .area-price-page .box-l p {
    width: 100%;
  }
}
.area-price-page .box-l img {
  display: block;
  width: 80%;
  margin: 0 auto 2rem;
  border-radius: 8px;
}
@media (max-width: 834px) {
  .area-price-page .box-l img {
    width: 100%;
  }
}
.area-price-page .box-l img:first-of-type {
  margin-top: 3rem;
}
.area-price-page .box-r {
  width: 30%;
}
@media (max-width: 1240px) {
  .area-price-page .box-r {
    width: 38%;
  }
}
@media (max-width: 834px) {
  .area-price-page .box-r {
    width: 100%;
  }
}
.area-price-page .box-r .box-price {
  width: 100%;
  border-radius: 0;
  margin-bottom: 2rem;
  text-align: center;
  background-color: white;
}
@media (max-width: 834px) {
  .area-price-page .box-r .box-price {
    margin: 1rem auto;
  }
}
.area-price-page .box-r .box-price .spn-price {
  font-size: 3rem;
  margin-right: 8px;
}
.area-price-page .box-r .box-price .spn-md {
  margin-right: 1rem;
  font-size: 18px;
}
.area-price-page .box-r .box-price .spn-lg {
  margin-right: 8px;
  font-size: 2.5rem;
}
.area-price-page .box-r .box-price .box-ticket-outer-outer {
  padding-top: 1rem;
  border-top: 1px dotted #707070;
}
.area-price-page .box-r .box-price .box-ticket-outer-outer .box-ticket span:first-of-type {
  margin-right: 12px;
}
.area-price-page .box-r .box-price .box-ticket-outer-outer .box-ticket span:last-of-type {
  margin-right: 8px;
  font-size: 2.5rem;
}
.area-price-page .box-r .box-add {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: white;
}
.area-price-page .box-r .box-add .spn-title {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
@media (max-width: 480px) {
  .area-price-page .box-r .box-add .spn-title {
    font-size: 20px;
    line-height: 1.5;
  }
}
.area-price-page .box-r .box-add .spn-title::before {
  font-family: "icomoon";
  content: "\e915";
  margin-right: 6px;
  font-size: 22px;
  color: #73ba9b;
}
.area-price-page .box-r .box-add p {
  margin-top: 8px;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.7;
}
.area-price-page .box-r .box-add-uppeer {
  margin-bottom: 3rem;
  display: block;
  width: 100%;
  padding: 20px;
  background-color: #fff;
}
.area-price-page .box-r .box-add-uppeer .spn-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 22px;
}
.area-price-page .box-r .box-add-uppeer .spn-title::before {
  margin-right: 6px;
  color: #73ba9b;
  font-family: icomoon;
  font-size: 22px;
  content: "\e915";
}
.area-price-page .box-r .box-add-uppeer p {
  margin-top: 8px;
  font-size: 1rem;
  line-height: 1.5;
}
.area-price-page .box-r .box-add-uppeer .spn-sm {
  display: block;
  margin-top: 8px;
  font-size: 0.8rem;
  line-height: 1.4;
}
.area-price-page .box-r .box-add-uppeer table tr:nth-of-type(2n+1) {
  background: 0 0;
}
.area-price-page .box-r .box-add-uppeer table tr {
  border-bottom: 1px dotted #ff662e;
}
.area-price-page .box-r .box-add-uppeer table td {
  font-size: 1rem;
  line-height: 1.5;
}

.box-page-treatment h2 {
  margin-top: 3rem;
}
@media (max-width: 834px) {
  .box-page-treatment h2 {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .box-page-treatment h2 {
    margin-bottom: 2rem;
  }
}
.box-page-treatment h3 {
  border-bottom: 2px dotted #ff662e;
}
.box-page-treatment h3::before {
  margin-right: 12px;
  color: #ff662e;
  font-family: icomoon;
  font-size: 22px;
  content: "\e907";
}
.box-page-treatment h3:first-of-type {
  margin-top: 3rem;
}
@media (max-width: 480px) {
  .box-page-treatment h3:first-of-type {
    margin-top: 2rem;
  }
}

/* ------------------------------------------------------------------------------------------ 症例（case） */
.sec-case-page .box-sec-intro,
.sec-voice-page .box-sec-intro {
  width: 100%;
}
@media (max-width: 834px) {
  .sec-case-page .box-sec-intro,
  .sec-voice-page .box-sec-intro {
    width: 80%;
    margin-top: 2rem;
  }
}
@media (max-width: 480px) {
  .sec-case-page .box-sec-intro,
  .sec-voice-page .box-sec-intro {
    width: 92%;
  }
}

/* ------------------------------------------------------------ ヒーロー */
.area-hero-customer-voice {
  background-image: url(../img/photo/hero-voice.jpg);
}
@media (max-width: 480px) {
  .area-hero-customer-voice {
    background-image: url(../img/photo/hero-voice_s.jpg);
  }
}

/* ------------------------------------------------------------ タイトルアイコン */
.icon-shoulder-neck,
.icon-waist-back,
.icon-arm,
.icon-leg,
.icon-fatigue,
.icon-pelvis,
.icon-stretch,
.icon-others {
  display: block;
  width: 80px;
  margin: 0 auto 1rem;
}
/* ------------------------------------------------------------ 症例ページ */
.box-case-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5rem;
}
@media (max-width: 834px) {
  .box-case-outer {
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
  }
}
.box-case-outer span {
  display: block;
  font-weight: 500;
  line-height: 1.4;
  color: #521600;
}
.box-case-outer p {
  line-height: 1.6;
}
.box-case-outer .box-case-l {
  width: 52%;
  padding-right: 2rem;
}
@media (max-width: 834px) {
  .box-case-outer .box-case-l {
    margin-bottom: 2rem;
    width: 100%;
    padding-right: 0;
  }
}
.box-case-outer .box-case-l span {
  font-size: 28px;
  margin-bottom: 3rem;
}
.box-case-outer .box-case-area span {
  font-size: 24px;
  margin: 2rem 0;
  padding-top: 2rem;
  border-top: 1px dotted #ff662e;
}
.box-case-outer .box-case-r {
  width: 46%;
  text-align: center;
}
@media (max-width: 834px) {
  .box-case-outer .box-case-r {
    width: 100%;
  }
}
.box-case-outer img {
  border-radius: 10px;
  margin-bottom: 4rem;
}
@media (max-width: 834px) {
  .box-case-outer img {
    width: 80%;
    margin: 0 auto 2rem;
  }
}
@media (max-width: 480px) {
  .box-case-outer img {
    width: 100%;
  }
}

.sec-case-example {
  background-color: #fff0eb;
}
.sec-case-example .box-select-voice {
  width: 300px;
  margin-bottom: 3rem;
}

.h3-example-cases,
.h3-example-symptoms,
.h3-example-treatment {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  position: relative;
}
.h3-example-cases::before, .h3-example-cases::after,
.h3-example-symptoms::before,
.h3-example-symptoms::after,
.h3-example-treatment::before,
.h3-example-treatment::after {
  flex-grow: 1;
  content: "";
  height: 8px;
  background-color: white;
  border-radius: 12px;
}
.h3-example-cases::before,
.h3-example-symptoms::before,
.h3-example-treatment::before {
  margin-right: 1.5rem;
}
.h3-example-cases::after,
.h3-example-symptoms::after,
.h3-example-treatment::after {
  margin-left: 1.5rem;
}

.h3-example-cases::before, .h3-example-cases::after {
  background-color: #73ba9b;
}

.h3-example-symptoms::before, .h3-example-symptoms::after {
  background-color: #ff8e92;
}

.h3-example-treatment::before, .h3-example-treatment::after {
  background-color: #85d3f2;
}

.box-example-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 92%;
  margin-bottom: 1rem;
}
@media (max-width: 480px) {
  .box-example-outer {
    flex-direction: column;
  }
}
.box-example-outer .box-example-cases,
.box-example-outer .box-example-symptoms,
.box-example-outer .box-example-treatment {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 1.5rem;
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4;
  background-color: white;
  border: 2px solid white;
  border-radius: 8px;
  transition: all, 0.2s;
}
@media (max-width: 834px) {
  .box-example-outer .box-example-cases,
  .box-example-outer .box-example-symptoms,
  .box-example-outer .box-example-treatment {
    margin-bottom: 1rem;
  }
}
@media (max-width: 480px) {
  .box-example-outer .box-example-cases,
  .box-example-outer .box-example-symptoms,
  .box-example-outer .box-example-treatment {
    width: 100%;
    padding: 8px;
  }
}
.box-example-outer .box-example-cases:hover,
.box-example-outer .box-example-symptoms:hover,
.box-example-outer .box-example-treatment:hover {
  color: #cc3600;
  background-color: white;
  border: 2px solid #ff662e;
  filter: drop-shadow(6px 6px 6px rgba(10, 10, 10, 0.1));
  transition: all, 0.2s;
}
.box-example-outer .box-example-cases {
  border-color: #73ba9b;
  background-color: #fafffa;
}
.box-example-outer .box-example-symptoms {
  border-color: #ff8e92;
  background-color: #ffebec;
}
.box-example-outer .box-example-treatment {
  border-color: #85d3f2;
  background-color: #f0fafc;
}
.box-example-outer img {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

/* ------------------------------------------------------------ トップページ　ピックアップ症例 */
@media (max-width: 480px) {
  .h2-select-treatment {
    margin-top: 2rem !important;
  }
}

.box-select-treatment {
  width: 92%;
  margin: 2rem auto 1rem;
}
@media (max-width: 1023px) {
  .box-select-treatment {
    width: 100%;
  }
}
.box-select-treatment a {
  background-color: white !important;
  border: none;
}
.box-select-treatment img {
  width: 120px;
  height: 120px;
}
.box-select-treatment span {
  display: block;
  width: 100%;
  padding-bottom: 8px;
  text-align: left !important;
  font-size: 1.2rem;
  border-bottom: 1px dotted #707070;
}
.box-select-treatment p {
  margin-top: 8px;
  margin-bottom: 0;
  text-align: left !important;
  font-size: 1rem;
}

/* ------------------------------------------------------------ お客さまの声ページ */
.spn-voice-name {
  display: block;
  margin-bottom: 1rem;
}

.box-select-voice {
  display: block;
  width: 50%;
  margin: 2rem auto;
}
@media (max-width: 834px) {
  .box-select-voice {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .box-select-voice {
    width: 100%;
  }
}
.box-select-voice a {
  display: block;
  width: auto;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: inherit;
}
@media (max-width: 834px) {
  .box-select-voice a {
    margin-bottom: 1rem;
  }
}
.box-select-voice a:hover {
  color: #c60000;
}
.box-select-voice a::before {
  font-family: "icomoon";
  content: "\e925";
  margin-right: 6px;
  font-size: 16px;
  color: #ff662e;
}

.card-voice-outer {
  display: block;
  width: 92%;
  margin: 0 auto;
  padding: 30px;
  margin-bottom: 2rem;
  border: 1px solid #adadad;
  border-radius: 10px;
}
@media (max-width: 1240px) {
  .card-voice-outer {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .card-voice-outer {
    padding: 20px;
  }
}
.card-voice-outer span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
}
.card-voice-outer span::before {
  font-family: "icomoon";
  content: "\e925";
  margin-right: 6px;
  font-size: 22px;
  color: #ff662e;
}
.card-voice-outer .card-voice-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media (max-width: 480px) {
  .card-voice-outer .card-voice-inner {
    flex-direction: column;
  }
}
.card-voice-outer .box-l {
  width: 33%;
  text-align: center;
}
@media (max-width: 480px) {
  .card-voice-outer .box-l {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.card-voice-outer .box-l .voice-info {
  margin-top: 1rem;
}
.card-voice-outer .box-r {
  width: 63%;
}
@media (max-width: 480px) {
  .card-voice-outer .box-r {
    width: 100%;
  }
}
.card-voice-outer .box-r .spn-mds {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
}
.card-voice-outer .box-r .spn-mds::before {
  content: none;
}
.card-voice-outer .box-r p {
  margin-top: 0.7rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}
.card-voice-outer .box-r .spn-mds-last {
  padding-top: 2rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
  border-top: 1px dotted #ff662e;
}
.card-voice-outer .box-r .spn-mds-last::before {
  content: none;
}

/* ------------------------------------------------------------ ニュースブログ */
/* ------------------------------------------------------------------------------------------ 予約ページ */
.header-container-res {
  border-bottom: 1px solid #707070;
}

.res-header {
  display: block;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 1023px) {
  .res-header {
    padding: 20px 10px;
  }
}
.res-header img {
  height: 60px;
  margin: 0 2rem;
}
@media (max-width: 1023px) {
  .res-header img {
    height: 40px;
    margin: 0 1rem 0;
  }
}
@media (max-width: 480px) {
  .res-header img {
    margin: 0 1rem 1rem;
  }
}

/* ------------------------------------------------------------------------------------------ シングルページ */
/* ------------------------------------------------------------ ブログ一覧（is_home） */
.entry-card-title {
  margin-bottom: 1rem;
}

.a-wrap {
  border-bottom: 1px dotted #ff662e;
}

.a-wrap:hover {
  background-color: #fff0eb;
  transition: all, 0.2s;
}

.fa-folder-open::before {
  color: #cc3600;
}

/* ------------------------------------------------------------ シングルページ */
.content-single-cmz {
  padding-top: 4rem;
  background-color: #fff6eb;
}
@media (max-width: 1240px) {
  .content-single-cmz {
    padding: 2rem 1rem 0;
  }
}
@media (max-width: 480px) {
  .content-single-cmz {
    padding: 1rem 0 2rem;
  }
}
.content-single-cmz .wrap-padding {
  padding: 10px;
}
.content-single-cmz .main {
  padding: 40px 60px;
}
@media (max-width: 1240px) {
  .content-single-cmz .main {
    width: 67%;
    padding: 2rem;
  }
}
@media (max-width: 1023px) {
  .content-single-cmz .main {
    width: 92%;
    margin: 0 auto;
    padding: 1.5rem;
  }
}
@media (max-width: 480px) {
  .content-single-cmz .main {
    width: 100%;
    padding: 1rem;
  }
}
.content-single-cmz .article h1 {
  justify-content: flex-start;
  font-size: 32px;
  font-weight: 500;
}
.content-single-cmz .article h1::before, .content-single-cmz .article h1::after {
  content: none;
}
@media (max-width: 480px) {
  .content-single-cmz .article h1 {
    padding-left: 0;
    padding-right: 0;
  }
}
.content-single-cmz .article h2 {
  margin-top: 3rem;
  border-bottom: 2px dotted #ffa585;
}
.content-single-cmz .article h2::before {
  margin-right: 12px;
  color: #ffa585;
  font-family: icomoon;
  font-size: 16px;
  content: "\e926";
}
@media (max-width: 480px) {
  .content-single-cmz .article h2::before {
    font-size: 10px;
  }
}
.content-single-cmz .entry-content {
  margin-top: 4rem;
}
.content-single-cmz .entry-categories-tags {
  text-align: right;
}
.content-single-cmz .cat-link {
  color: #5c5c5c;
  background: none;
}
.content-single-cmz .pager-post-navi a figure {
  display: none;
}
.content-single-cmz .area-case-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4rem;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.content-single-cmz .area-case-top .box-case {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 22%;
  margin-bottom: 2rem;
  padding-top: 8px;
  background-color: white;
  border: 2px solid white;
  border-radius: 12px;
  text-decoration: none;
  color: #474747;
  cursor: pointer;
  transition: all, 0.2s;
}
@media (max-width: 1240px) {
  .content-single-cmz .area-case-top .box-case {
    width: 32%;
  }
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case {
    width: 48%;
  }
}
.content-single-cmz .area-case-top .box-case:hover {
  border: 2px solid #73ba9b;
  filter: drop-shadow(6px 6px 6px rgba(10, 10, 10, 0.1));
  transition: all, 0.2s;
}
.content-single-cmz .area-case-top .box-case div .spn-fz-lg {
  font-size: 28px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-lg {
    font-size: 28px;
  }
}
.content-single-cmz .area-case-top .box-case div .spn-fz-md {
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-md {
    font-size: 24px;
  }
}
.content-single-cmz .area-case-top .box-case div .spn-fz-mmd {
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-mmd {
    font-size: 26px;
  }
}
.content-single-cmz .area-case-top .box-case div .spn-fz-msm {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-msm {
    font-size: 24px;
  }
}
.content-single-cmz .area-case-top .box-case div .spn-fz-smsm {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-smsm {
    font-size: 24px;
  }
}
.content-single-cmz .area-case-top .box-case div .spn-fz-sm {
  font-size: 18px;
}
@media (max-width: 480px) {
  .content-single-cmz .area-case-top .box-case div .spn-fz-sm {
    font-size: 16px;
  }
}
.content-single-cmz .area-case-top .box-case img {
  width: 144px;
  height: 144px;
  padding-bottom: 1rem;
}
.content-single-cmz .area-case-top .box-case p {
  display: none;
}
.content-single-cmz .sidebar {
  width: 320px;
  border: 1px solid #ffa585;
  padding: 16px;
}
@media (max-width: 1240px) {
  .content-single-cmz .sidebar {
    width: 300px;
  }
}
.content-single-cmz .sidebar h3 {
  margin: 1rem 0 0.5rem 0;
  padding: 4px 8px;
  font-weight: 500;
  background-color: #fff0eb;
}
.content-single-cmz .sidebar h3:first-of-type {
  margin-top: 0;
}
.content-single-cmz .sidebar h3::before {
  font-family: "icomoon";
  content: "\e926";
  margin-right: 6px;
  font-size: 12px;
  color: #ffa585;
}
.content-single-cmz .sidebar ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.content-single-cmz .sidebar a {
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.content-single-cmz .frame-sec-down {
  padding-top: 4rem;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.content-single-cmz .box-author {
  display: block;
  width: 80%;
  margin: 3rem auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 12px;
}
@media (max-width: 480px) {
  .content-single-cmz .box-author {
    width: 100%;
  }
}
.content-single-cmz .box-author span {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
}
.content-single-cmz .box-author .box-outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.content-single-cmz .box-author .box-outer img {
  display: block;
  width: 100px;
  border-radius: 10px;
}
.content-single-cmz .box-author .box-outer .box-r {
  margin-left: 2rem;
}
.content-single-cmz .box-author .box-outer .box-r span {
  display: block;
  margin-bottom: 1rem;
  font-size: 18px;
}
.content-single-cmz .box-author .box-outer .box-r p {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.widget_recent_entries ul li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_pages ul li a,
.widget_meta ul li a,
.widget_rss ul li a,
.widget_nav_menu ul li a {
  transition: all, 0.2s;
}

.widget_recent_entries ul li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_pages ul li a:hover,
.widget_meta ul li a:hover,
.widget_rss ul li a:hover,
.widget_nav_menu ul li a:hover {
  background-color: #fff0eb;
  transition: all, 0.2s;
}

/* 症状別ページ カスタマイズ */
.cmz-page-treatments {
  /* WordPress標準 グループdiv などに付くスタイル インナーブロックの幅を調整している */
}
@media (max-width: 480px) {
  .cmz-page-treatments .content-in {
    width: 92%;
    margin: 2rem auto;
  }
}
.cmz-page-treatments .article {
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .cmz-page-treatments .article {
    width: 100%;
  }
}
.cmz-page-treatments .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: 90%;
}
@media (max-width: 480px) {
  .cmz-page-treatments .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: 100%;
  }
}
.cmz-page-treatments .article h1.entry-title {
  justify-content: flex-start;
  margin-bottom: 5rem;
  font-size: 3rem;
  font-weight: 500;
}
.cmz-page-treatments .article h1.entry-title::before, .cmz-page-treatments .article h1.entry-title::after {
  content: none;
}
@media (max-width: 1023px) {
  .cmz-page-treatments .article h1.entry-title {
    font-size: 2.4rem !important;
    margin-bottom: 3rem !important;
  }
  .cmz-page-treatments .article h1.entry-title::before, .cmz-page-treatments .article h1.entry-title::after {
    font-size: 20px !important;
  }
}
@media (max-width: 480px) {
  .cmz-page-treatments .article h1.entry-title {
    padding-left: 8px;
    padding-right: 0;
    font-size: 2rem !important;
    color: white;
    background-color: #ff662e;
  }
  .cmz-page-treatments .article h1.entry-title::before, .cmz-page-treatments .article h1.entry-title::after {
    display: none;
  }
}
.cmz-page-treatments .article h2 {
  margin-top: 4rem;
  margin-bottom: 2.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 2.2rem;
  border-top: 6px solid #ffa585;
  border-bottom: 6px solid #ffa585;
}
@media (max-width: 1024px) {
  .cmz-page-treatments .article h2 {
    font-size: 2rem;
  }
}
@media (max-width: 480px) {
  .cmz-page-treatments .article h2 {
    padding-bottom: 4px;
    font-size: 1.8rem;
  }
}
.cmz-page-treatments .article h2::before {
  margin-right: 12px;
  color: #ffa585;
  font-family: icomoon;
  font-size: 16px;
  content: "\e926";
}
@media (max-width: 480px) {
  .cmz-page-treatments .article h2::before {
    display: none;
  }
}
.cmz-page-treatments .sec-offer h2 {
  margin-top: 0;
  border-top: none;
  border-bottom: none;
}
@media (max-width: 480px) {
  .cmz-page-treatments .sec-offer h2 {
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
}
.cmz-page-treatments .article h3 {
  margin-top: 4rem;
  margin-bottom: 2.2rem;
  padding: 4px 2px 4px 12px;
  font-size: 1.8rem;
  font-weight: normal;
  border-left: 12px solid #ff662e;
}
@media (max-width: 480px) {
  .cmz-page-treatments .article h3 {
    font-size: 1.5rem;
  }
}
.cmz-page-treatments p {
  line-height: 2;
}
.cmz-page-treatments .blogcard-wrap {
  width: 64%;
}
@media (max-width: 480px) {
  .cmz-page-treatments .blogcard-wrap {
    width: 90%;
  }
}
.cmz-page-treatments .blogcard-wrap .blogcard-title {
  font-size: 1.2rem;
  font-weight: normal;
}
.cmz-page-treatments .box-line-tomo {
  width: 80%;
  margin: 2rem auto 3rem;
  padding: 24px 36px;
  text-align: center;
}
@media (max-width: 1024px) {
  .cmz-page-treatments .box-line-tomo {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .cmz-page-treatments .box-line-tomo {
    width: 100%;
    padding: 12px 18px;
  }
}
.cmz-page-treatments .box-line-tomo p {
  margin-top: 0;
  padding: 4px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
@media (max-width: 480px) {
  .cmz-page-treatments .box-line-tomo p {
    font-size: 1.2rem;
    text-align: center;
  }
}
.cmz-page-treatments .box-line-tomo .box-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 480px) {
  .cmz-page-treatments .box-line-tomo .box-btns {
    flex-direction: column;
  }
}
.cmz-page-treatments .box-line-tomo a {
  text-decoration: none;
}
@media (max-width: 1024px) {
  .cmz-page-treatments .box-line-tomo a {
    transform: scale(0.9);
  }
}
@media (max-width: 480px) {
  .cmz-page-treatments .box-line-tomo a {
    transform: scale(0.8);
  }
}
.cmz-page-treatments .area-voice,
.cmz-page-treatments .area-salespoint {
  margin-top: 4rem;
  padding: 1px 0 4rem;
}
.cmz-page-treatments .area-voice .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)),
.cmz-page-treatments .area-salespoint .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: auto;
}
@media (max-width: 480px) {
  .cmz-page-treatments .area-voice .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)),
  .cmz-page-treatments .area-salespoint .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: 94%;
  }
}
.cmz-page-treatments .area-voice h2,
.cmz-page-treatments .area-voice h3,
.cmz-page-treatments .area-salespoint h2,
.cmz-page-treatments .area-salespoint h3 {
  background-color: white;
}
@media (max-width: 1023px) {
  .cmz-page-treatments .area-voice h2,
  .cmz-page-treatments .area-voice h3,
  .cmz-page-treatments .area-salespoint h2,
  .cmz-page-treatments .area-salespoint h3 {
    margin-top: 2rem;
  }
}
.cmz-page-treatments .area-voice .wrap-padding,
.cmz-page-treatments .area-salespoint .wrap-padding {
  margin-top: 24px;
  padding-top: 1px;
  padding-bottom: 24px;
}
.cmz-page-treatments .area-counseling {
  margin-top: 3rem;
  padding: 2rem;
}
@media (max-width: 480px) {
  .cmz-page-treatments .area-counseling {
    padding: 1rem;
  }
}
.cmz-page-treatments .area-counseling h2 {
  margin-top: 0;
  text-align: center;
  border-width: 3px;
  border-color: #73ba9b;
  background-color: white;
}
@media (max-width: 1023px) {
  .cmz-page-treatments .area-counseling h2 {
    margin-top: 2rem;
  }
}
.cmz-page-treatments .area-counseling h2::before {
  display: none;
}
.cmz-page-treatments .area-counseling .box-counseling-outer {
  padding-bottom: 3rem;
  border: 4px solid white;
  background-color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 480px) {
  .cmz-page-treatments .area-counseling .box-counseling-outer {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.cmz-page-treatments .area-counseling .box-line-tomo p {
  font-size: 1.2rem;
}
.cmz-page-treatments .box-related-treatment-outer {
  margin-top: 4rem;
}
.cmz-page-treatments .box-related-treatment-outer .blogcard-wrap {
  width: 100%;
}
.cmz-page-treatments .box-related-treatment-outer .blogcard-thumbnail {
  float: none;
  width: 100%;
}
.cmz-page-treatments .box-related-treatment-outer figure {
  margin: 0 0 12px 0;
}
.cmz-page-treatments .box-related-treatment-outer .blogcard-content {
  margin-left: auto;
}
.cmz-page-treatments .box-related-treatment-outer .blogcard-title {
  margin-bottom: 1rem;
  line-height: 1.3;
}

/* ------------------------------ 貼り付けショップ情報 */
.shopinfo-post {
  display: block;
  position: relative;
  margin: 6rem auto 2rem;
}
.shopinfo-post::before {
  position: absolute;
  top: -86px;
  left: 50%;
  transform: translateX(-50%);
  color: #ff662e;
  font-family: icomoon;
  font-size: 60px;
  content: "\e924";
}
.shopinfo-post .box-outer {
  display: block;
  width: 90%;
  margin: 2rem auto;
  padding: 40px;
  border: 1px solid #ff662e;
  border-radius: 10px;
}
@media (max-width: 834px) {
  .shopinfo-post .box-outer {
    width: 100%;
    padding: 20px;
  }
}
@media (max-width: 480px) {
  .shopinfo-post .box-outer {
    padding: 20px 8px;
  }
}
.shopinfo-post .box-upper {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: right;
}
.shopinfo-post .box-upper span {
  display: block;
  margin-bottom: 0.7rem;
  border-bottom: 2px dotted #ff662e;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
}
.shopinfo-post table {
  width: 90%;
  margin: 0 auto 1rem;
}
@media (max-width: 480px) {
  .shopinfo-post table {
    width: 100%;
  }
}
.shopinfo-post table tr:nth-of-type(2n + 1) {
  background: none;
}
.shopinfo-post table tr {
  border-bottom: 1px dotted #ff662e;
}
.shopinfo-post table td {
  font-size: 0.9rem;
}
@media (max-width: 375px) {
  .shopinfo-post table td {
    display: block;
  }
}
@media (max-width: 480px) {
  .shopinfo-post table td a {
    font-size: 0.8rem;
  }
}
.shopinfo-post .box-lower {
  font-size: 0.9rem;
}
.shopinfo-post .box-lower strong {
  display: block;
  margin-bottom: 0.5rem;
  color: #ff662e;
  font-size: 1.1rem;
}
.shopinfo-post .box-dm-up {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto 0;
}
@media (max-width: 480px) {
  .shopinfo-post .box-dm-up {
    flex-direction: column;
  }
}
.shopinfo-post .box-dm-up a {
  text-decoration: none;
}
.shopinfo-post .box-dm-tel,
.shopinfo-post .box-dm-mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 64px;
  padding: 6px 30px;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
}
@media (max-width: 480px) {
  .shopinfo-post .box-dm-tel,
  .shopinfo-post .box-dm-mail {
    justify-content: center;
    width: 80%;
    margin-bottom: 1rem;
  }
}
.shopinfo-post .box-dm-tel::before,
.shopinfo-post .box-dm-mail::before {
  margin-right: 6px;
  font-family: icomoon;
  font-size: 18px;
}
.shopinfo-post .box-dm-tel {
  background-color: #fff0eb;
  color: #cc3600;
}
.shopinfo-post .box-dm-tel:hover {
  background-color: #ffa585;
}
.shopinfo-post .box-dm-tel::before {
  content: "\e918";
}
.shopinfo-post .box-dm-mail {
  background-color: #f1f8f5;
  color: #43896b;
}
.shopinfo-post .box-dm-mail:hover {
  background-color: #bbddce;
}
.shopinfo-post .box-dm-mail::before {
  content: "\e916";
}

/* ------------------------------ ページネーション */
.pagination-next-link {
  border-color: #fff0eb;
  transition: all, 0.2s;
}
.pagination-next-link:hover {
  background-color: #fff0eb;
  transition: all, 0.2s;
}

.pagination a:hover {
  background-color: #fff0eb;
  transition: all, 0.2s;
}

.pagination .current {
  background-color: #ffa585;
}

.page-numbers {
  border-color: #fff0eb;
  transition: all, 0.2s;
}

/* ------------------------------------------------------------ シェアボタン　アイコンフォント変更 */
.share-button.twitter-button .icon-twitter::before {
  content: "\e922";
}

.share-button.facebook-button .icon-facebook::before {
  content: "\e90b";
}

.share-button.line-button .icon-line::before {
  content: "\e929";
}

.share-button.copy-button .icon-copy::before {
  content: "\e92d";
}

/* ------------------------------------------------------------------------------------------ 予約ページ */
@media (max-width: 480px) {
  .box-res-header img {
    max-width: 90%;
  }
}

.page-id-10 .breadcrumb {
  display: none;
}

.p-res {
  display: block;
  width: 100%;
  margin: 1.2rem auto !important;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}
@media (max-width: 834px) {
  .p-res {
    width: 92%;
  }
}

.box-res-time {
  width: 40%;
  margin: 0 auto 1rem;
  text-align: center;
}
@media (max-width: 1023px) {
  .box-res-time {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .box-res-time {
    width: 90%;
  }
}
.box-res-time .spn-icon-clock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 auto 12px;
  font-size: 16px;
}
.box-res-time .spn-icon-clock::before {
  margin-right: 6px;
  font-family: icomoon;
  font-size: 16px;
  content: "\e909";
}
.box-res-time table tr:nth-of-type(2n + 1) {
  background: none;
}
.box-res-time table tr:first-of-type {
  background-color: #ffa585;
}
.box-res-time table td {
  font-size: 14px;
}
.box-res-time table.box-schedule-s .spn-color {
  color: #ff662e;
}
.box-res-time table.box-schedule-p tr:first-of-type {
  background-color: #bbddce;
}
.box-res-time table.box-schedule-p .spn-color {
  color: #73ba9b;
}
.box-res-time .box-hpwari {
  padding: 12px 1rem;
  font-size: 1.2rem;
  border: 1px solid #ff8e92;
  border-radius: 6px;
}
.box-res-time .box-hpwari .spn-att {
  color: #c60000;
  font-size: 1.5rem;
}
.box-res-time .box-hpwari .spn-sm {
  font-size: 1rem;
}
.box-res-time .box-hpwari .spn-sm::before {
  margin-right: 6px;
  font-family: icomoon;
  font-size: 24px;
  content: "\e91f";
  color: #ff8e92;
}
.box-res-time .box-hpwari .spn-sm::after {
  margin-left: 6px;
  font-family: icomoon;
  font-size: 24px;
  content: "\e91f";
  color: #ff8e92;
}

.box-res-time-brd {
  padding-top: 1.5rem;
  border-top: 1px solid #bbddce;
  border-bottom: 1px solid #bbddce;
}

.box-res-time-reserve table tr:first-of-type {
  background-color: #fdd7c9;
}
.box-res-time-reserve table tr:first-of-type td {
  width: 33.333%;
  font-size: 20px;
}
.box-res-time-reserve table tr:first-of-type td:nth-child(2) {
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.box-res-time-reserve table td {
  vertical-align: top;
}
.box-res-time-reserve table .td-hour {
  padding: 0 30px;
}
@media (max-width: 480px) {
  .box-res-time-reserve table .td-hour {
    padding: 0 10px;
  }
}
.box-res-time-reserve table.box-schedule-s .spn-color {
  padding: 1rem 0;
  font-size: 20px;
  color: #ec4b10;
}
.box-res-time-reserve p {
  font-size: 1rem;
  text-align: left;
}
.box-res-time-reserve span {
  font-size: 1.2rem;
  color: #c60000;
}

.box-res-notice {
  display: block;
  width: 80%;
  margin: 4rem auto 2rem;
  padding: 20px;
  border: 1px solid #5f891a;
}
@media (max-width: 834px) {
  .box-res-notice {
    width: 92%;
  }
}
.box-res-notice p {
  margin: 0;
  font-size: 0.9rem;
}

.box-sitelogo-res {
  display: block;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.box-sitelogo-res div {
  display: inline-block;
  width: 30%;
  margin: 0 2rem;
}
@media (max-width: 1023px) {
  .box-sitelogo-res div {
    width: 40%;
  }
}
@media (max-width: 834px) {
  .box-sitelogo-res div {
    display: block;
    width: 80%;
    margin-bottom: 1rem;
  }
}
.box-sitelogo-res .btn-fjs,
.box-sitelogo-res .btn-fjp {
  display: block;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all, 0.2s;
}
.box-sitelogo-res .btn-fjs:hover,
.box-sitelogo-res .btn-fjp:hover {
  transition: all, 0.2s;
}
.box-sitelogo-res .btn-fjs {
  border: 1px solid #ff662e;
}
.box-sitelogo-res .btn-fjs:hover {
  background-color: #fff0eb;
}
.box-sitelogo-res .btn-fjp {
  border: 1px solid #73ba9b;
}
.box-sitelogo-res .btn-fjp:hover {
  background-color: #f1f8f5;
}
.box-sitelogo-res img {
  height: 40px;
}
.box-sitelogo-res span {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.footer-res {
  padding: 40px 0;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  background-color: #5f891a;
}

.ea-bootstrap {
  width: 92%;
  margin: 2rem auto !important;
}

/* ------------------------------------------------------------------------------------------ プライバシーポリシー */
.privacy-box,
.kiyaku-box {
  font-size: 16px;
}
@media (max-width: 1240px) {
  .privacy-box,
  .kiyaku-box {
    width: 90%;
    padding: 0 1rem;
    margin: 2rem 0;
  }
}
@media (max-width: 1023px) {
  .privacy-box,
  .kiyaku-box {
    width: 100%;
    padding: 0 1rem;
    margin: 2rem 0;
  }
}
@media (max-width: 480px) {
  .privacy-box,
  .kiyaku-box {
    padding: 0 8px;
  }
}
.privacy-box p,
.kiyaku-box p {
  padding-left: 40px;
  margin-bottom: 2rem;
  line-height: inherit;
}

/* ------------------------------------------------------------------------------------------ コンタクトフォーム７　ContactForm7 */
.inquiry-sec {
  margin: 0 auto;
}
@media (max-width: 834px) {
  .inquiry-sec {
    padding: 30px;
  }
}
@media (max-width: 480px) {
  .inquiry-sec {
    padding: 30px 8px;
  }
}
.inquiry-sec .haveto {
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #ff662e;
  color: #fff;
  font-size: 10px;
  line-height: 1;
}
.inquiry-sec table tr:nth-of-type(2n + 1) {
  background: none;
}
.inquiry-sec table tr {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}
@media (max-width: 480px) {
  .inquiry-sec table tr {
    flex-direction: column;
  }
}
.inquiry-sec table th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-right: 1rem;
  color: #474747;
  font-weight: normal;
  text-align: left;
  border: none;
  background: white;
}
@media (max-width: 480px) {
  .inquiry-sec table th {
    width: 140px;
  }
}
.inquiry-sec table td {
  width: 80%;
  padding: 0;
  border: none;
}
@media (max-width: 480px) {
  .inquiry-sec table td {
    width: 100%;
  }
}
.inquiry-sec table span.wpcf7-list-item {
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
@media (max-width: 480px) {
  .inquiry-sec table span.wpcf7-list-item {
    display: block;
  }
}
.inquiry-sec table span.wpcf7-list-item.first {
  margin-top: 1rem;
}
.inquiry-sec table span.wpcf7-list-item.last {
  margin-bottom: 0;
}
.inquiry-sec input::placeholder {
  color: #8f8f8f;
}
.inquiry-sec input[type=text],
.inquiry-sec input[type=tel],
.inquiry-sec input[type=email],
.inquiry-sec textarea {
  border: 2px solid #cccccc;
  font-family: "yu gothic medium", "游ゴシック medium", yugothic, "游ゴシック体", "ヒラギノ角ゴ pro w3", "メイリオ", sans-serif;
}
.inquiry-sec input[type=text]:focus,
.inquiry-sec input[type=tel]:focus,
.inquiry-sec input[type=email]:focus,
.inquiry-sec textarea:focus {
  border: 2px solid #ff662e;
}
.inquiry-sec textarea {
  margin-top: 0;
}
.inquiry-sec .wpcf7-form-control-wrap {
  margin-right: 1rem;
}
.inquiry-sec input[type=radio],
.inquiry-sec input[type=checkbox] {
  margin-right: 5px;
  opacity: 1;
  width: 26px;
  height: 26px;
  border: 1px solid #ff662e;
  border-radius: 3px;
  appearance: none;
  position: absolute;
}
.inquiry-sec input[type=radio] + span,
.inquiry-sec input[type=checkbox] + span {
  margin-left: 34px;
}
.inquiry-sec input[type=radio]:checked,
.inquiry-sec input[type=checkbox]:checked {
  background: #ff662e;
}
.inquiry-sec .wpcf7-form-control-wrap {
  margin-right: 1.5rem;
}
.inquiry-sec .wpcf7-acceptance .wpcf7-list-item {
  display: inherit;
  margin: 0 20px 0 0;
}
.inquiry-sec .wpcf7-acceptance input[type=radio],
.inquiry-sec .wpcf7-acceptance input[type=checkbox] {
  margin-right: 5px;
  opacity: 1;
  width: 26px;
  height: 26px;
  border: 1px solid #ff662e;
  border-radius: 3px;
  appearance: none;
  position: absolute;
}
.inquiry-sec #formbtn {
  display: block;
  padding: 15px;
  margin: 25px 0;
  width: 350px;
  color: white;
  font-size: 2rem;
  background: #73ba9b;
  border: 2px solid #73ba9b;
  border-radius: 2px;
  transition: 0.3s;
}
@media (max-width: 834px) {
  .inquiry-sec #formbtn {
    margin: 25px auto;
  }
}
@media (max-width: 480px) {
  .inquiry-sec #formbtn {
    width: 100%;
  }
}
.inquiry-sec #formbtn:hover {
  background: #bbddce;
  color: #43896b;
  border: 2px solid #73ba9b;
}
.inquiry-sec .submit-check-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}
@media (max-width: 480px) {
  .inquiry-sec .submit-check-box {
    font-size: 15px;
  }
}
.inquiry-sec .submit-send-box {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 834px) {
  .inquiry-sec .submit-send-box {
    justify-content: center;
  }
}
.inquiry-sec .submit {
  position: relative;
}
.inquiry-sec .submit .ajax-loader {
  position: absolute;
  bottom: -1rem;
}
.inquiry-sec .contact-notice-footer {
  margin-top: 20px;
}
.inquiry-sec .box-acceptance {
  display: flex;
  flex-direction: row;
}

.thanks {
  margin-top: 0;
  margin-bottom: 0;
}

/* ------------------------------------------------------------ reCAPTCHA */
.box-recaptcha-outer {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px dotted #ff662e;
}

.box-recaptcha {
  display: block;
  margin-top: 2rem;
  font-size: 0.7rem;
}
@media (max-width: 480px) {
  .box-recaptcha {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.box-recaptcha a {
  color: inherit;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* ------------------------------------------------------------------------------------------ サイドバー */
/* ------------------------------------------------------------------------------------------ フッター */
.footer {
  display: block;
  background-image: url(../img/parts/frame-bottom.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
@media (max-width: 1023px) {
  .footer {
    margin-bottom: 46px;
    background-image: url(../img/parts/frame-bottom_t.svg);
  }
}
@media (max-width: 480px) {
  .footer {
    background-image: url(../img/parts/frame-bottom_s.svg);
  }
}

.footer-bottom {
  font-size: 12px;
}

.footer-widgets {
  padding-bottom: 40px;
}
@media (max-width: 1240px) {
  .footer-widgets {
    padding: 20px;
  }
}
@media (max-width: 1023px) {
  .footer-widgets {
    display: inherit;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .footer-widgets > div {
    width: 85%;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .footer-widgets > div {
    width: 100%;
  }
}

.footer-bottom.fdt-up-and-down .footer-bottom-content {
  padding-right: 1rem;
  color: white;
  text-align: center;
}
@media (max-width: 1023px) {
  .footer-bottom.fdt-up-and-down .footer-bottom-content {
    color: inherit;
  }
}

.box-footer-logo {
  width: 100%;
  text-align: center;
}
@media (max-width: 480px) {
  .box-footer-logo {
    width: 90%;
    margin: 0 auto;
  }
}
.box-footer-logo img {
  margin: 1rem auto 3rem;
  width: 380px;
}
@media (max-width: 480px) {
  .box-footer-logo img {
    margin-bottom: 1rem;
  }
}

.footer-left,
.footer-right {
  width: 49%;
}

.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border: 1px solid #ff662e;
  border-radius: 12px;
}

.dmfooter {
  position: inherit;
  justify-content: center;
  width: auto;
  border-radius: 0;
  background: none;
}
@media (max-width: 1023px) {
  .dmfooter {
    display: inherit;
  }
}
.dmfooter .box-drawermenu {
  flex-direction: column-reverse;
  width: auto;
  padding: 12px 0;
  border-radius: 0;
  background: none;
}
.dmfooter .box-drawermenu .box-dm-up {
  flex-direction: column;
}
.dmfooter .box-drawermenu .box-dm-up .box-dm-tel {
  width: 90%;
  justify-content: center;
  margin-bottom: 2rem;
}
.dmfooter .box-drawermenu .box-dm-up .box-dm-mail {
  width: 90%;
  justify-content: center;
}
.dmfooter .box-drawermenu .spn-sm {
  font-size: 12px;
}
.dmfooter .box-drawermenu .box-btn-extres {
  display: block;
  width: auto;
  margin: 1rem auto;
  text-align: center;
}
.dmfooter .box-drawermenu .box-btn-extres a {
  width: 100%;
  margin: 0 auto 1rem;
}
.dmfooter .box-drawermenu .box-btn-extres img {
  width: 360px;
}
@media (max-width: 480px) {
  .dmfooter .box-drawermenu .box-btn-extres img {
    width: 280px;
  }
}
.dmfooter .spn-notice {
  display: none;
}
.dmfooter .spn-notice_footer {
  display: block;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 8px;
  text-align: center;
  border-bottom: 1px dotted #ff662e;
}
.dmfooter .spn-notice_footer::after {
  display: inline-block;
}
.dmfooter .spn-notice2_footer {
  display: block;
  margin-top: 8px;
  font-size: 0.9rem;
}

.box-footer-info {
  width: 100%;
  margin: 0 auto 2rem;
}
.box-footer-info ul {
  padding: 0;
}
@media (max-width: 480px) {
  .box-footer-info ul {
    width: 92%;
    margin: 0 auto;
  }
}
.box-footer-info li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
  list-style: none;
  line-height: 1.4;
}
.box-footer-info li::before {
  font-family: "icomoon";
  content: "\e909";
  margin-right: 18px;
  font-size: 22px;
  color: #ff662e;
}
.box-footer-info li:first-of-type {
  border-bottom: 1px dotted #ff662e;
}
.box-footer-info li:last-of-type {
  border-top: 1px dotted #ff662e;
}
.box-footer-info .li-footer-map::before {
  content: "\e913";
}
.box-footer-info .li-footer-walk::before {
  content: "\e923";
}
.box-footer-info .li-footer-walk a {
  font-size: 0.9rem;
}
.box-footer-info .li-footer-car span {
  display: block;
  width: 100%;
}
.box-footer-info .li-footer-car .spn-sm {
  margin-top: 6px;
  font-size: 12px;
}
.box-footer-info .li-footer-car::before {
  content: "\e906";
}
.box-footer-info .box-img-map {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 75%;
  /* calc(画像高さ ÷ 画像横幅 × 100%) */
  background-image: url(../img/image/map.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 375px) {
  .box-footer-info .box-img-map {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-right {
  padding-left: 80px;
}
@media (max-width: 834px) {
  .footer-right {
    padding: 30px;
  }
}
@media (max-width: 834px) {
  .footer-right {
    padding: 10px;
  }
}
.footer-right .area-footer-sns {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px dotted #ff662e;
}
@media (max-width: 1023px) {
  .footer-right .area-footer-sns {
    margin-top: 2rem;
  }
}
.footer-right .area-footer-sns a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  border-radius: 10px;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #474747;
  background-color: white;
  transition: all, 0.2s;
}
@media (max-width: 1240px) {
  .footer-right .area-footer-sns a {
    width: 80%;
  }
}
@media (max-width: 1023px) {
  .footer-right .area-footer-sns a {
    width: 50%;
    margin: 0 auto 1rem;
  }
}
@media (max-width: 480px) {
  .footer-right .area-footer-sns a {
    width: 80%;
  }
}
.footer-right .area-footer-sns a:hover {
  filter: drop-shadow(4px 4px 4px rgba(10, 10, 10, 0.2));
  transition: all, 0.2s;
}
.footer-right .area-footer-sns a::before {
  font-family: "icomoon";
  margin-right: 12px;
  font-size: 22px;
}
.footer-right .area-footer-sns .btn-twt {
  padding: 2px 14px;
  border: 1px solid #1da1f3;
}
.footer-right .area-footer-sns .btn-twt::before {
  content: "\e922";
  color: #1da1f3;
}
.footer-right .area-footer-sns .btn-line {
  padding: 2px 14px;
  border: 1px solid #00c300;
}
.footer-right .area-footer-sns .btn-line::before {
  content: "\e929";
  color: #00c300;
}
.footer-right .area-footer-sns .btn-fcb {
  padding: 4px 16px;
  border: 1px solid #3b5999;
}
.footer-right .area-footer-sns .btn-fcb::before {
  content: "\e90b";
  color: #3b5999;
}
.footer-right .area-footer-sns .btn-ytb {
  padding: 4px 16px;
  border: 1px solid #c60000;
}
.footer-right .area-footer-sns .btn-ytb::before {
  content: "\e917";
  color: #c60000;
}
.footer-right .area-footer-sns .btn-isg {
  padding: 4px 16px;
  border: 1px solid #CF2E92;
}
.footer-right .area-footer-sns .btn-isg::before {
  content: "\ea92";
  color: #CF2E92;
}
.footer-right .widget_nav_menu {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px dotted #ff662e;
}
.footer-right .widget_nav_menu ul li {
  width: 100%;
}
.footer-right .widget_nav_menu ul li a {
  width: 100%;
  padding: 4px;
  transition: all, 0.2s;
}
.footer-right .widget_nav_menu ul li a:hover {
  background-color: #fff0eb;
  transition: all, 0.2s;
}
.footer-right .widget_nav_menu ul li a::before {
  font-family: "icomoon";
  content: "\e915";
  margin-right: 6px;
  font-size: 16px;
  color: #ff662e;
}
.footer-right .box-footer-right2 .area-othershop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 480px) {
  .footer-right .box-footer-right2 .area-othershop {
    justify-content: center;
    align-items: center;
  }
}
.footer-right .box-footer-right2 .area-othershop .box-othershop-here {
  display: block;
  width: 180px;
  padding: 8px;
  margin-bottom: 1rem;
  color: white;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  background-color: #01a4aa;
  position: relative;
}
.footer-right .box-footer-right2 .area-othershop .box-othershop-here img {
  width: 40px;
  position: absolute;
  bottom: -28px;
  left: 16px;
}
.footer-right .box-footer-right2 .area-othershop .box-othershop-here .box-image-here {
  width: 40px;
  position: absolute;
  bottom: -28px;
  left: 16px;
  height: 30px;
  background-image: url(../img/parts/here-g.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.footer-right .box-footer-right2 .area-othershop .btn-othershop {
  display: block;
  width: 300px;
  padding: 8px;
  border: 1px solid #01a4aa;
  border-radius: 10px;
}
.footer-right .box-footer-right2 .area-othershop img {
  width: 300px;
}
.footer-right .box-footer-right2 .area-othershop .box-image-logo {
  width: 100%;
  height: 0;
  padding-top: 13.7931034483%;
  /* calc(画像高さ ÷ 画像横幅 × 100%) */
  background-image: url(../img/parts/fs-p_logo_full_gr.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* ------------------------------------------------------------ モバイル用フッターメニュー */
.mobile-footer-menu-buttons.mobile-menu-buttons {
  z-index: 1200 !important;
}

.mobile-footer-menu-buttons .menu-button:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ff662e;
  min-height: 50px;
}
.mobile-footer-menu-buttons .menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #73ba9b;
  min-height: 50px;
}
.mobile-footer-menu-buttons .menu-button:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ffbf70;
  min-height: 50px;
}
.mobile-footer-menu-buttons .fa {
  font-size: 24px;
}
.mobile-footer-menu-buttons .menu-close-button {
  font-size: 1.2rem;
  color: white;
}
.mobile-footer-menu-buttons .menu-content {
  background-color: rgba(82, 22, 0, 0.9);
}
.mobile-footer-menu-buttons .menu-content .site-logo-image {
  margin-top: 2rem;
}
.mobile-footer-menu-buttons .menu-drawer a {
  margin-bottom: 12px;
  color: white;
  font-size: 1.2rem;
}

.menu-button-in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.mobile-menu-buttons .menu-button > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.mobile-menu-buttons .menu-icon {
  width: auto;
}

.mobile-menu-buttons .menu-caption {
  width: auto;
  margin-left: 4px;
  font-size: 15px;
  font-weight: 700;
  opacity: 1;
}

@media (max-width: 834px) {
  #menu-cmz-footer-bobile-menu {
    z-index: 300 !important;
  }
}

/* ------------------------------------------------------------ モバイル用ドロワーメニュー */
.mobile-footer-menu-buttons .menu-content .site-name-text {
  display: block;
  width: 240px;
  margin: 0 auto 1rem;
}
.mobile-footer-menu-buttons .menu-content .spn-icon-clock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 0 auto;
  font-size: 16px;
}
.mobile-footer-menu-buttons .menu-content .spn-icon-clock::before {
  font-family: "icomoon";
  content: "\e909";
  margin-right: 6px;
  font-size: 16px;
}
.mobile-footer-menu-buttons .menu-content .box-schedule {
  width: 90%;
  margin: 0 auto 2rem;
}
.mobile-footer-menu-buttons .menu-content table tr:nth-of-type(2n + 1) {
  background: none;
}
.mobile-footer-menu-buttons .menu-content table td {
  padding: 6px;
  text-align: center;
  border: 1px solid #adadad;
}
.mobile-footer-menu-buttons .menu-content .navi-menu-close-button {
  margin-bottom: 2rem;
}

.mobile-footer-menu-buttons .menu-drawer a {
  font-size: 1rem;
  margin-bottom: 4px;
}
.mobile-footer-menu-buttons .menu-drawer a::before {
  font-family: "icomoon";
  content: "\e926";
  margin-right: 6px;
  font-size: 8px;
  color: white;
  opacity: 0.8;
}

/* ------------------------------------------------------------------------------------------ 本文その他 */
/* ------------------------------------------------------------ 店内ストリートビュー */
.ifrm-gglsv {
  display: block;
  width: 80%;
  margin: 3rem auto 2rem;
}
@media (max-width: 1023px) {
  .ifrm-gglsv {
    width: 96%;
    margin-top: 2rem;
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .ifrm-gglsv {
    width: 98%;
  }
}
.ifrm-gglsv figcaption {
  text-align: center;
}
.ifrm-gglsv iframe {
  width: 100%;
  height: 600px;
}
@media (max-width: 1023px) {
  .ifrm-gglsv iframe {
    height: 450px;
  }
}
@media (max-width: 480px) {
  .ifrm-gglsv iframe {
    height: 300px;
  }
}

/* ------------------------------ シェアボタン */
@media (max-width: 480px) {
  .ss-top .sns-share-buttons a .button-caption,
  .ss-bottom .sns-share-buttons a .button-caption {
    display: none;
  }
}

/* ------------------------------------------------------------ トップへ戻る */
.go-to-top-button {
  width: 60px;
  height: 60px;
  background-color: #cc3600;
  border: 2px solid white;
  border-radius: 50%;
  transition: all, 0.2s;
}
.go-to-top-button:hover {
  color: white;
  background-color: #ffa585;
  transition: all, 0.2s;
}

/* ------------------------------------------------------------ SNSボタン */
/* ------------------------------------------------------------------------------------------ 問い合わせボタン */
/* ------------------------------------------------------------------------------------------ その他 */
/* ------------------------------------------------------------------------------------------ 印刷用 */
.unghorst-print {
  display: none;
}

@media print {
  body {
    background-color: white;
  }
  .sec-myfile .unghorst-print {
    display: block;
  }
  .sec-myfile .unghorst-print th {
    width: 300px;
  }
  .sec-myfile .unghorst-print td {
    width: 80px;
    text-align: center;
  }
  .sec-myfile .ghorst-print {
    display: none;
  }
  .sec-myfile table {
    font-size: 8px;
  }
  .sec-myfile tr:nth-of-type(2n + 1) {
    background-color: white;
  }
  .sec-myfile th,
  .sec-myfile td {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sec-myfile .fs-s {
    font-size: 7px;
  }
}
/*! purgecss end ignore */