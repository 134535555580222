@charset "UTF-8";

/*!
Theme Name: Cocoon Child
Template:   cocoon-master
Version:    1.1.0
*/

/************************************
** 子テーマ用のスタイルを書く　サイト名
************************************/

@use "index" as i;

/*! purgecss start ignore */
.body {
    @include i.wpc {
        background: inherit;
    }

    @include i.pc {
        background: inherit;
    }

    @include i.padpc {
        background: inherit;
    }

    @include i.pad {
        background: inherit;
    }

    @include i.tab {
        background: inherit;
    }

    @include i.sp {
        background: inherit;
    }

    @include i.ssp {
        background: inherit;
    }

    font-family: i.$font-family-base;
}

.main,
.sidebar {
    padding: 0;
}

.main {

    //width: 800px;
    p {
        margin: 1.2em 0;
    }

    @media (min-width: 1023px) and (max-width: 1024px) {
        //width: 760px;
    }
}

main.main {
    @include i.pad {
        padding: 0;
    }

    @include i.tab {
        padding: 0;
    }
}

.sidebar {

    //width: 200px;
    @media (min-width: 1023px) and (max-width: 1024px) {}
}

.wrap {
    //width: 1240px;

    @include i.pad {}
}

.full {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    width: auto;
}

img {
    vertical-align: middle;
}

.ghorst-lg {
    display: none;

    @include i.tab {
        display: block;
    }
}

.ghorst-tab {
    display: none;

    @include i.sp {
        display: block;
    }
}

.ghorst-pd {
    display: block;

    @include i.pad {
        display: none;
    }
}

.ghorst-sm {
    display: block;

    @include i.sp {
        display: none;
    }
}

.go-to-top-button {
    color: white;
}

.text-idt1 {
    display: inline-block;
    text-indent: -1em;
    padding-left: 1em;
}

.text-idt2 {
    display: inline-block;
    text-indent: -2em;
    padding-left: 2em;
}

.text-idt3 {
    display: inline-block;
    text-indent: -3em;
    padding-left: 3em;
}

/* ------------------------------ パンくず */
.breadcrumbs {
    font-size: 14px;
    color: i.$gray-700;

    a {
        text-decoration: none;
    }
}

.breadcrumb-page.wrap.wrap-padding {
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;

    @include i.sp {
        width: 92%;
    }
}


/* ------------------------------ キャプション */

.wp-block-image .aligncenter>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image .alignright>figcaption,
.wp-block-image.is-resized>figcaption,
.wp-block-image figcaption {
    text-align: center;
}

/* ------------------------------------------------------------------------------------------ 見出し */
/* ------------------------------------------------------------ 見出しリセット */

.article h2 {
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 0;
}

.article h3 {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
}

.article h4 {
    margin: 0;
    padding: 0;
    border: none;
}

.article h5 {
    margin: 0;
    padding: 0;
    border: none;
}

.article h6 {
    margin: 0;
    padding: 0;
    border: none;
}

.sidebar h3 {
    border-radius: 0;
}

.sidebar h4 {
    border-radius: 0;
}

/* ------------------------------------------------------------ h1 */

.article {
    h1.entry-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
        font-size: 32px;
        font-weight: 500;

        @include i.pad {
            margin-bottom: 1.5rem !important;
            font-size: 22px !important;
        }

        @include i.sp {
            font-size: 20px !important;
        }

        &::before,
        &::after {
            color: i.$primary !important;
            font-family: icomoon !important;
            font-size: 22px !important;
            content: "\e926" !important;

            @include i.tab {
                font-size: 14px !important;
            }

            @include i.sp {
                font-size: 12px !important;
            }
        }

        &::before {
            margin-right: 18px;
        }

        &::after {
            margin-left: 18px;
            transform: scale(-1, 1);
        }
    }
}

/* ------------------------------------------------------------ h2 */

/* ------------------------------------------------------------ h3 */

.article {
    h3 {
        width: 100%;
    }
}

/* ------------------------------ サイドバー　h3 */

/* ------------------------------------------------------------ h4 */

/* ------------------------------------------------------------ h5 */

/* ------------------------------------------------------------ p */

/* ------------------------------ サイドバー　h4 */

/* ------------------------------------------------------------ カテゴリーリンクアイコンラベル色*/

/* ------------------------------------------------------------------------------------------ ヘッダー */

/* ------------------------------------------------------------ メインのヘッダー */

.csz-front-logo {
    .site-logo-image {
        margin-top: 10px;
    }
}

.header-container {
    border-top: 6px solid i.$primary;
}

.header-container-in {
    flex-direction: column;

    .header .header-in {
        align-items: flex-start;
        position: relative;

        @include i.sp {
            //padding: 8px 0;
        }

        .box-header-title {
            @include i.sp {
                width: 100%;
            }
        }

        .box-conv-outer {
            position: absolute;
            top: 12px;
            right: 0;
            display: flex;
            flex-direction: row;

            @include i.padpc {
                top: 8px;
                margin-right: 8px;
            }

            @include i.sp {
                display: none;
            }

            .box-tel-outer {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 98px;
                margin-right: 2rem;
                border: 2px solid i.$primary;
                border-radius: 12px;

                @include i.padpc {
                    height: 91px;
                    margin-right: 1rem;
                }

                @include i.pad {
                    margin-right: 8px;
                }
            }

            .box-tel {
                width: 100%;
                //height: 64px;
                //margin-right: 2rem;
                padding: 0 6px 0 10px;
                color: white;
                cursor: pointer;
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                background-color: i.$primary;
                transition: all .2s;

                @include i.padpc {
                    font-size: 20px;
                }

                &:hover {
                    color: black;
                    background-color: i.$secondary-d-d;
                }

                &::before {
                    content: "\e918";
                    font-family: icomoon;
                    font-size: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 18px;

                    @include i.pad {
                        font-size: 14px;
                    }
                }
            }

            .box-tel-info {
                padding: 0 12px;
                font-size: 12px;
                line-height: 1.4;
            }

            .box-mail {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: auto;
                height: 98px;
                padding: 4px 30px;
                color: white;
                //cursor: pointer;
                font-size: 21px;
                font-weight: 700;
                background-color: i.$keycolor-d;
                border-radius: 12px;
                text-decoration: none;
                transition: all .2s;

                @include i.padpc {
                    height: 91px;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                &:hover {
                    color: black;
                    background-color: i.$secondary-d-d;
                }

                span {
                    padding: 12px;
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;

                    @include i.pad {
                        padding: 6px;
                    }

                    &::before {
                        content: "\e905";
                        font-family: icomoon;
                        font-size: 22px;
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    .logo-header {
        padding-top: 0;

        @include i.pad {
            padding: 8px 0;
        }

        @include i.tab {
            //text-align: center;
        }

        @include i.sp {
            padding: 4px 0;
        }

        img {
            max-height: 64px;

            @include i.pc {
                max-height: 48px;
                margin-left: 12px;
            }

            @include i.pad {
                max-height: 48px;
            }
        }
    }

    .tagline {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 8px;
        font-size: 1rem;
        color: white;
        background-color: i.$primary;
        border-radius: 0 0 12px 12px;

        @include i.tab {
            margin-bottom: 0;
            margin-left: 8px;
            font-size: 14px;
        }

        @include i.sp {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/* ------------------------------------------------------------------------------------------ ナビ */

.navi {
    margin-top: 6px;
    padding-top: 6px;
    height: 60px;
    //background-color: i.$primary;
    //border-top: 2px solid i.$primary;

    @include i.pad {
        height: auto;
    }
}

.navi-in {
    background-color: white;
}

.navi-in>ul {
    justify-content: flex-start;

    .caption-wrap {
        width: auto;
    }
}

.navi-in a {
    display: flex;
    align-items: center;
    width: auto;
    //height: 66px;
    margin-left: 22px;
    //border-top: 12px solid i.$primary;
    //border-bottom: 6px solid white;
    font-size: 18px;
    //font-weight: bold;
    transition: all 0s;

    @include i.pc {
        margin-left: 16px;
    }

    @include i.pad {
        height: auto;
    }

    &:hover {
        background-color: i.$primary-l-l;
        //border-bottom: 6px solid i.$primary;
        transition: all 0s;
    }
}

.navi-in>ul>li:first-of-type {
    a {
        //margin-left: 0;
    }
}

.navi-in>ul>li:last-of-type {
    a {
        @include i.pc {
            //margin-right: 0.5rem;
        }
    }
}

#navi .navi-in>ul>li>a {
    padding: 0 1em;
    border: none;

    @include i.pc {
        padding: 0 0.5em;
    }
}

.navi-in {
    .menu-item:hover {
        .sub-menu {
            display: grid;
            grid-auto-flow: column;
            column-gap: 1.5rem;
        }
    }

    .icn-menu-intro.menu-item:hover {
        .sub-menu {
            grid-template-rows: repeat(3, auto);
        }
    }

    .icn-menu-case.menu-item:hover {
        .sub-menu {
            grid-template-rows: repeat(5, auto);
        }
    }

    .icn-menu-about.menu-item:hover {
        .sub-menu {
            grid-template-rows: repeat(2, auto);
        }
    }
}

.navi-in>ul .sub-menu {
    min-width: auto;
    width: auto;
    margin-top: -7px;
    margin-left: 3px;
    padding: 6px 24px;
    background-color: i.$primary-l-l;
    filter: drop-shadow(2px 2px 4px rgba(i.$black, 0.25));

    li {
        height: 48px;
    }

    a {
        width: 100%;
        margin-left: 0;

        &:hover {
            //background-color: i.$primary-l;
            border-top: 1px solid i.$primary-l;
            border-bottom: 1px solid i.$primary-l;
            color: i.$primary-d;

            .item-label {
                &::before {
                    color: i.$primary-l;
                }
            }
        }
    }
}




.header-container-in .navi {
    width: auto;
}

.icn-menu-intro,
.icn-menu-case,
.icn-menu-price,
.icn-menu-about,
.icn-menu-blog {
    .item-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &::before {
            font-family: "icomoon";
            margin-right: 10px;
            font-size: 22px;
            color: i.$primary-d;

            @include i.pc {
                font-size: 20px;
            }
        }
    }
}

.icn-menu-intro {
    .item-label {
        &::before {
            content: "\e902";
        }
    }
}

.icn-menu-case {
    .item-label {
        &::before {
            content: "\e91b";
        }
    }
}

.icn-menu-price {
    .item-label {
        &::before {
            content: "\e91c";
        }
    }
}

.icn-menu-about {
    .item-label {
        &::before {
            content: "\e901";
        }
    }
}

.icn-menu-blog {
    .item-label {
        &::before {
            content: "\e903";
        }
    }
}

/* ------------------------------------------------------------ swiper */

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.swiper-container {
    width: 90%;
    max-width: 1600px;
    height: 800px;
    position: relative;
    z-index: 50;

    @include i.wpc {
        height: 700px;
    }

    @include i.padpc {
        height: 360px;
    }

    @include i.tab {
        //width: auto;
        //margin-top: -154px;
        //background-color: white;
        //z-index: 1;
    }

    @include i.sp {
        width: 100%;
        height: 260px;
    }

    .swiper-wrapper {
        margin-top: 0;

        //flex-shrink: 0;
        //position: relative;
        .swiper-slide {
            flex-shrink: 0;
            //width: 100%;
            //height: 100%;
            //background-color: antiquewhite;
            background-repeat: no-repeat;
            background-position: 50% 30%;
            background-size: cover;

            img {
                aspect-ratio: 16 / 9;
                width: 100%;
                max-width: 1920px;
                //height: auto;
                //margin-top: -200px;
                object-fit: cover;
                object-position: center;
            }

            p {
                display: block;
                width: 100%;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                right: 10%;
                color: #ffffff;
                text-decoration: none;
                text-align: right;
                font-size: 3.5rem;
                font-weight: bold;
                filter: drop-shadow(4px 4px 4px rgba(i.$black, 0.5));

                @include i.pad {
                    font-size: 3rem;
                }

                @include i.sp {
                    right: 0;
                    text-align: center;
                    font-size: 2rem;
                }

                @media (orientation: landscape) and (max-height: 480px) {
                    top: 200px;
                }
            }

            p {
                margin: 0;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        @include i.sp {
            display: none;
        }
    }

    .swiper-slide-active {
        p {
            animation-name: fade-in;
            animation-duration: 2s;
            animation-delay: 2s;
            animation-fill-mode: both;
        }
    }
}

/* ------------------------------------------------------------------------------------------ コンテンツ（全体）セクション */

.content {
    margin-top: 0;
}

.icon2-more-arrow {
    margin-left: 8px;

    &::before {
        color: i.$gray-500;
    }
}

/* ------------------------------------------------------------ ヒーロー */

.area-hero,
.area-hero-treatment,
.area-hero-introduction,
.area-hero-price,
.area-hero-about,
.area-hero-case-head-shoulder-neck,
.area-hero-case-waist-back,
.area-hero-case-arm,
.area-hero-case-leg,
.area-hero-case-fatigue,
.area-hero-case-pelvis,
.area-hero-case-stretch,
.area-hero-case-others,
.area-hero-customer-voice {
    position: relative;
    background-image: linear-gradient(to top, #FFD9CB 0%, white 100%);
    margin-top: 8px;
    overflow: hidden;

    @include i.sp {
        margin-top: 0;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        mix-blend-mode: multiply;

        @include i.padpc {
            display: none;
        }
    }

    &::before {
        width: 500px;
        height: 600px;
        background-image: url(../img/parts/hero-back-02.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: 5px;
        bottom: 40px;
    }

    &::after {
        width: 850px;
        height: 600px;
        background-image: url(../img/parts/hero-back-01.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        right: 5px;
        bottom: 40px;
    }

    .frame-hero-up,
    .frame-hero-down {
        vertical-align: baseline;
        width: auto;
        height: auto;
        position: absolute;
        top: -1px;
        left: 0;
        z-index: 70;
        object-fit: cover;

        @include i.sp {
            height: 15px;
        }
    }

    .frame-hero-down {
        //top: 0;
    }

    .frame-hero-up {
        top: inherit;
        bottom: -1px;
        transform: scale(1, -1);
    }

    .spn-top-mds {
        //writing-mode: vertical-rl;
        font-family: 'メイリオ';
        font-size: 72px;
        font-weight: bold;
        color: white;
        text-align: start;
        letter-spacing: 4px;
        line-height: 1.5;
        padding: 4px;
        filter: drop-shadow(2px 2px 4px rgba(i.$black, 0.7));
        position: absolute;
        bottom: 8%;
        right: 8%;
        z-index: 70;

        @include i.padpc {
            font-size: 48px;
            bottom: 4%;
            right: 4%;
        }

        @include i.sp {
            font-size: 36px;
            bottom: 4%;
            left: 3%;
            right: inherit;
        }
    }

    ins {
        padding-left: 4px;
        text-decoration: overline;
    }

    .swiper-container {
        position: relative;
        //z-index: 10;
    }
}

.area-hero-treatment {

    &::before,
    &::after {
        //display: none;
        z-index: 1;
    }

    .box-hero-treatment {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: 700px;
        text-align: center;
        position: relative;
        z-index: 10;

        @include i.wpc {
            height: 620px;
        }

        @include i.padpc {
            height: auto;
        }

        img {
            width: auto;
            height: 100%;
            margin: 0 auto;

            @include i.padpc {
                height: auto;
            }

        }
    }
}

.area-hero-introduction,
.area-hero-price,
.area-hero-about,
.area-hero-case-head-shoulder-neck,
.area-hero-case-waist-back,
.area-hero-case-arm,
.area-hero-case-leg,
.area-hero-case-fatigue,
.area-hero-case-pelvis,
.area-hero-case-stretch,
.area-hero-case-others,
.area-hero-customer-voice {
    width: 100%;
    height: 560px;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;
    position: relative;
    margin-top: 8px;

    @include i.padpc {
        height: 480px;
    }

    @include i.pad {
        height: 360px;
        margin-top: 0;
    }

    @include i.sp {
        height: 260px;
        margin-top: 0;
    }

    &::before,
    &::after {
        display: none;
    }
}

.area-hero-introduction {
    background-image: url(../img/photo/hero-intro.jpg);

    @include i.sp {
        background-image: url(../img/photo/hero-intro_s.jpg);
    }
}

.area-hero-price {
    background-image: url(../img/photo/hero-price.jpg);

    @include i.sp {
        background-image: url(../img/photo/hero-price_s.jpg);
    }
}

.area-hero-about {
    background-image: url(../img/photo/hero-about.jpg);

    @include i.sp {
        background-image: url(../img/photo/hero-about_s.jpg);
    }
}

.area-hero-newsblog,
.area-hero-newsblog {
    width: 100%;
    height: 420px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 8px;

    @include i.sp {
        margin-top: 0;
    }
}

.area-hero-newsblog {
    background-image: url(../img/image/image-3-2-1.jpg);
}

.area-hero-newsblog {
    background-image: url(../img/image/image-3-2-1.jpg);
}

/* ------------------------------------------------------------ 営業時間ボックス */

table th,
table td {
    border: none;
}

.areadrawermenu,
.areadrawermenu-page,
.dmfooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 540px;
    padding: 6px;
    background-color: i.$primary;
    position: fixed;
    top: 45%;
    left: -20px;
    z-index: 999;
    border-radius: 0 10px 10px 0;
    transition: all 1s;

    @include i.pad {
        display: none;
    }

    .box-drawermenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 490px;
        padding: 6px 6px 6px 40px;
        background-color: rgba(white, 1);
        border-radius: 0 20px 20px 0;

        .box-btn-extres {
            display: none;
        }
    }

    .box-dm-up {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 12px auto 16px;

        a {
            text-decoration: none;
        }
    }

    .box-dm-tel {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        height: 64px;
        padding: 6px 30px;
        font-size: 21px;
        font-weight: bold;
        color: i.$primary-d;
        background-color: i.$primary-l-l;
        cursor: pointer;
        transition: all 0.2s;

        &::before {
            font-family: "icomoon";
            content: "\e918";
            margin-right: 6px;
            font-size: 18px;
        }

        &:hover {
            background-color: i.$primary-l;
        }
    }

    .box-dm-mail {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        height: 64px;
        padding: 6px 30px;
        font-size: 21px;
        font-weight: bold;
        color: i.$keycolor-d;
        background-color: i.$keycolor-l-l;
        cursor: pointer;
        transition: all 0.2s;

        &::before {
            font-family: "icomoon";
            content: "\e916";
            margin-right: 6px;
            font-size: 28px;
        }

        &:hover {
            background-color: i.$keycolor-l;
        }
    }

    .box-dm-bottom {
        width: 100%;
        margin-bottom: 8px;
        text-align: center;

        @include i.ssp {
            width: 92%;
            margin-left: auto;
            margin-right: auto;
        }

        .spn-icon-clock {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: auto;
            margin: 0 auto;

            &::before {
                font-family: "icomoon";
                content: "\e909";
                margin-right: 6px;
                font-size: 16px;
            }
        }

        .spn-sml {
            display: block;
            margin-bottom: -3px;
            font-size: 14px;
        }
    }

    .box-dmenu-navi,
    .box-dmenu-navi-page {
        display: none;
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem 0 0 1rem;
        border-top: 1px dotted i.$primary;

        a {
            text-align: left;
        }
    }

    .box-dmenu-navi.open-dmenu-navi {
        display: block;
    }

    .box-dmenu-navi-page {
        display: block;
    }

    .box-schedule {
        width: auto;
        margin: 0 auto 4px;
        border-collapse: collapse;
        border: 0;

        @include i.ssp {
            display: block;
            width: 92%;
            overflow-x: scroll;
        }

        tr {
            color: i.$primary;
            font-size: 16px;
            border: 1px solid i.$primary;
        }

        td {
            padding: 2px 10px;
            font-weight: bold;
            text-align: center;
            border: 1px solid i.$primary;
            //border-radius: 4px;
        }

        tr.brd-w-tr {
            background-color: i.$primary;
            color: white;
            font-size: 18px;
            font-weight: bold;
            border: 1px solid i.$primary;

            td.brd-w {
                border-right: 1px solid white !important;
            }

            td.brd-w:last-of-type {
                border-right: 1px solid i.$primary !important;
            }
        }
    }

    a {
        display: block;
        width: 90%;
        font-size: 14px;
        text-align: right;
        text-decoration: nooe;
        cursor: pointer;

    }

    .box-dm-label {
        display: block;
        width: 28px;
        margin: 0 2px 0 6px;
        text-align: center;
        line-height: 1;
    }

    table tr:nth-of-type(2n + 1) {
        background: none;
    }

    .spn-notice,
    .spn-notice_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        &::before,
        &::after {
            font-family: icomoon;
            content: "\e926";
            font-size: 12px;
            color: i.$primary;
        }

        &::before {
            margin-right: 6px;
        }

        &::after {
            margin-left: 6px;
            transform: scale(-1, 1);
        }
    }

    .spn-notice_footer {
        display: none;
    }

    .spn-notice2_footer {
        display: none;
    }
}

.btn-dm {
    font-size: 20px;
    font-weight: bold;
    color: white;
    writing-mode: vertical-rl;
    cursor: pointer;

    &::after {
        display: inline-block;
        font-family: "icomoon";
        content: "\e900";
        margin: 12px 0 0 0;
        padding: 3px;
        font-size: 16px;
        text-align: center;
        background-color: white;
        color: i.$primary;
        border-radius: 3px;
        transform: scale(-1, 1);
    }
}

.areadrawermenu,
.areadrawermenu-page {
    filter: drop-shadow(1px 1px 3px rgba(i.$black, 0.3));
}

.open-dmenu,
.slidedmenu-animation {
    left: -490px;
    transition: all 0.5s;

    .btn-dm {
        &::after {
            transform: scale(1, 1) rotate(2turn);
            transition: all, 1s;
        }
    }
}

.slidedmenu-animation.open-dmenu.close-dmenu {
    left: -20px;
    transition: all 0.5s;

    .btn-dm {
        &::after {
            transform: scale(-1, 1);
            transition: all, 1s;
        }
    }
}

.areadrawermenu-page {
    left: -490px;
    transition: all 0.5s;

    .btn-dm {
        &::after {
            transform: scale(1, 1) rotate(2turn);
            transition: all, 1s;
        }
    }
}

.areadrawermenu-page.open-dmenu {
    left: -20px;
    transition: all 0.5s;

    .btn-dm {
        &::after {
            transform: scale(1, 1) rotate(2turn);
            transition: all, 1s;
        }
    }
}

.areadrawermenu.open-dmenu,
.areadrawermenu-page.open-dmenu {
    filter: drop-shadow(4px 4px 6px rgba(i.$black, 0.3));
}


/* ------------------------------------------------------------ コンテンツセクション（ベース） */

.wrap-padding {
    padding: 60px;

    @include i.tab {
        padding: 16px;
    }

    @include i.sp {
        padding: 0;
    }
}

.area-btn-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 380px;
        padding: 8px;
        margin-bottom: 1.5rem;
        text-decoration: none;
        color: inherit;
        font-size: 18px;
        background-color: white;
        border: 1px solid i.$primary;
        border-radius: 24px;
        transition: all, 0.2s;
        position: relative;

        @include i.sp {
            width: 98%;
            font-size: 0.9rem;
        }

        &:hover {
            color: white;
            font-weight: 500;
            background-color: i.$primary;
            border: 1px solid i.$primary-l;
            filter: drop-shadow(4px 4px 6px rgba(i.$black, 0.2));
            transition: all, 0.2s;

            .icon2-more-arrow::before {
                color: white;
            }
        }

        &::before {
            font-family: "icomoon";
            margin-right: 6px;
            font-size: 24px;
            line-height: 1;
            color: i.$primary;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
        }

        &:hover::before {
            color: white;
        }
    }

    .btn-link-merit {
        &::before {
            content: "\e90e";
        }

        @include i.ssp {
            width: 92%;
        }
    }

    .btn-link-flow {
        &::before {
            content: "\e91c";
        }
    }

    .btn-link-about {
        &::before {
            content: "\e901";
        }
    }

    .btn-link-qa {
        &::before {
            content: "\e919";
        }
    }

    .btn-link-voice {
        &::before {
            content: "\e91e";
        }

        @include i.ssp {
            width: 92%;
        }
    }
}

.area-btn-link-solo {
    margin-top: 2rem;
}

/* ------------------------------------------------------------ ニュース */

.sec-news {
    h1 {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: normal;
    }
}

.box-news-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 1rem;

    @include i.pad {
        width: 98%;
    }
}

.spn-mds-news {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //width: 200px;
    font-size: 1.2em;
    //margin-top: -34px;
    background-color: white;

    @include i.sp {
        justify-content: center;
    }

    &::before {
        font-family: "icomoon";
        content: "\e91f";
        margin-right: 12px;
        font-size: 36px;
        color: i.$primary;
    }
}

.area-items-news {
    width: 100%;

    .widget-entry-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include i.sp {
            flex-direction: column;
        }

        a {
            display: block;
            width: 32%;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid i.$gray-300;

            @include i.sp {
                width: 90%;
                margin-bottom: 12px;
            }
        }

        .widget-entry-card {
            display: flex;
            flex-direction: column;
        }

        figure {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;

            img {
                width: 100%;
            }
        }

        .widget-entry-card-content {
            display: flex;
            flex-direction: column-reverse;
            margin-left: 0;
        }

        .widget-entry-card-title {
            margin-top: 8px;
            font-size: 1.2rem;
            line-height: 1.4;
        }

        .widget-entry-card-date {
            display: block;
            width: 100%;
            text-align: right;
        }
    }
}

.btn-newslist {
    display: block;
    padding: 4px 16px;
    font-size: 14px;
    text-decoration: none;
    color: i.$gray-800;
    background-color: white;
    border: 1px solid i.$gray-500;
    border-radius: 24px;
    transition: all, 0.2s;

    &:hover {
        border: 1px solid i.$red;
        transition: all, 0.2s;
    }
}

.box-news-notice {
    display: block;
    width: 60%;
    padding: 16px 30px;
    margin: 2rem auto;
    text-align: center;
    border: 2px solid i.$tertiary;
    border-radius: 6px;
    background-color: i.$notice-back;
    position: relative;
    overflow: hidden;

    @include i.pad {
        width: 74%;
    }

    @include i.tab {
        width: 90%;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        width: 100px;
        height: 100px;
        z-index: 5;
        opacity: 0.1;
    }

    &::before {
        background-image: url(../img/parts/safe-4.png);
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        position: absolute;
        top: 5px;
        left: 20px;
    }

    &::after {
        background-image: url(../img/parts/safe-1.png);
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        position: absolute;
        bottom: 3px;
        right: 20px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 500;
        position: relative;
        z-index: 10;

        &::before {
            font-family: "icomoon";
            content: "\e909";
            margin-right: 6px;
            font-size: 16px;
        }
    }

    a {
        //display: block;
        width: auto;
        padding: 6px 12px;
        margin: 20px 0 8px;
        background-color: white;
        position: relative;
        z-index: 10;
        cursor: pointer;
    }
}

.box-pickup {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 5rem;

    @include i.sp {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    a {
        display: block;
        width: 31%;
        text-decoration: none;
        color: inherit;
        transition: all .2s;

        @include i.sp {
            width: 90%;
            margin: 0 auto 12px;
        }

        img {
            border-radius: 12px 12px 0 0;
        }

        &:hover {
            cursor: pointer;
            color: #cc3600;

            img {
                opacity: .8;
                transition: all .2s;
            }
        }
    }

    p {
        margin-top: .5rem;
        font-size: 1.1rem;
        text-align: center !important;
        border-top: 1px solid i.$primary;
        border-bottom: 1px solid i.$primary;
        background-color: white;

        @include i.pad {
            font-size: 1rem;
        }
    }
}

.box-pickup-tpt-c {
    margin: 3rem auto 0;
    padding: 2rem;
    background-color: white;
    border: 2px solid i.$primary;
    border-radius: 12px;

    @include i.sp {
        width: 98%;
        padding: 2rem 1rem;
        border: none;
    }

    h2 {
        margin-top: 0 !important;
    }

    .box-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        @include i.pad {
            flex-direction: column;
        }

        .box-txt {
            width: 56%;

            @include i.pad {
                width: 100%;
            }

            p {
                width: 90%;
                margin: 0 auto;

                @include i.pad {
                    width: 100%;
                }
            }
        }

        figure {
            width: 50%;
            height: 100%;

            @include i.pad {
                width: 100%;
            }
        }
    }
}

.box-extra-notice,
.box-extra-notice-2nd {
    display: block;
    width: 90%;
    margin: 4rem auto;
    //padding: 16px 30px;
    text-align: center;

    @include i.pad {
        width: 100%;
    }

    @include i.sp {
        width: 90%;
        padding: 16px;
    }

    a {
        display: block;
        margin-bottom: 2rem;
        border: 1px solid #fff;
        transition: all, 0.1s;

        &:hover {
            border: 1px solid #ffa585;
            opacity: 0.7;
            transition: all, 0.1s;
        }
    }
}

.box-extra-notice-2nd {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
        width: 48%;

        @include i.sp {
            width: 100%;
        }
    }
}

/* ------------------------------------------------------------ お知らせ */

.box-top-notice {
    display: block;
    border: 2px solid i.$primary;
    border-radius: 12px;

    @include i.sp {
        margin: 12px 8px 0;
    }

    .box-title {
        padding: 4px 0;
        color: white;
        font-size: 1.3rem;
        font-family: "メイリオ";
        font-weight: 700;
        text-align: center;
        background-color: i.$primary;

        &::before {
            font-family: "icomoon";
            content: "\e90e";
            margin-right: 10px;
            font-size: 22px;
            color: white;
        }
    }

    .box-info-outer {
        padding: 12px;

        .box-info {
            display: flex;
            justify-content: space-around;
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px dotted i.$gray-500;

            @include i.sp {
                flex-direction: column;
            }
        }

        .box-info:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        .info-mds {
            width: 33%;
            font-family: "メイリオ";
            font-size: 1.2rem;
            font-weight: 500;
            text-align: left;

            @include i.sp {
                width: 100%;
            }

            &::before {
                content: "・";
                margin-right: 6px;
                font-size: 1.2rem;
                color: i.$primary;
            }
        }

        .info-text {
            width: 55%;
            padding: 0 6px;
            text-align: left;
            line-height: 1.5;

            @include i.sp {
                width: 100%;
            }
        }

        .info-date {
            width: 12%;
            text-align: right;
            font-size: .9rem;

            @include i.sp {
                width: 100%;
            }
        }
    }
}

/* ------------------------------------------------------------ YouTubeリスト */

.box-video-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding-bottom: 1rem;

    @include i.pad {
        width: 98%;
    }
}

.area-items-video {
    //width: 100%;
}

.box-ytvideo-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include i.sp {
        flex-direction: column;
    }

    .box-ytvideo {
        width: 32%;

        @include i.sp {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 12px;
        }

        iframe {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: auto;
        }
    }
}


/* ------------------------------------------------------------ オファー */

.sec-offer,
.sec-offer-price {

    .area-page {
        margin-top: 20px;
        padding: 20px 0;
        background-color: i.$primary;

        @include i.padpc {
            padding-bottom: 16px;
        }

        .wrap-padding {
            margin: 30px auto;
            padding: 40px 30px;
            border: 2px solid white;
            background-color: rgba(white, .2);

            @include i.padpc {
                margin: 0 16px;
                padding: 20px 16px;
            }

            @include i.pad {
                padding-top: 12px;
                padding-bottom: 32px;
            }

            @include i.sp {
                padding: 12px 8px 8px;
            }

            @include i.ssp {
                margin: 0 8px;
            }
        }
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        font-family: "メイリオ";
        font-size: 1.8em;
        font-weight: 500;
        color: white;
        line-height: 1.3;

        &::before,
        &::after {
            font-family: "icomoon";
            content: "\e91f";
            font-size: 22px;
            color: white;
        }

        &::before {
            margin-right: 16px;
        }

        &::after {
            margin-left: 16px;
        }

        @include i.padpc {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        @include i.sp {
            margin-top: 0;
            font-size: 22px;
            text-align: center;
            line-height: 1.5;
        }
    }

    .box-offer-outer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        gap: 24px;

        @include i.sp {
            flex-direction: column;
        }
    }

    .box-offer {
        //width: 48%;
        padding: 6px;
        background-color: white;
        border: 6px solid white;
        overflow: hidden;

        @include i.sp {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 12px;
        }

        span {
            display: block;
            font-size: 36px;
            color: i.$red;
            text-align: center;
        }

        .btn-resv {
            display: block;
            width: 80%;
            margin: 20px auto 8px;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
            color: white;
            border-bottom: 7px solid hsl(197, 99%, 31%);
            border-radius: 6px;
            background-image: linear-gradient(to right, rgba(57, 210, 248, 1) 0%, #01995f 100%);
            //background-image: linear-gradient(to right, rgba(247, 93, 139, 1) 0%, rgba(254, 220, 64, 1) 100%);
            text-decoration: none;
            vertical-align: middle;
            //filter: drop-shadow(2px 2px 4px rgba(i.$black, 0.3));

            @include i.sp {
                width: 90%;
                padding: 4px 16px;
                font-size: 22px;
            }

            @include i.ssp {
                font-size: 20px;
            }

            &:hover {
                margin-top: 25px;
                border-bottom: 2px solid #00662d;
                color: black;
                background-image: linear-gradient(to right, #ffed59 0%, #fff18a 100%);
                transition: all .2s;
            }
        }

        .btn-resv-cubic {
            //border-bottom: 5px solid #00662d;

            &:hover {
                //margin-top: 23px;
                //border-bottom: 2px solid #00662d;
            }
        }

        img {
            //aspect-ratio: 1 / 1;
            //width: 100%;
            //max-width: 480px;
            //height: 100%;
            max-height: 360px;
            margin: 0;
            //object-fit: cover;
            border-radius: 4px;
            transition: all .1s;
            filter: drop-shadow(2px 2px 4px rgba(i.$black, 0.2));

            &:hover {
                transform: scale(1.1);
                transition: all .2s;
            }
        }
    }

    .area-singlepost {
        margin-bottom: 2rem;

        h2 {
            padding-top: 18px;
            margin-bottom: 12px;
            color: inherit;
            border-top: 1px dotted i.$primary !important;
            border-bottom: none !important;
        }

        .box-offer {
            flex-direction: column;

            img {
                width: 100%;
                max-width: 100%;
            }

            .box-txt {
                width: 100%;
                margin-left: 0;
            }

        }

    }
}

.sec-offer {

    @include i.pad {
        margin-bottom: 2rem;
    }
}

.sec-offer-price {
    margin-bottom: 2rem;

    .area-page {
        padding: 2rem 0;
        border-radius: 12px;

        @include i.sp {
            padding-left: 8px;
            padding-right: 8px;
        }

        h2 {
            margin-bottom: 20px;

            &::before {
                font-family: "icomoon";
                content: "\e91f";
                font-size: 22px;
                color: white;
            }
        }
    }

    .box-offer {
        img {
            max-height: 240px;
        }
    }
}

/* ------------------------------------------------------------ 人気の施術 */

.area-singlepost,
.area-frontpage {

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 2rem;
        font-size: 1.8em;
        font-weight: 500;
        line-height: 1.3;

        &::before {
            font-family: "icomoon";
            content: "\e91f";
            margin-right: 10px;
            font-size: 22px;
            color: i.$primary;
        }

        @include i.tab {
            margin-top: 2rem;
            margin-bottom: 8px;
        }

        @include i.sp {
            font-size: 22px;
            text-align: center;
            line-height: 1.5;
        }

        @include i.ssp {
            font-size: 20px;
        }
    }

    .box-treatments-outer-outer {
        padding: 2rem 1rem 1rem;
        border-radius: 12px;
        background-color: white;
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include i.padpc {
            margin-bottom: 1rem;
        }

        @include i.sp {
            width: 96%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .box-treatments-outer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .box-treatment {
        width: 32%;
        margin-bottom: 1.5rem;
        overflow: hidden;

        @include i.tab {
            width: 48%;
            margin-bottom: 1rem;
        }

        img {
            aspect-ratio: 16 / 9;
            width: 100%;
            //max-width: 480px;
            height: 100%;
            object-fit: cover;
            transition: all .1s;
            border-radius: 8px;

            @include i.tab {
                width: 100%;
                max-width: 100%;
            }

            &:hover {
                transform: scale(1.2);
                transition: all .2s;
            }
        }
    }

    .box-treatment:last-child {
        //margin-bottom: 0;
        //padding-bottom: 0;
    }

    .area-singlepost {

        .box-treatment {
            flex-direction: column;

            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

/* ------------------------------------------------------------ ふじさわサーチ */

.intro-search {
    margin-top: 3rem;
    margin-bottom: 6rem;
}

.fujisawa-listsbox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 auto 2rem;
    padding: 40px 0;
    border-radius: 12px;
    background-color: white;

    @include i.pad {
        //width: 100%;
        flex-direction: column;
    }

    .listbox-symptom,
    .listbox-treatment,
    .listbox-cases {
        display: block;
        padding: 1rem 6rem;
        color: white;
        font-size: 20px;
        font-weight: 700;
        border-radius: 12px;
        background-size: 200% auto;
        text-shadow: 1px 1px 1px rgba(i.$black, 0.5), 1px -1px 1px rgba(i.$black, 0.5), -1px 1px 1px rgba(i.$black, 0.5), -1px -1px 1px rgba(i.$black, 0.5);
        box-shadow: 0 0 20px #eee;
        transition: all, .2s;
        text-decoration: none;
        cursor: pointer;

        @include i.pad {
            margin-bottom: 1rem;
        }

        &:hover {
            transition: all, .2s;
            background-position: right center;
            filter: drop-shadow(8px 8px 4px rgba(i.$black, 0.2));
        }
    }

    .listbox-symptom {
        //border: 2px solid i.$symptoms;
        background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
    }

    .listbox-cases {
        //border: 2px solid i.$keycolor;
        //background-color: i.$keycolor;
        background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
    }

    .listbox-treatment {
        //border: 2px solid i.$treatment;
        //background-color: i.$treatment;
        background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
    }
}

.box-searchform {

    @include i.sp {
        padding: 0 4px;
    }

    p {
        margin-top: 12px;
        margin-bottom: 1.5rem;
        text-align: right;
        font-size: .9rem;

        strong {
            color: i.$red;
        }
    }
}

.box-rcmdkwd {
    width: 42%;
    margin: 2em auto;
    position: relative;
    padding: 0.5em 1.5em;
    background: white;
    border-top: solid 2px i.$primary;
    border-bottom: solid 2px i.$primary;

    @include i.pad {
        width: 64%;
    }

    @include i.sp {
        width: 100%;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: -10px;
        width: 2px;
        height: -webkit-calc(100% + 20px);
        height: calc(100% + 20px);
        background-color: i.$primary;
    }

    &::before {
        left: 10px;
    }

    &::after {
        right: 10px;
    }

    p {
        margin: 8px 0 16px;
        padding-bottom: 4px;
        text-align: center;
        border-bottom: 3px dotted i.$primary;

        span {
            color: i.$primary;
        }
    }

    ul {
        width: auto;
        margin-bottom: 0;
        padding-left: 1rem;

        @include i.pad {
            padding-left: .5rem;
        }
    }

    li {
        padding-left: .8em;
        list-style: none;
        position: relative;
        text-align: left;

        &::before {
            border-radius: 50%;
            width: 5px;
            height: 5px;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background: i.$primary;
        }
    }
}

.search-submit-fs {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 1.8rem auto 0;
    padding: 1em 2em;
    width: 360px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    background-color: i.$primary-l;
    border: 1px solid i.$primary-l;
    border-radius: 50vh;
    transition: all 0.2s;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: -7px;
        left: -8px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 2px solid i.$primary-d;
        border-radius: 50vh;
        transition: all 0.2s;
    }

    &::after {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        right: 60px;
        background-image: url(../img/svg/icon-search.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }


    /* 
    &::after {
        content: '';
        position: absolute;
        top: 48%;
        right: 30px;
        width: 5px;
        height: 5px;
        border-top: 5px solid white;
        border-right: 5px solid white;
        transform: rotate(45deg) translateY(-50%);
        transition: all 0.3s;
    }
     */

    &:hover {
        justify-content: center;
        color: white;
        text-decoration: none;
        background-color: i.$primary;
        filter: drop-shadow(6px 6px 5px rgba(i.$black, 0.2));
        transition: all 0.3s;
    }

    &:hover::before {
        top: 0;
        left: 0;
        border-color: white;
        border-width: 2px;
    }

    &:hover::after {
        border-color: white;
        rotate: 360deg;
        transition: all 0.2s;
    }
}

/* ------------------------------ サイドバーのウィジェット用検索ボックス */

.searchform-widget {
    justify-content: flex-start;
    width: 240px;
    margin: 1rem auto 0;

    &::after {
        width: 22px;
        height: 22px;
        right: 30px;
    }
}


/* ------------------------------------------------------------ ふじさわデータベース */

.h1-small {

    @include i.sp {
        margin-top: 16px;
        margin-bottom: 32px !important;
        font-size: 18px !important;

        &::before,
        &::after {
            font-size: 14px !important;
        }
    }
}


.area-fujisawasearchpage {

    .section-fujisawalists {
        margin-bottom: 40px !important;
    }

    @include i.sp {
        width: 92%;
        margin-left: auto;
        margin-right: auto;
    }
}

.h2-fujisawasaerch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem !important;
    padding: 10px 0 !important;
    font-size: 32px;
    font-weight: 500;
    color: white;
    background-color: i.$primary !important;
    border-radius: 50px !important;

    @include i.sp {
        width: 92%;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 0 !important;
    }

    &::before,
    &::after {
        color: white;
        font-family: icomoon;
        font-size: 22px;
        content: "\e91f";
    }

    &::before {
        margin-right: 18px;
        transform: scale(-1, 1);
    }

    &::after {
        margin-left: 18px;
    }
}

.spn-star {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
        color: i.$primary;
        font-family: icomoon;
        font-size: 18px;
        content: "\e91f";
    }

    &::before {
        margin-right: 18px;
        transform: scale(-1, 1);
    }

    &::after {
        margin-left: 18px;
    }
}

.label-blogcat,
.label-cpostterm,
.label-cpostterm2 {
    display: inline-block;
    margin-left: 16px;
    padding-right: 6px;
    font-size: .7rem;
    text-align: center;
    border-radius: 4px;

}

.label-blogcat {
    background-color: #bbddce;
}

.label-cpostterm {
    background-color: #fdd7c9;
}

.label-cpostterm2 {
    background-color: #c9eefd;
}

.section-fujisawalists {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto 5rem;

    .outer-listgroup {
        width: 48%;

        @include i.sp {
            width: 92%;
            margin: 0 auto;
        }
    }

    .spn-mds-head,
    .spn-mds-back,
    .spn-mds-arm,
    .spn-mds-leg,
    .spn-mds-fatigue,
    .spn-mds-pelvis,
    .spn-mds-stretch,
    .spn-mds-others {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
        border-bottom: 3px dotted i.$primary;
        padding-bottom: 8px;
        margin: 2rem 0 1rem;
        width: 100%;

        &::before {
            content: '';
            width: 32px;
            height: 32px;
            margin-right: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .spn-mds-head {

        &::before {
            background-image: url(../img/case-icon/icon-kubi-kata.svg);
        }
    }

    .spn-mds-back {

        &::before {
            background-image: url(../img/case-icon/icon-senaka-koshi.svg);
        }
    }

    .spn-mds-arm {

        &::before {
            background-image: url(../img/case-icon/icon-ude-te.svg);
        }
    }

    .spn-mds-leg {

        &::before {
            background-image: url(../img/case-icon/icon-ashi-hiza.svg);
        }
    }

    .spn-mds-fatigue {

        &::before {
            background-image: url(../img/case-icon/icon-hirou-teleork.svg);
        }
    }

    .spn-mds-pelvis {

        &::before {
            background-image: url(../img/case-icon/icon-kotsuban.svg);
        }
    }

    .spn-mds-stretch {

        &::before {
            background-image: url(../img/case-icon/icon-strecth-bodycare.svg);
        }
    }

    .spn-mds-others {

        &::before {
            background-image: url(../img/case-icon/icon-other.svg);
        }
    }

    ul {
        padding-left: 10px;

        @include i.sp {
            padding-left: 0;
        }
    }

    li {
        list-style-type: none;
        position: relative;
        padding-left: 0.6em;
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: 1px dotted i.$primary !important;

        &::before {
            border-radius: 50%;
            width: 5px;
            height: 5px;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background: i.$primary;
        }
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
        text-indent: .4rem;
        transition: all, .2s;

        &:hover {
            color: i.$red;
            background-color: i.$primary-l-l;
            //transition: all, .2s;
        }
    }
}

.box-results {
    width: 90%;
    margin: -2rem auto 1.5rem;
    font-size: .9rem;
    padding: 1rem;
    border: 2px dotted i.$keycolor-d;

    @include i.sp {
        width: 100%;
        margin-top: 1rem;
    }

    .box-result {

        strong {
            color: i.$red;
        }
    }
}

.listgroup {
    width: 100%;

    ul {

        @include i.sp {
            margin-bottom: 0;
        }

    }
}

/* ------------------------------------------------------------ お悩みどこ？ */

.sec-case-top,
.sec-case-pro {
    padding: 30px 0;
    background-color: i.$primary-l-l;
    position: relative;

    .frame-sec-up,
    .frame-sec-bottom {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
    }

    .frame-sec-up {
        top: 0;
        left: auto;
    }

    .frame-sec-bottom {
        bottom: 0;
        left: auto;
    }

    .wrap-padding {
        text-align: center;
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        font-size: 1.8em;
        font-weight: 500;

        &:last-of-type {
            margin-top: 5rem;
        }

        &::before {
            font-family: "icomoon";
            content: "\e91f";
            margin-right: 10px;
            font-size: 22px;
            color: i.$primary;
        }

        @include i.tab {}

        @include i.sp {
            font-size: 22px;
            text-align: center;
            line-height: 1.5;
        }

        @include i.ssp {
            font-size: 20px;
        }
    }

    p {
        text-align: left;
    }

    .p-92 {
        margin: 1.2em auto;
        text-align: center;

        @include i.sp {
            width: 92%;
            text-align: left;
        }
    }

    .area-case-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 4rem;

        @include i.sp {
            margin-top: 2rem;
            width: 92%;
            margin-left: auto;
            margin-right: auto;
        }

        .box-case {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 22%;
            margin-bottom: 2rem;
            padding-top: 8px;
            background-color: white;
            border: 2px solid white;
            border-radius: 12px;
            text-decoration: none;
            color: i.$gray-800;
            cursor: pointer;
            transition: all, 0.2s;

            @include i.pc {
                width: 32%;
            }

            @include i.sp {
                width: 48%;
            }

            @include i.ssp {
                width: 92%;
                margin-left: auto;
                margin-right: auto;
            }

            &:hover {
                border: 2px solid i.$keycolor;
                filter: drop-shadow(6px 6px 6px rgba(i.$black, 0.1));
                transition: all, 0.2s;
            }

            div {
                .spn-fz-lg {
                    font-size: 32px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 28px;
                    }
                }

                .spn-fz-md {
                    font-size: 32px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 24px;
                    }
                }

                .spn-fz-mmd {
                    font-size: 32px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 22px;
                    }
                }

                .spn-fz-msm {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 1.3;

                    @include i.sp {
                        font-size: 24px;
                    }
                }

                .spn-fz-smsm {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 1.3;

                    @include i.sp {
                        font-size: 22px;
                    }
                }

                .spn-fz-sm {
                    font-size: 18px;

                    @include i.sp {
                        font-size: 16px;
                    }
                }
            }

            img {
                width: 144px;
                height: 144px;
            }

            p {
                margin: 1.2em 0;
                padding: 0 8px;
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }

    .box-notice-case-top {
        width: 92%;
        margin: 0 auto 4rem;
        text-align: left;

        p {
            @include i.sp {
                margin-top: 0;
            }
        }
    }

    .spn-strong-top-treatment {
        display: inline-block;
        width: auto;
        margin: 12px 0;
        border-bottom: 2px dotted #ff662e;
        font-size: 1.2rem;
        font-weight: 500;

        @include i.tab {
            margin: 4px 0;
            font-size: 1rem;
        }
    }
}

.sec-introfront {
    .p-92 {
        margin: 1.2em auto;
        text-align: center;

        @include i.sp {
            width: 92%;
            text-align: left;
        }
    }
}

.sec-case-pro {
    .wrap {
        width: 100%;
    }
}

/* ------------------------------------------------------------ 初めての方へ */

.sec-introduction-top {
    padding: 30px 0;
    //background-color: i.$secondary-l-l;
    position: relative;

    .frame-sec-up,
    .frame-sec-bottom {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
    }

    .frame-sec-up {
        top: 0;
        left: auto;
    }

    .frame-sec-bottom {
        bottom: 0;
        left: auto;
    }

    .wrap-padding {
        padding: 30px;
        text-align: center;
        background-image: url(../img/parts/blue-en-2.svg);
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;

        @include i.sp {
            padding: 16px;
        }
    }

    .area-intro-intro {
        position: relative;
        width: 100%;
        padding-bottom: 4rem;
        margin-bottom: 4rem;

        @include i.tab {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            width: 200px;
            height: 200px;
            position: absolute;

            @include i.tab {
                opacity: 0;
            }
        }

        &::before {
            background-image: url(../img/parts/kumo-1-2.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            top: -20px;
            left: 0;

            @include i.sp {
                top: 40px;
            }
        }

        &::after {
            background-image: url(../img/parts/kumo-2-2.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            top: 80px;
            right: 40px;
        }
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.8em;
        font-weight: 500;
        line-height: 1.5;

        &:last-of-type {
            width: 92%;
            margin: 5rem auto 0;

            @include i.tab {
                margin-top: 3rem;
            }
        }

        &::before {
            font-family: "icomoon";
            content: "\e926";
            margin-right: 10px;
            font-size: 20px;
            color: i.$keycolor-d;

            @include i.sp {
                font-size: 14px;
            }
        }

        @include i.pad {
            font-size: 1.6em;
        }

        @include i.sp {
            font-size: 24px;
            text-align: center;
            line-height: 1.5;
        }

        @include i.ssp {
            font-size: 18px;
        }
    }

    p {
        display: block;
        width: 80%;
        margin: 1rem auto 1rem;
        text-align: left;
        line-height: 1.7;

        @include i.tab {
            width: 90%;
        }

        &:last-of-type {
            //margin: 0 auto 3rem;
            //padding-bottom: 3rem;
            //border-bottom: 1px dotted i.$primary;

            @include i.tab {
                //margin-bottom: 1rem;
                //padding-bottom: 1rem;
            }
        }
    }

    .p-narrow {
        width: 60%;

        @include i.pad {
            width: 90%;
        }
    }

    .spn-check {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
        line-height: 1.5;

        &::before {
            margin-right: 16px;
            font-family: icomoon;
            font-size: 16px;
            content: "\e908";
        }
    }

    .area-merit-top {
        .box-mds-merit {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 3rem;
            font-size: 28px;

            @include i.sp {
                font-size: 20px;
            }

            @include i.ssp {
                font-size: 16px;
            }

            &::before {
                font-family: "icomoon";
                content: "\e910";
                margin-right: 6px;
                font-size: 28px;
                color: i.$primary;

                @include i.ssp {
                    font-size: 22px;
                }
            }

            span {
                font-size: 60px;
                font-weight: bold;
                color: i.$primary-d;
                line-height: 1;

                @include i.sp {
                    font-size: 48px;
                }

                @include i.ssp {
                    font-size: 36px;
                }
            }
        }

        .box-merit-top-outer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            @include i.sp {
                flex-direction: column;
                justify-content: center;
            }
        }

        .box-merit-top {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 31%;
            padding: 16px;
            margin-bottom: 60px;
            border: 1px solid i.$gray-400;
            border-radius: 10px;
            background-color: white;
            position: relative;

            @include i.pad {
                width: 48%;
                margin-bottom: 48px;
            }

            @include i.sp {
                width: 100%;
                margin-bottom: 32px;
                margin-left: auto;
                margin-right: auto;
            }

            &::before {
                font-family: "icomoon";
                content: "\e90c";
                margin-right: 6px;
                font-size: 48px;
                color: i.$primary;
                opacity: 0.3;
                position: absolute;
                top: 10px;
                left: 10px;
                line-height: 1;
            }

            /* &::after {
                width: 60px;
                height: 60px;
                padding: 10px;
                font-style: italic;
                font-size: 60px;
                line-height: 1;
                color: i.$primary-d;
                background-color: white;
                border-radius: 50%;
                position: absolute;
                top: -44px;
                right: 10px;
            } */

            .num-merit {
                display: block;
                text-align: center;
                width: 80px;
                height: 80px;
                position: absolute;
                top: -40px;
                right: -20px;

                @include i.sp {
                    width: 52px;
                    height: 52px;
                    top: -26px;
                    right: -7px;
                }
            }

            span {
                margin-top: 24px;
                font-size: 14px;
            }

            h3 {
                margin-top: 8px;
                font-size: 24px;
                font-weight: normal;
                font-style: italic;
                line-height: 1.4;

                @include i.ssp {
                    font-size: 20px;
                }
            }

            p {
                margin: 1.5rem 0;
                padding-bottom: 0;
                font-size: 16px;
                border: none;

                @include i.sp {
                    margin-bottom: 0;
                }
            }
        }
    }

    .box-pickup-tpt-c {
        border-color: i.$keycolor;

        @include i.sp {
            padding-left: 0;
            padding-right: 0;
            border: none;
        }

        h2 {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 2rem;
            line-height: 1.4;

            &:last-of-type {
                width: 100%;
            }
        }

        h3 {
            width: 90%;
            margin: .5rem 0 1rem;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.4;
            border-bottom: 2px dotted i.$keycolor;

            @include i.sp {

                width: 100%;
                font-size: 1.4em;
            }

            &::before {
                font-family: "icomoon";
                content: "\e926";
                margin-right: 10px;
                font-size: 16px;
                color: i.$keycolor-d;

                @include i.sp {
                    font-size: 14px;
                }
            }
        }

        .box-inner:first-of-type {
            margin-bottom: 2em;
        }

        p {
            width: 90%;

            @include i.pad {
                width: 100%;
            }
        }

        img {
            margin-top: 1em;
        }
    }
}

.box-movie {
    //display: block;
    width: 72%;
    margin: 0 auto;
    text-align: center;

    @include i.tab {
        width: 92%;
    }
}

.box-movie-topmes {
    margin: 5rem auto;

    @include i.pad {
        margin-top: 3rem auto;
    }
}

.box-top-message {
    width: 70%;
    margin: 1rem auto 7rem;

    @include i.sp {
        width: 100%;
        margin-bottom: 2rem;
    }

    span {
        display: block;
        margin-bottom: 2rem;
        padding-bottom: 12px;
        font-size: 1.8em;
        font-weight: 500;
        text-align: center;
        line-height: 1.4;
        border-bottom: 2px dotted i.$primary;

        @include i.sp {
            font-size: 24px;
        }
    }

    p {
        margin: 0 auto;

        @include i.pad {
            width: 100%;
        }
    }

    figure {
        display: block;
        margin: 3rem auto;
        text-align: center;

        @include i.sp {
            margin-top: 1.5rem;
        }
    }

    figcaption {
        margin-top: 8px;
    }

}

/* ------------------------------------------------------------ 料金 */

.sec-price-top {
    padding: 30px 0;
    background-color: i.$secondary-l-l;
    position: relative;

    .frame-sec-up,
    .frame-sec-bottom {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
    }

    .frame-sec-up {
        top: 0;
        left: auto;
    }

    .frame-sec-bottom {
        bottom: 0;
        left: auto;
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        font-size: 2em;
        font-weight: 500;

        &::before {
            font-family: "icomoon";
            content: "\e91c";
            margin-right: 10px;
            font-size: 26px;
            color: i.$keycolor-d;
        }
    }
}

.area-price-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include i.sp {
        flex-direction: column;
    }
}

.area-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-bottom: 5rem;
    border-radius: 10px;
    background-color: white;

    @include i.tab {
        margin-bottom: 3rem;
    }

    h3 {
        display: block;
        width: 100%;
        padding: 10px 0;
        font-size: 26px;
        text-align: center;
        color: white;
        background-color: i.$keycolor;
        border-radius: 10px 10px 0 0;
    }

    .box-price-price {
        display: block;
        width: 100%;
        padding: 0 20px;
        text-align: center;
        background-color: i.$keycolor-l-l;

        .spn-price {
            margin-right: 8px;
            font-size: 54px;
            font-weight: 500;
        }
    }

    .box-price-about {
        width: 31%;
        padding: 0 20px;

        @include i.pad {
            width: 100%;
            text-align: center;
        }

        span {
            display: block;
            padding: 16px 0;
            line-height: 1.4;
            border-bottom: 1px dotted i.$keycolor-d;
        }
    }

    img {
        margin: 0;

        @include i.pad {
            margin: 1rem auto;
            text-align: center;
        }
    }

    .box-info-outer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 1rem;

        @include i.pad {
            flex-direction: column;
        }
    }

    .box-price-outer {
        width: 100%;

        @include i.padpc {
            //width: 80%;
            margin: 0 auto;
        }

        @include i.sp {
            width: 100%;
        }

        .box-next,
        .box-ticket-outer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 0;
        }

        .box-next {
            border-top: 1px dotted i.$keycolor-d;
            border-bottom: 1px dotted i.$keycolor-d;
        }

        .box-ticket-outer {
            border-bottom: 1px dotted i.$keycolor-d;
        }

        .spn-md {
            width: auto;
            //display: block;
            font-size: 18px;
        }

        .box-outer-l {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            padding-right: 2rem;
            text-align: right;
        }

        .box-ticket-outer {
            .spn-md {
                padding-right: 2rem;
            }
        }

        .box-outer-r {
            width: 50%;
            padding-right: 2rem;
        }

        .spn-lg {
            font-size: 42px;
            margin-right: 1rem;
        }

        .box-tickets {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 50%;
        }

        .box-ticket {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            span:first-of-type {
                margin-right: 1rem;
                font-size: 18px;
            }

            span:last-of-type {
                margin-right: 1rem;
                font-size: 36px;
                line-height: 1.2;
            }
        }
    }

    span {
        font-size: 18px;
    }

    .box-btn {
        display: block;
        width: 100%;
        padding: 20px;
        background-color: i.$keycolor;
        border-radius: 0 0 10px 10px;
        text-align: center;

        a {
            padding: 8px 36px;
            background-color: white;
            text-align: center;
            text-decoration: none;
            color: inherit;
            border-radius: 20px;
            cursor: pointer;
            transition: all, 0.2s;

            &:hover {
                background-color: i.$secondary-l-l;
                filter: drop-shadow(4px 4px 6px rgba(i.$black, 0.2));
                transition: all, 0.2s;
            }
        }
    }
}

/* ------------------------------------------------------------ 採用ページ */

.sec-recruitment {

    .wrap-padding {
        padding-top: 0;
    }

    .box-sec-intro {
        width: 98%;
    }


    h1 {
        @include i.sp {
            font-size: 20px;
        }

        &::before,
        &::after {
            @include i.sp {
                font-size: 16px;
            }
        }
    }

    .h2-nowing {
        @include i.sp {
            font-size: 20px;
            text-align: left;
        }
    }

    p,
    table {
        font-size: 16px;
    }

    img {
        display: block;
        margin: 1rem auto;
    }

    .p-question {
        font-size: 1.1rem;
        font-weight: bold;
        color: i.$primary-d;
    }

    .p-separate {
        display: block;
        width: 100%;
        margin: 1rem auto 3rem;
        color: i.$primary;
        text-align: center;
    }
}

p.box-recruit-notice {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    //background-color: i.$primary;
    border: 3px dotted i.$red;
}

.btn-here {
    display: block;
    width: 50%;
    margin: 1rem auto;
    padding: 20px 40px;
    background-color: i.$keycolor-d;
    border: 1px solid i.$keycolor-d;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    transition: all, .1s;

    @include i.pad {
        width: 90%;
    }

    &:hover {
        background-color: i.$secondary-l-l;
        border: 1px solid i.$secondary-d-d;
        filter: drop-shadow(3px 3px 8px rgba(i.$black, 0.1));
        transition: all, .1s;
    }

}

.outer-movie-recruit {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.movie-recruit {
    width: 100%;
    height: 100%;
}

.tbl-youkou {
    tr {
        border-bottom: dotted 1px i.$primary;
    }

    tr:nth-of-type(1) {
        border-top: dotted 1px i.$primary;
    }

    tr:nth-of-type(2n+1) {
        background-color: white;
    }

    th {
        width: 180px;
        font-weight: normal;
        background-color: i.$primary-l-l;

        @include i.sp {
            width: auto;
        }
    }

    td {
        padding: 8px;
        line-height: 1.4;
    }
}

/* ------------------------------------------------------------ コンタクトボックス */
.sec-contactbox-front,
.sec-contactbox {
    padding: 30px 0 30px;
    position: relative;

    .frame-sec-up,
    .frame-sec-down {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
    }

    .frame-sec-up {
        top: 0;
        left: auto;
    }

    .frame-sec-down {
        bottom: inherit;
        left: auto;

        @include i.sp {
            bottom: 0;
        }
    }

    .frame-sec-down {
        transform: scale(1, -1);
    }

    .box-contact-yohaku {
        margin-left: auto;
        margin-right: auto;
        padding: 24px 48px;
        background-color: white;
        border-radius: 12px;

        @include i.sp {
            width: 94%;
            padding: 20px;
        }
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        padding-top: 1rem;
        font-family: "メイリオ";
        font-size: 1.8em;
        font-weight: 500;

        &::before {
            font-family: "icomoon";
            content: "\e905";
            margin-right: 10px;
            font-size: 26px;
            color: i.$keycolor-d;
        }
    }

    .box-contact-outer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;

        @include i.sp {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .box-contact-mail,
        .box-contact-tel {
            display: block;
            width: 40%;
            text-align: center;

            @include i.pad {
                width: 42%;
            }

            @include i.sp {
                width: 90%;
                margin-bottom: 2rem;
            }

            .spn-mid {
                font-size: 24px;
            }

            .btn-form {
                display: flex;
                justify-content: center;
                width: auto;
                height: 108px;
                padding: 12px;
                font-weight: bold;
                color: white;
                background-color: i.$keycolor-d;
                border: 2px solid i.$keycolor-d;
                border-radius: 12px;
                transition: all, 0.2s;
                cursor: pointer;

                @include i.sp {
                    height: 80px;
                }

                &:hover {
                    color: i.$keycolor-d;
                    background-color: white;
                    transition: all, 0.2s;
                    filter: drop-shadow(4px 4px 6px rgba(i.$black, 0.2));

                    span::before {
                        color: i.$keycolor-d;
                    }
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 32px;

                    @include i.sp {
                        font-size: 26px;
                    }

                    &::before {
                        content: "\e916";
                        font-family: "icomoon";
                        margin-right: 6px;
                        font-size: 46px;
                        color: white;
                    }
                }
            }

            .spn-sm-r {
                display: block;
                margin: 1rem auto 0;
                font-size: 18px;
                color: i.$red;

                @include i.tab {
                    font-size: 16px;
                }
            }

            .spn-sm {
                display: block;
                margin: 0 auto 1rem;
                font-size: 14px;

                @include i.sp {
                    margin-bottom: 0;
                }
            }

            a {
                font-size: 16px;
                text-decoration: none;

                @include i.sp {
                    font-size: 14px;
                }
            }
        }

        .box-contact-tel {
            .btn-form {
                span {
                    &::before {
                        content: "\e918";
                        font-size: 30px;
                    }
                }
            }

            table {
                width: auto;
                margin: 0 auto;
            }

            table tr:nth-of-type(2n + 1),
            th {
                background: none;
            }

            th {
                padding: 0;
                text-align: right;
                font-weight: normal;
            }

            td {
                padding: 0;
                text-align: left;
            }
        }
    }
}

.sec-contactbox {
    background-color: i.$primary;

    .wrap {
        //background-color: white;
        //border-radius: 12px;
    }

    h2 {
        margin-bottom: 1rem;
    }

    .box-contact-outer {
        padding-top: 24px;
    }
}

/* ------------------------------------------------------------ お客さまの声 */

.sec-voice-top {
    padding: 30px 0 30px;
    background-color: i.$secondary-l-l;
    position: relative;

    .frame-sec-up,
    .frame-sec-down {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
    }

    .frame-sec-up {
        top: 0;
        left: auto;
    }

    .frame-sec-down {
        bottom: 0;
        left: auto;
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        font-size: 28px;
        font-weight: 500;

        @include i.sp {
            margin-bottom: 2rem;
        }

        &::before {
            font-family: "icomoon";
            content: "\e91e";
            margin-right: 10px;
            font-size: 30px;
            color: i.$primary;
        }
    }

    .area-voice-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include i.sp {
            justify-content: space-around;
            margin: 0 8px;
        }

        .box-voice {
            display: flex;
            justify-content: flex-start;
            width: 49%;
            padding: 16px;
            margin-bottom: 2rem;
            background-color: white;
            border-radius: 10px;

            @include i.pad {
                width: 100%;
                margin-bottom: 1rem;
            }

            @include i.ssp {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .box-voiceinfo-outer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }

        .box-voice-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            font-size: 1.2rem;
            line-height: 1.4;

            @include i.sp {
                font-size: 16px;
            }

            &::before {
                font-family: "icomoon";
                content: "\e91e";
                margin-right: 6px;
                font-size: 16px;
            }
        }

        img {
            width: 100px;
            height: 100%;
            margin-right: 1rem;
        }

        .box-voice-info {
            text-align: right;

            @include i.sp {
                font-size: 14px;
            }
        }

        .box-btn {
            display: block;
            width: 100%;
            text-align: right;
        }

        a {
            text-decoration: none;
            color: i.$gray-800;
            border: 1px solid white;
            transition: all, 0.2s;

            &:hover {
                background-color: i.$secondary-l-l;
                border: 1px solid i.$primary;
                filter: drop-shadow(4px 4px 4px rgba(i.$black, 0.2));
                transition: all, 0.2s;
            }
        }

        .a-toptovoice {
            border: none;
            border-radius: 0;
            font-size: 1rem;
        }
    }

    .area-btn-link {
        margin-top: 2rem;
    }
}

/* ------------------------------ お客様の声　カスタム記事 */

.single-voices {
    .voice-info {
        margin-bottom: 2rem;
    }

    p {
        margin: 12px 0 3rem;
    }

    .spn-mds {
        display: block;
        width: auto;
        padding-bottom: 4px;
        font-size: 1rem;
        border-bottom: 1px solid i.$primary-l;
    }
}

/* ------------------------------------------------------------------------------------------ 個別ページ */

/* ------------------------------------------------------------ 共通 */

.icon-logo,
.icon-bed-big,
.icon-logo-light,
.icon-logo-sheet,
.icon-logo-sheet-r,
.icon-logo-q,
.icon-logo-voice {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 64px;

    @include i.sp {
        font-size: 48px;
    }
}

.icon-logo:before {
    content: "\e924";
    color: i.$primary;
    font-size: 72px;
}

.icon-bed-big:before {
    content: "\e901";
    color: i.$primary;
}

.icon-logo-light:before {
    content: "\e910";
    color: i.$primary;
}

.icon-logo-sheet:before {
    content: "\e91d";
    color: i.$primary;
}

.icon-logo-sheet-r:before {
    content: "\e91d";
    color: i.$keycolor;
}

.icon-logo-q:before {
    content: "\e919";
    color: i.$keycolor;
}

.icon-logo-voice:before {
    content: "\e91e";
    color: i.$primary;
}

.sec-about,
.sec-introduction,
.sec-price {
    @include i.sp {
        margin-top: 2rem;
    }
}

.box-sec-top,
.box-sec-intro {
    width: 70%;
    margin-right: auto;
    margin-left: auto;

    @include i.pad {
        width: 90%;
    }

    h1,
    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.6em;
        font-weight: 500;
        margin-bottom: 3rem;
        line-height: 1.6;

        @include i.sp {
            font-size: 22px;
            text-align: center;
            line-height: 1.5;
        }

        @include i.ssp {
            font-size: 20px;
        }

        &::before,
        &::after {
            font-family: 'icomoon';
            content: '\e926';
            font-size: 22px;
            color: i.$primary;

            @include i.sp {
                font-size: 20px;
            }
        }

        &::before {
            margin-right: 18px;

            @include i.sp {
                margin-right: 14px;
            }
        }

        &::after {
            margin-left: 18px;
            transform: scale(-1, 1);

            @include i.sp {
                margin-left: 14px;
            }
        }
    }

    .h1-r,
    .h2-r {

        &::before,
        &::after {
            color: i.$keycolor;
        }

    }

    .h2-nowing {
        justify-content: flex-start;
        margin: 3rem 0 2rem;
        border-bottom: 2px dotted #ff662e;
        font-size: 1.3rem;
        font-weight: 500;

        @include i.ssp {
            font-size: 20px;
        }

        &::before {
            margin-right: 12px;
            color: #ff662e;
            font-family: icomoon;
            font-size: 22px;
            content: '\e907';
        }

        &::after {
            content: none;
        }
    }

    .h2-about-omoi {
        margin-top: 6rem;
    }

    .h3-about-omoi {
        justify-content: flex-start;
        margin: 3rem 0 2rem;
        border-bottom: 2px dotted #ff662e;
        font-size: 1.5rem;
        font-weight: 500;

        &::before {
            margin-right: 12px;
            color: #ff662e;
            font-family: icomoon;
            font-size: 22px;
            content: '\e91f';
        }

        @include i.sp {
            font-size: 20px;
        }
    }

    p {
        line-height: 1.6;
    }

    .img-intro-intro {
        display: block;
        margin: 1rem auto;
    }

    .img-about-omoi {
        display: block;
        margin: 3rem auto 1rem;
        border-radius: 10px;
    }
}

.box-sec-top {
    width: 100%;

    @include i.tab {
        margin-top: 48px;
    }

    @include i.sp {
        h1 {

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .icon-logo {
        @include i.sp {
            margin-top: 1rem;
        }
    }
}

.spn-strong-fjs {
    color: #ff662e;
    font-size: 1.1rem;
    font-weight: 700;
}

/* ------------------------------------------------------------ 店舗紹介 */

.area-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-sec-main {
    width: 92%;
    margin-bottom: 2rem;
}

.box-item-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin: 60px 0 80px;

    @include i.sp {
        flex-direction: column;
        margin-bottom: 2rem;
    }
}

.box-item {
    width: 30%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url(../img/parts/balloon-p.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include i.pad {
        width: 30%;
    }

    @include i.sp {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    span {
        font-size: 32px;
        line-height: 1.4;
        position: relative;
        z-index: 30;
    }

    p {
        position: relative;
        z-index: 30;
    }
}

.box-kuchikomi {
    display: block;
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    font-size: 32px;
    border: 1px solid i.$primary;
}

/* ------------------------------ スタッフ */

.sec-staff {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px dotted i.$primary;
}

.area-staff {
    width: 100%;
    margin-bottom: 3rem;
    padding-bottom: 3rem;

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include i.pad {
        padding-bottom: 1.5rem;
    }

    @include i.tab {
        width: 92%;
        margin-bottom: 2rem;
    }

    .spn-mds-about {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 4px 4px 4px 12px;
        margin-bottom: 2rem;
        font-size: 24px;
        font-weight: 500;
        color: white;
        background-color: i.$primary-l;
        border-radius: 30px;

        &::before {
            font-family: 'icomoon';
            content: '\e915';
            margin-right: 6px;
            font-size: 24px;
            color: white;
            transform: scale(-1, 1);
        }
    }

    .spn-mds-pro {
        color: i.$gray-800;
        background-color: i.$keycolor-l;

        &::before {
            color: i.$gray-800;
        }
    }

    .box-staff {
        margin-bottom: 60px;
        padding-bottom: 60px;
        border-bottom: 12px dotted i.$primary-l;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
        }

        @include i.tab {
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 6px dotted i.$primary-l;
        }
    }

    .btn-link-mystory {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        border: 1px solid #ff662e;
        border-radius: 10px;

        .icon2-more-arrow {
            margin-bottom: 0 !important;
            font-size: 1rem !important;
        }
    }

    .box-staff-up {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @include i.sp {
            flex-direction: column;
        }

        .staff-image {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 40%;
            border: 1px solid i.$primary;
            border-radius: 12px;

            @include i.sp {
                width: 100%;
                margin-bottom: 2rem;
            }

            img {
                border-radius: 12px 12px 0 0;
            }

            .box-staff-info {
                padding: 12px;
                width: 100%;
                text-align: center;

                span {
                    display: block;
                    width: 100%;
                }

                .spn-work {
                    font-size: 14px;
                }

                .spn-name {
                    font-size: 22px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &::before,
                    &::after {
                        flex-grow: 1;
                        content: "";
                        height: 1px;
                        background-color: i.$primary-l;
                    }

                    &::before {
                        margin-right: 1rem;
                    }

                    &::after {
                        margin-left: 1rem;
                    }
                }

                .spn-name-sub {
                    font-size: 12px;
                }
            }
        }

        .staff-speech {
            display: block;
            width: 55%;

            @include i.sp {
                width: 100%;
            }

            span {
                display: block;
                font-size: 28px;
                line-height: 1.4;
                color: i.$primary-d-d;
                margin-bottom: 3rem;

                @include i.pad {
                    margin-bottom: 1.5rem;
                    font-size: 24px;
                }
            }

            p {
                padding-right: 2rem;
                line-height: 1.6;

                @include i.sp {
                    padding-right: 0;
                }
            }
        }
    }

    .box-staff-low {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid i.$primary;
        border-radius: 10px;
        margin-top: 2rem;
        padding: 16px 40px;

        @include i.sp {
            flex-direction: column;
            margin-top: 0;
            padding: 16px;
        }

        span {
            font-size: 18px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;
            border-bottom: 1px dotted i.$primary;

            @include i.sp {
                font-size: 20px;
                line-height: 1.5;
            }

            &::before {
                font-family: 'icomoon';
                content: '\e915';
                margin-right: 6px;
                font-size: 24px;
                color: i.$primary;
                transform: scale(-1, 1);
            }
        }

        .box-l {
            width: 48%;

            @include i.sp {
                width: 100%;
            }

            p,
            li {
                font-size: .9rem;
            }
        }

        .box-r {
            width: 48%;

            @include i.sp {
                width: 100%;
            }

            p {
                font-size: .9rem;
            }

            li {
                list-style: none;
                font-size: .9rem;
            }
        }

        table {
            tr:nth-of-type(2n+1) {
                background: none;
            }

            td {
                font-size: .9rem;
            }
        }
    }

    .box-staffs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include i.sp {
            flex-direction: column;
        }

        .staff-image {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 32%;
            border: 1px solid i.$primary;
            border-radius: 12px;

            @include i.pad {
                width: 49%;
                margin-bottom: 1.2rem;
            }

            @include i.sp {
                width: 100%;
            }

            img {
                border-radius: 12px 12px 0 0;
            }

            .box-staff-info {
                padding: 12px;
                width: 100%;
                text-align: center;

                span {
                    display: block;
                    width: 100%;
                }

                .spn-work {
                    font-size: 14px;
                }

                .spn-name {
                    font-size: 22px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &::before,
                    &::after {
                        flex-grow: 1;
                        content: "";
                        height: 1px;
                        background-color: i.$primary-l;
                    }

                    &::before {
                        margin-right: 1rem;
                    }

                    &::after {
                        margin-left: 1rem;
                    }
                }

                .spn-name-sub {
                    font-size: 12px;
                }

                p {
                    padding: 0 20px;
                    font-size: 16px;
                    text-align: left;
                    line-height: 1.5;

                    @include i.sp {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .box-pro {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        @include i.sp {
            flex-direction: column-reverse;
        }

        .box-pro-img {
            display: block;
            width: 140px;
            height: 140px;

            img {
                border-radius: 10px;
                object-fit: cover;
            }
        }

        .box-pro-voice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            padding: 1rem;
            border: 1px solid i.$keycolor;
            border-radius: 10px;
            position: relative;

            @include i.pad {
                flex-direction: column;
                width: 74%;
            }

            @include i.sp {
                width: 100%;
                margin-bottom: 2rem;
            }

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                left: -24px;
                top: 25px;
                border-right: 24px solid i.$keycolor;
                border-top: 24px solid transparent;
                border-bottom: 24px solid transparent;

                @include i.sp {
                    left: 50px;
                    top: inherit;
                    bottom: -50px;
                    border-right: 24px solid transparent;
                    border-left: 24px solid transparent;
                    border-top: 24px solid i.$keycolor;
                }
            }

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                left: -22px;
                top: 25px;
                border-right: 24px solid white;
                border-top: 24px solid transparent;
                border-bottom: 24px solid transparent;

                @include i.sp {
                    left: 50px;
                    top: inherit;
                    bottom: -48px;
                    border-right: 24px solid transparent;
                    border-left: 24px solid transparent;
                    border-top: 24px solid white;
                }
            }
        }

        .box-l {
            width: 70%;
            text-align: right;

            @include i.pad {
                width: 100%;
            }
        }

        .spn-lg {
            display: block;
            text-align: left;
            font-size: 28px;
            line-height: 1.4;
            margin-bottom: 10px;

            @include i.pad {
                font-size: 24px;
            }
        }

        .spn-sm {
            display: block;
            font-size: 16px;
        }

        a {
            display: block;
            padding: 4px 16px;
            background-color: i.$keycolor-l-l;
            border: 1px solid i.$keycolor;
            border-radius: 20px;
            text-decoration: none;
            color: i.$gray-800;
            transition: all, .2s;

            @include i.pad {
                margin-top: 1rem;
            }

            &:hover {
                color: i.$red;
                filter: drop-shadow(4px 4px 5px rgba(i.$black, 0.2));
                transition: all, .2s;
            }
        }
    }
}

/* ------------------------------------------------------------ 設備 */

.sec-setsubi {
    padding-top: 3rem;
    margin: 3rem auto 5rem;
    border-top: 1px dotted i.$primary;

    @include i.sp {
        width: 92%;
    }

    h2 {
        @include i.sp {
            font-size: 22px;
        }
    }
}

.box-setsubi-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @include i.sp {
        flex-direction: column;
    }

    .box-setsubi {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 32%;

        @include i.sp {
            width: 100%;
        }
    }

    img {
        //width: 400px;
        border-radius: 10px;
    }

    span {
        font-size: 18px;
    }

    p {
        margin: .3rem 0 1.5rem;
        font-size: 14px;
    }
}

/* ------------------------------------------------------------------------------------------ 初めての方へ */

.box-somosomo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    padding: 20px 40px;
    border: 1px solid #cc3600;
    border-radius: 12px;

    @include i.sp {
        flex-direction: column;
        padding: 12px 10px;
        margin-bottom: 0;
    }

    .box-l {
        width: 100%;
        margin: 0 auto;

        @include i.sp {
            width: 92%;
        }
    }

    span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: i.$primary-d-d;

        @include i.sp {
            font-size: 26px;
        }

        @include i.ssp {
            font-size: 22px;
        }

        &::before {
            font-family: 'icomoon';
            content: '\e927';
            margin-right: 6px;
            font-size: 32px;
            color: i.$primary;
        }
    }

    p {
        width: 80%;
        margin: 1rem auto;

        @include i.pad {
            width: 90%;
        }

        @include i.sp {
            width: 100%;
            line-height: 1.6;
        }
    }

    img {
        display: block;
        margin-top: 1rem;
        border-radius: 12px;
    }
}

/* ------------------------------------------------------------ メリット */

.box-more-merit {
    padding: 5rem 0 3rem;
    margin: 3rem auto;
    border-top: 1px dotted i.$primary;

    @include i.sp {
        padding-top: 3rem;
        margin-top: 2rem;
    }

    h3 {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 2rem;
        font-size: 24px;
        font-weight: 500;

        @include i.sp {
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            font-size: 24px
        }

        &::before {
            font-family: 'icomoon';
            content: '\e910';
            margin-right: 6px;
            font-size: 40px;
            color: i.$primary;
        }

        .spn-num-lg {
            font-size: 60px;
            font-style: italic;
            color: i.$primary;
            margin: 0 4px;
            line-height: 1;
        }
    }

    .box-mmerit-outer {
        width: 100%;
        margin-top: 5rem;
        padding: 30px 48px;
        margin-bottom: 5rem;
        border: 1px solid i.$primary;
        border-radius: 12px;
        position: relative;

        @include i.tab {
            width: 92%;
            margin: 5rem auto 0;
            padding: 30px 1rem;
        }

        /* 
        .spn-num {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            font-size: 72px;
            font-style: italic;
            text-align: left;
            color: white;
            background-color: i.$primary;
            border-radius: 50%;
            position: absolute;
            top: -40px;
            left: -40px;
            
            @include i.tab {
                width: 50px;
                height: 50px;
                font-size: 42px;
                top: -25px;
                left: -8px;
            }
        }
        */
        .num-merit {
            display: block;
            text-align: center;
            width: 80px;
            height: 80px;
            position: absolute;
            top: -40px;
            left: -40px;

            @include i.sp {
                width: 52px;
                height: 52px;
                top: -26px;
                left: -10px;
            }
        }

        .spn-title {
            display: block;
            width: auto;
            padding: 2px 12px;
            font-size: 28px;
            font-weight: 500;
            background-color: white;
            position: absolute;
            top: -24px;
            left: 56px;
            line-height: 1.3;

            @include i.pad {
                font-size: 24px;
            }

            @include i.tab {
                top: -20px;
            }

            @include i.sp {
                font-size: 20px;
                line-height: 1.4;
            }
        }

        .box-mmerit,
        .box-mmerit-r {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            @include i.pad {
                flex-direction: column;
            }

            .box-l {
                width: 56%;
                padding-right: 3rem;

                @include i.pad {
                    width: 100%;
                    padding: 0;
                }

                @include i.tab {
                    width: 90%;
                    padding-right: 0;
                }

                @include i.ssp {
                    width: 100%;
                }
            }

            .p-topmargin-large {
                @include i.ssp {
                    margin-top: 42px;
                }
            }

            img {
                display: block;
                width: 42%;
                border-radius: 8px;

                @include i.pad {
                    width: 80%;
                }

                @include i.sp {
                    width: 100%;
                }
            }
        }

        .box-mmerit-r {
            flex-direction: row-reverse;

            @include i.tab {
                flex-direction: column;
            }

            .box-l {
                padding-left: 3rem;

                @include i.tab {
                    padding-left: 0;
                }
            }
        }
    }

    .p-92 {
        width: 70%;
        margin-right: auto;
        margin-left: auto;

        @include i.sp {
            width: 80%;
        }
    }
}

/* ------------------------------------------------------------ 流れ */

.sec-flow {
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-color: i.$tertiary-d-d;

    @include i.sp {
        padding-top: 3rem;
    }
}

.box-flow-outer {
    margin-top: 3rem;

    @include i.pad {
        width: 92%;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }

    .box-flow,
    .box-flow-r {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 3rem;

        @include i.sp {
            flex-direction: column;
            margin-bottom: 1.5rem;
        }

        img {
            display: block;
            width: 32%;
            border-radius: 8px;

            @include i.sp {
                width: 100%;
            }
        }

        .box-flow-text {
            width: 44%;
            margin-left: 2rem;

            @include i.sp {
                width: 100%;
                margin: 1rem 0 0;
            }

            .spn-num {
                margin-right: 1rem;
                font-size: 72px;
                font-weight: 500;
                font-style: italic;
                color: i.$primary;
                line-height: 1;
            }

            .spn-title {
                font-size: 28px;
                font-weight: 500;
            }
        }
    }

    .box-flow-r {
        justify-content: flex-end;
    }
}

/* ------------------------------------------------------------ よくある質問 */

.sec-qa {
    padding-top: 2rem;
    //padding-bottom: 4rem;
    background-color: i.$keycolor-l-l;
}

.box-pa-label {
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 4px 12px;
    text-align: center;
    font-size: 18px;
    background-color: white;
    border-top: 1px solid i.$keycolor-d;
    border-left: 1px solid i.$keycolor-d;
    border-right: 1px solid i.$keycolor-d;
    border-radius: 10px 10px 0 0;
}

.box-qa-outer {
    display: block;
    width: 100%;
    margin: 0 auto 5rem;
    padding: 20px 40px;
    background-color: white;
    border: 1px solid i.$keycolor-d;
    border-radius: 10px;

    @include i.tab {
        width: 92%;
        padding: 20px;
    }

    .box-qa {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px dotted i.$keycolor-d;

        .box-up {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;

            .box-q {
                display: block;
                width: 32px;
                margin-right: 12px;
                font-size: 26px;
                color: i.$keycolor-d;
            }

            .box-q-title {
                display: block;
                font-size: 20px;
                font-weight: 500;
            }
        }

        .box-down {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .box-a {
                display: block;
                width: 40px;
                margin-right: 12px;
                font-size: 26px;
                color: i.$gray-700;
            }

            .box-q-answer {
                display: block;
                font-size: 16px;
                line-height: 1.5;
            }
        }
    }
}

/* ------------------------------------------------------------------------------------------ サービスと料金 */

.area-price-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 40px;
    margin-bottom: 3rem;
    background-color: i.$keycolor;
    border-radius: 10px;

    @include i.pad {
        padding: 20px;
    }

    @include i.tab {
        flex-direction: column;
        width: 92%;
        margin: 0 auto 2rem;
    }

    .box-l {
        width: 66%;
        padding: 20px 40px;
        background-color: white;

        @include i.pc {
            width: 60%;
            padding: 20px;
        }

        @include i.tab {
            width: 100%;
        }

        h3 {
            display: block;
            width: 100%;
            font-size: 32px;
            text-align: center;
            margin-bottom: 1rem;
            border-bottom: 1px dotted i.$keycolor-d-d;
        }

        .spn-title {
            width: 90%;
            margin: 0 auto;
            font-size: 22px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @include i.pad {
                width: 100%;
            }

            @include i.sp {
                font-size: 20px;
                line-height: 1.5;
            }

            &::before {
                font-family: 'icomoon';
                content: '\e915';
                margin-right: 6px;
                font-size: 22px;
                color: i.$keycolor;
                //transform: scale(-1, 1);
            }
        }

        p {
            width: 80%;
            margin: 8px auto 2rem;
            font-size: 1rem;
            line-height: 1.7;

            @include i.pad {
                width: 90%;
            }

            @include i.sp {
                width: 100%;
            }
        }

        img {
            display: block;
            width: 80%;
            margin: 0 auto 2rem;
            border-radius: 8px;

            @include i.tab {
                width: 100%;
            }

            &:first-of-type {
                margin-top: 3rem;
            }
        }
    }

    .box-r {
        width: 30%;

        @include i.pc {
            width: 38%;
        }

        @include i.tab {
            width: 100%;
        }

        .box-price {
            width: 100%;
            border-radius: 0;
            margin-bottom: 2rem;
            text-align: center;
            background-color: white;

            @include i.tab {
                margin: 1rem auto;
            }

            .box-price-price {
                //text-align: center;
            }

            .spn-price {
                font-size: 3rem;
                margin-right: 8px;
            }

            .spn-md {
                margin-right: 1rem;
                font-size: 18px;
            }

            .spn-lg {
                margin-right: 8px;
                font-size: 2.5rem;
            }

            .box-ticket-outer-outer {
                padding-top: 1rem;
                border-top: 1px dotted i.$gray-600;
            }

            .box-ticket-outer-outer .box-ticket span:first-of-type {
                margin-right: 12px;
            }

            .box-ticket-outer-outer .box-ticket span:last-of-type {
                margin-right: 8px;
                font-size: 2.5rem;
                //margin-right: 4px;
            }
        }

        .box-add {
            display: block;
            width: 100%;
            padding: 20px;
            background-color: white;

            .spn-title {
                font-size: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                @include i.sp {
                    font-size: 20px;
                    line-height: 1.5;
                }

                &::before {
                    font-family: 'icomoon';
                    content: '\e915';
                    margin-right: 6px;
                    font-size: 22px;
                    color: i.$keycolor;
                    //transform: scale(-1, 1);
                }
            }

            p {
                margin-top: 8px;
                margin-bottom: 2rem;
                font-size: 1rem;
                line-height: 1.7;
            }
        }

        .box-add-uppeer {
            margin-bottom: 3rem;
            display: block;
            width: 100%;
            padding: 20px;
            background-color: #fff;

            .spn-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 1rem;
                font-size: 22px;

                &::before {
                    margin-right: 6px;
                    color: #73ba9b;
                    font-family: icomoon;
                    font-size: 22px;
                    content: '\e915';
                }
            }

            p {
                margin-top: 8px;
                font-size: 1rem;
                line-height: 1.5;
            }

            .spn-sm {
                display: block;
                margin-top: 8px;
                font-size: .8rem;
                line-height: 1.4;
            }

            table {
                tr:nth-of-type(2n+1) {
                    background: 0 0;
                }

                tr {
                    border-bottom: 1px dotted #ff662e;
                }

                td {
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
    }
}

//症状別ページ　カスタマイズ
.box-page-treatment {

    h2 {
        margin-top: 3rem;

        @include i.tab {
            font-size: 28px;
        }

        @include i.sp {
            margin-bottom: 2rem;
        }
    }

    h3 {
        border-bottom: 2px dotted #ff662e;

        &::before {
            margin-right: 12px;
            color: #ff662e;
            font-family: icomoon;
            font-size: 22px;
            content: '\e907';
        }

        &:first-of-type {
            margin-top: 3rem;

            @include i.sp {
                margin-top: 2rem;
            }
        }
    }
}

/* ------------------------------------------------------------------------------------------ 症例（case） */

.sec-case-page,
.sec-voice-page {

    .box-sec-intro {
        width: 100%;

        @include i.tab {
            width: 80%;
            margin-top: 2rem;
        }

        @include i.sp {
            width: 92%;
        }
    }
}

/* ------------------------------------------------------------ ヒーロー */

.area-hero-case-head-shoulder-neck {
    //background-image: url(../img/photo/hero-head-shoulder-neck.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-head-shoulder-neck_s.jpg);
    }
}

.area-hero-case-waist-back {
    //background-image: url(../img/photo/hero-waist-back.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-waist-back_s.jpg);
    }
}

.area-hero-case-arm {
    //background-image: url(../img/photo/hero-arm.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-arm_s.jpg);
    }
}

.area-hero-case-leg {
    //background-image: url(../img/photo/hero-leg.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-leg_s.jpg);
    }
}

.area-hero-case-fatigue {
    //background-image: url(../img/photo/hero-fatigue.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-fatigue_s.jpg);
    }
}

.area-hero-case-pelvis {
    //background-image: url(../img/photo/hero-pelvis.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-pelvis_s.jpg);
    }
}

.area-hero-case-stretch {
    //background-image: url(../img/photo/hero-stretch.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-stretch_s.jpg);
    }
}

.area-hero-case-others {
    //background-image: url(../img/photo/hero-others.jpg);

    @include i.sp {
        //background-image: url(../img/photo/hero-others_s.jpg);
    }
}

.area-hero-customer-voice {
    background-image: url(../img/photo/hero-voice.jpg);

    @include i.sp {
        background-image: url(../img/photo/hero-voice_s.jpg);
    }
}

/* ------------------------------------------------------------ タイトルアイコン */

.icon-shoulder-neck,
.icon-waist-back,
.icon-arm,
.icon-leg,
.icon-fatigue,
.icon-pelvis,
.icon-stretch,
.icon-others {
    display: block;
    width: 80px;
    margin: 0 auto 1rem;

    @include i.sp {}
}

/* ------------------------------------------------------------ 症例ページ */

.box-case-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5rem;

    @include i.tab {
        flex-direction: column;
        justify-content: center;
        margin-top: 3rem;
    }

    span {
        display: block;
        font-weight: 500;
        line-height: 1.4;
        color: i.$primary-d-d;
    }

    p {
        line-height: 1.6;
    }

    .box-case-l {
        width: 52%;
        padding-right: 2rem;

        @include i.tab {
            margin-bottom: 2rem;
            width: 100%;
            padding-right: 0;
        }

        span {
            font-size: 28px;
            margin-bottom: 3rem;
        }
    }

    .box-case-area {

        span {
            font-size: 24px;
            margin: 2rem 0;
            padding-top: 2rem;
            border-top: 1px dotted i.$primary;
        }
    }

    .box-case-r {
        width: 46%;
        text-align: center;

        @include i.tab {
            width: 100%;
        }
    }

    img {
        border-radius: 10px;
        margin-bottom: 4rem;

        @include i.tab {
            width: 80%;
            margin: 0 auto 2rem;
        }

        @include i.sp {
            width: 100%;
        }
    }
}

.sec-case-example {
    background-color: i.$primary-l-l;

    .box-select-voice {
        width: 300px;
        margin-bottom: 3rem;
    }
}

.h3-example-cases,
.h3-example-symptoms,
.h3-example-treatment {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 24px;
    margin-bottom: 1rem;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    position: relative;

    &::before,
    &::after {
        flex-grow: 1;
        content: "";
        height: 8px;
        background-color: white;
        border-radius: 12px;
    }

    &::before {
        margin-right: 1.5rem;
    }

    &::after {
        margin-left: 1.5rem;
    }
}

.h3-example-cases {

    &::before,
    &::after {
        background-color: i.$cases;
    }
}

.h3-example-symptoms {

    &::before,
    &::after {
        background-color: i.$symptoms;
    }
}

.h3-example-treatment {

    &::before,
    &::after {
        background-color: i.$treatment;
    }
}

.box-example-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 92%;
    margin-bottom: 1rem;

    @include i.sp {
        flex-direction: column;
    }

    .box-example-cases,
    .box-example-symptoms,
    .box-example-treatment {
        width: 49%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px;
        margin-bottom: 1.5rem;
        text-decoration: none;
        color: inherit;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.4;
        background-color: white;
        border: 2px solid white;
        border-radius: 8px;
        transition: all, .2s;

        @include i.tab {
            margin-bottom: 1rem;
        }

        @include i.sp {
            width: 100%;
            padding: 8px;
        }

        &:hover {
            color: i.$primary-d;
            background-color: white;
            border: 2px solid i.$primary;
            filter: drop-shadow(6px 6px 6px rgba(i.$black, 0.1));
            transition: all, .2s;
        }
    }

    .box-example-cases {
        border-color: i.$cases;
        background-color: i.$cases-l;
    }

    .box-example-symptoms {
        border-color: i.$symptoms;
        background-color: i.$symptoms-l;
    }

    .box-example-treatment {
        border-color: i.$treatment;
        background-color: i.$treatment-l;
    }

    img {
        width: 80px;
        height: 80px;
        margin-right: 1rem;
    }
}

/* ------------------------------------------------------------ トップページ　ピックアップ症例 */

.h2-select-treatment {
    @include i.sp {
        margin-top: 2rem !important;
    }
}

.box-select-treatment {
    width: 92%;
    margin: 2rem auto 1rem;

    @include i.pad {
        width: 100%;
    }

    a {
        background-color: white !important;
        border: none;
    }

    img {
        width: 120px;
        height: 120px;
    }

    span {
        display: block;
        width: 100%;
        padding-bottom: 8px;
        text-align: left !important;
        font-size: 1.2rem;
        border-bottom: 1px dotted i.$gray-600;
    }

    p {
        margin-top: 8px;
        margin-bottom: 0;
        text-align: left !important;
        font-size: 1rem;
    }
}

/* ------------------------------------------------------------ お客さまの声ページ */

.spn-voice-name {
    display: block;
    margin-bottom: 1rem;
}

.box-select-voice {
    display: block;
    width: 50%;
    margin: 2rem auto;

    @include i.tab {
        width: 70%;
    }

    @include i.sp {
        width: 100%;
    }

    a {
        display: block;
        width: auto;
        margin-bottom: .5rem;
        text-decoration: none;
        color: inherit;

        @include i.tab {
            margin-bottom: 1rem;
        }

        &:hover {
            color: i.$red;
        }

        &::before {
            font-family: 'icomoon';
            content: '\e925';
            margin-right: 6px;
            font-size: 16px;
            color: i.$primary;
        }
    }
}

.card-voice-outer {
    display: block;
    width: 92%;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 2rem;
    border: 1px solid i.$gray-400;
    border-radius: 10px;

    @include i.pc {
        width: 100%;
    }

    @include i.sp {
        padding: 20px;
    }

    span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;

        &::before {
            font-family: 'icomoon';
            content: '\e925';
            margin-right: 6px;
            font-size: 22px;
            color: i.$primary;
        }
    }

    .card-voice-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        @include i.sp {
            flex-direction: column;
        }
    }

    .box-l {
        width: 33%;
        text-align: center;

        @include i.sp {
            width: 100%;
            margin-bottom: 1.5rem;
        }

        .voice-info {
            margin-top: 1rem;
        }
    }

    .box-r {
        width: 63%;

        @include i.sp {
            width: 100%;
        }

        .spn-mds {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: bold;

            &::before {
                content: none;
            }
        }

        p {
            margin-top: .7rem;
            margin-bottom: 2rem;
            line-height: 1.4;
        }

        .spn-mds-last {
            padding-top: 2rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: bold;
            border-top: 1px dotted i.$primary;

            &::before {
                content: none;
            }
        }
    }
}

/* ------------------------------------------------------------ ニュースブログ */

/* ------------------------------------------------------------------------------------------ 予約ページ */

.header-container-res {
    border-bottom: 1px solid i.$gray-600;
}

.res-header {
    display: block;
    width: 100%;
    padding: 30px;
    margin: 0 auto;
    text-align: center;

    @include i.pad {
        padding: 20px 10px;
    }

    img {
        height: 60px;
        margin: 0 2rem;

        @include i.pad {
            height: 40px;
            margin: 0 1rem 0;
        }

        @include i.sp {
            margin: 0 1rem 1rem;
        }
    }
}



/* ------------------------------------------------------------------------------------------ シングルページ */

/* ------------------------------------------------------------ ブログ一覧（is_home） */

.entry-card-title {
    margin-bottom: 1rem;
}

.a-wrap {
    border-bottom: 1px dotted i.$primary;
}

.a-wrap:hover {
    background-color: i.$primary-l-l;
    transition: all, 0.2s;
}

.fa-folder-open {
    &::before {
        color: i.$primary-d;
    }
}

/* ------------------------------------------------------------ シングルページ */

.content-single-cmz {
    padding-top: 4rem;
    background-color: i.$secondary-l-l;

    @include i.pc {
        padding: 2rem 1rem 0;
    }

    @include i.sp {
        padding: 1rem 0 2rem;
    }

    .wrap-padding {
        padding: 10px;
    }

    .main {
        //width: 900px;
        padding: 40px 60px;

        @include i.pc {
            width: 67%;
            padding: 2rem;
        }

        @include i.pad {
            width: 92%;
            margin: 0 auto;
            padding: 1.5rem;
        }

        @include i.sp {
            width: 100%;
            padding: 1rem;
        }
    }

    .article h1 {
        justify-content: flex-start;
        font-size: 32px;
        font-weight: 500;

        &::before,
        &::after {
            content: none;
        }

        @include i.sp {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .article h2 {
        margin-top: 3rem;
        border-bottom: 2px dotted #ffa585;

        &::before {
            margin-right: 12px;
            color: #ffa585;
            font-family: icomoon;
            font-size: 16px;
            content: "\e926";

            @include i.sp {
                font-size: 10px;
            }
        }
    }

    .entry-content {
        margin-top: 4rem;
    }

    .entry-categories-tags {
        text-align: right;
    }

    .cat-link {
        color: i.$gray-700;
        background: none;
    }

    .pager-post-navi a figure {
        display: none;
    }

    .area-case-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 4rem;

        @include i.sp {
            margin-top: 2rem;
            //width: 92%;
            margin-left: auto;
            margin-right: auto;
        }

        .box-case {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 22%;
            margin-bottom: 2rem;
            padding-top: 8px;
            background-color: white;
            border: 2px solid white;
            border-radius: 12px;
            text-decoration: none;
            color: i.$gray-800;
            cursor: pointer;
            transition: all, 0.2s;

            @include i.pc {
                width: 32%;
            }

            @include i.sp {
                width: 48%;
            }

            &:hover {
                border: 2px solid i.$keycolor;
                filter: drop-shadow(6px 6px 6px rgba(i.$black, 0.1));
                transition: all, 0.2s;
            }

            div {
                .spn-fz-lg {
                    font-size: 28px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 28px;
                    }
                }

                .spn-fz-md {
                    font-size: 24px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 24px;
                    }
                }

                .spn-fz-mmd {
                    font-size: 24px;
                    font-weight: 500;

                    @include i.sp {
                        font-size: 26px;
                    }
                }

                .spn-fz-msm {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.3;

                    @include i.sp {
                        font-size: 24px;
                    }
                }

                .spn-fz-smsm {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.3;

                    @include i.sp {
                        font-size: 24px;
                    }
                }

                .spn-fz-sm {
                    font-size: 18px;

                    @include i.sp {
                        font-size: 16px;
                    }
                }
            }

            img {
                width: 144px;
                height: 144px;
                padding-bottom: 1rem;
            }

            p {
                display: none;
            }
        }
    }

    .sidebar {
        width: 320px;
        border: 1px solid i.$primary-l;
        padding: 16px;

        @include i.pc {
            width: 300px;
        }

        h3 {
            margin: 1rem 0 0.5rem 0;
            padding: 4px 8px;
            font-weight: 500;
            background-color: i.$primary-l-l;
            //border-bottom: 6px solid i.$primary-l-l;

            &:first-of-type {
                margin-top: 0;
            }

            &::before {
                font-family: "icomoon";
                content: "\e926";
                margin-right: 6px;
                font-size: 12px;
                color: i.$primary-l;
            }
        }

        ul {
            padding-left: 1rem;
            margin-bottom: 1rem;
        }

        a {
            font-size: 16px;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    .frame-sec-down {
        padding-top: 4rem;
    }
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.content-single-cmz {
    .box-author {
        display: block;
        width: 80%;
        margin: 3rem auto;
        padding: 20px;
        background-color: #f8f8f8;
        border-radius: 12px;

        @include i.sp {
            width: 100%;
        }

        span {
            display: block;
            margin-bottom: 1.5rem;
            font-size: 0.8rem;
        }

        .box-outer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            img {
                display: block;
                width: 100px;
                border-radius: 10px;
            }

            .box-r {
                margin-left: 2rem;

                span {
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 18px;
                }

                p {
                    font-size: 0.9rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.widget_recent_entries ul li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_pages ul li a,
.widget_meta ul li a,
.widget_rss ul li a,
.widget_nav_menu ul li a {
    transition: all, 0.2s;
}

.widget_recent_entries ul li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_pages ul li a:hover,
.widget_meta ul li a:hover,
.widget_rss ul li a:hover,
.widget_nav_menu ul li a:hover {
    background-color: i.$primary-l-l;
    transition: all, 0.2s;
}

/* 症状別ページ カスタマイズ */
.cmz-page-treatments {
    .content-in {

        @include i.sp {
            width: 92%;
            margin: 2rem auto;
        }
    }

    .article {
        //width: 80%;
        margin: 0 auto;

        @include i.padpc {
            width: 100%;
        }
    }

    /* WordPress標準 グループdiv などに付くスタイル インナーブロックの幅を調整している */
    .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
        max-width: 90%;

        @include i.sp {
            max-width: 100%;
        }
    }

    .article h1.entry-title {
        justify-content: flex-start;
        margin-bottom: 5rem;
        font-size: 3rem;
        font-weight: 500;

        &::before,
        &::after {
            content: none;
        }

        @include i.pad {
            font-size: 2.4rem !important;
            margin-bottom: 3rem !important;

            &::before,
            &::after {
                font-size: 20px !important;
            }
        }

        @include i.sp {
            padding-left: 8px;
            padding-right: 0;
            font-size: 2rem !important;
            color: white;
            background-color: i.$primary;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .article h2 {
        margin-top: 4rem;
        margin-bottom: 2.2rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 2.2rem;
        border-top: 6px solid i.$primary-l;
        border-bottom: 6px solid i.$primary-l;

        @include i.padpc {
            font-size: 2rem;
        }

        @include i.sp {
            padding-bottom: 4px;
            font-size: 1.8rem;
            //border-bottom: 8px solid i.$primary-l;
        }

        &::before {
            margin-right: 12px;
            color: #ffa585;
            font-family: icomoon;
            font-size: 16px;
            content: "\e926";

            @include i.sp {
                display: none;
            }
        }
    }

    .sec-offer {
        h2 {
            margin-top: 0;
            border-top: none;
            border-bottom: none;

            @include i.sp {
                padding-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }

    .article h3 {
        margin-top: 4rem;
        margin-bottom: 2.2rem;
        padding: 4px 2px 4px 12px;
        font-size: 1.8rem;
        font-weight: normal;
        border-left: 12px solid i.$primary;

        @include i.sp {
            font-size: 1.5rem;
        }
    }

    p {
        line-height: 2;
    }

    .blogcard-wrap {
        width: 64%;

        @include i.sp {
            width: 90%;
        }

        .blogcard-title {
            font-size: 1.2rem;
            font-weight: normal;
        }

        .blogcard-site {
            //display: none;
        }
    }

    .box-line-tomo {
        width: 80%;
        margin: 2rem auto 3rem;
        padding: 24px 36px;
        text-align: center;

        @include i.padpc {
            width: 90%;
        }

        @include i.sp {
            width: 100%;
            padding: 12px 18px;
        }

        p {
            margin-top: 0;
            padding: 4px;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.5;
            background-color: rgba(white, .8);
            border-radius: 10px;

            @include i.sp {
                font-size: 1.2rem;
                text-align: center;
            }
        }

        .box-btns {
            display: flex;
            justify-content: space-around;
            align-items: center;

            @include i.sp {
                flex-direction: column;
            }
        }

        a {
            text-decoration: none;

            @include i.padpc {
                transform: scale(.9);
            }

            @include i.sp {
                transform: scale(.8);
            }
        }

        .btn-tel {
            //margin: 0 36px;
        }
    }

    .area-voice,
    .area-salespoint {
        margin-top: 4rem;
        padding: 1px 0 4rem;

        .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
            max-width: auto;

            @include i.sp {
                max-width: 94%;
            }
        }

        h2,
        h3 {
            background-color: white;

            @include i.pad {
                margin-top: 2rem;
            }
        }

        .wrap-padding {
            margin-top: 24px;
            padding-top: 1px;
            padding-bottom: 24px;
        }

        .inner-wrap {
            //width: 80%;
            //margin: 0 auto;

            @include i.pad {
                //width: 90%;
            }
        }
    }

    .area-counseling {
        margin-top: 3rem;
        padding: 2rem;

        @include i.sp {
            padding: 1rem;
        }

        h2 {
            margin-top: 0;
            text-align: center;
            border-width: 3px;
            border-color: i.$keycolor;
            background-color: white;

            @include i.pad {
                margin-top: 2rem;
            }

            &::before {
                display: none;
            }
        }

        .box-counseling-outer {
            padding-bottom: 3rem;
            border: 4px solid white;
            background-color: rgba(white, .8);

            @include i.sp {
                padding-left: 12px;
                padding-right: 12px;
            }
        }

        .box-line-tomo {
            p {
                font-size: 1.2rem;
            }
        }
    }

    .box-related-treatment-outer {
        margin-top: 4rem;

        .blogcard-wrap {
            width: 100%;
        }

        .blogcard-thumbnail {
            float: none;
            width: 100%;
        }

        figure {
            margin: 0 0 12px 0;
        }

        .blogcard-content {
            margin-left: auto;
        }

        .blogcard-title {
            margin-bottom: 1rem;
            line-height: 1.3;
        }
    }
}

/* ------------------------------ 貼り付けショップ情報 */

.shopinfo-post {
    display: block;
    position: relative;
    margin: 6rem auto 2rem;

    &::before {
        position: absolute;
        top: -86px;
        left: 50%;
        transform: translateX(-50%);
        color: #ff662e;
        font-family: icomoon;
        font-size: 60px;
        content: "\e924";
    }

    .box-outer {
        display: block;
        width: 90%;
        margin: 2rem auto;
        padding: 40px;
        border: 1px solid #ff662e;
        border-radius: 10px;

        @include i.tab {
            width: 100%;
            padding: 20px;
        }

        @include i.sp {
            padding: 20px 8px;
        }
    }

    .box-upper {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        text-align: right;

        span {
            display: block;
            margin-bottom: 0.7rem;
            border-bottom: 2px dotted #ff662e;
            font-size: 1.1rem;
            font-weight: 500;
            text-align: left;
        }
    }

    table {
        width: 90%;
        margin: 0 auto 1rem;

        @include i.sp {
            width: 100%;
        }

        tr:nth-of-type(2n + 1) {
            background: none;
        }

        tr {
            border-bottom: 1px dotted #ff662e;
        }

        td {
            font-size: 0.9rem;

            @include i.ssp {
                display: block;
            }

            a {
                @include i.sp {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .box-lower {
        font-size: 0.9rem;

        strong {
            display: block;
            margin-bottom: 0.5rem;
            color: #ff662e;
            font-size: 1.1rem;
        }
    }

    .box-dm-up {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 20px auto 0;

        @include i.sp {
            flex-direction: column;
        }

        a {
            text-decoration: none;
        }
    }

    .box-dm-tel,
    .box-dm-mail {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        height: 64px;
        padding: 6px 30px;
        font-size: 21px;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.2s;

        @include i.sp {
            justify-content: center;
            width: 80%;
            margin-bottom: 1rem;
        }

        &::before {
            margin-right: 6px;
            font-family: icomoon;
            font-size: 18px;
        }
    }

    .box-dm-tel {
        background-color: #fff0eb;
        color: #cc3600;

        &:hover {
            background-color: #ffa585;
        }

        &::before {
            content: "\e918";
        }
    }

    .box-dm-mail {
        background-color: #f1f8f5;
        color: #43896b;

        &:hover {
            background-color: #bbddce;
        }

        &::before {
            content: "\e916";
        }
    }
}


/* ------------------------------ ページネーション */

.pagination-next-link {
    border-color: #fff0eb;
    transition: all, 0.2s;

    &:hover {
        background-color: #fff0eb;
        transition: all, 0.2s;
    }
}

.pagination {
    a {
        &:hover {
            background-color: #fff0eb;
            transition: all, 0.2s;
        }
    }
}

.pagination .current {
    background-color: #ffa585;
}

.page-numbers {
    border-color: #fff0eb;
    transition: all, 0.2s;
}

/* ------------------------------------------------------------ シェアボタン　アイコンフォント変更 */

.share-button.twitter-button {
    .icon-twitter {
        &::before {
            content: "\e922";
        }
    }
}

.share-button.facebook-button {
    .icon-facebook {
        &::before {
            content: "\e90b";
        }
    }
}

.share-button.line-button {
    .icon-line {
        &::before {
            content: "\e929";
        }
    }
}

.share-button.copy-button {
    .icon-copy {
        &::before {
            content: "\e92d";
        }
    }
}


/* ------------------------------------------------------------------------------------------ 予約ページ */

.box-res-header {

    @include i.sp {
        img {
            max-width: 90%;
        }
    }
}

.page-id-10 {
    .breadcrumb {
        display: none;
    }
}

.p-res {
    display: block;
    width: 100%;
    margin: 1.2rem auto !important;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;

    @include i.tab {
        width: 92%;
    }
}

.box-res-time {
    width: 40%;
    margin: 0 auto 1rem;
    text-align: center;

    @include i.pad {
        width: 50%;
    }

    @include i.sp {
        width: 90%;
    }

    .spn-icon-clock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: auto;
        margin: 0 auto 12px;
        font-size: 16px;

        &::before {
            margin-right: 6px;
            font-family: icomoon;
            font-size: 16px;
            content: "\e909";
        }
    }

    table {
        tr:nth-of-type(2n + 1) {
            background: none;
        }

        tr:first-of-type {
            background-color: #ffa585;
        }

        td {
            font-size: 14px;
        }
    }

    table.box-schedule-s {
        .spn-color {
            color: #ff662e;
        }
    }

    table.box-schedule-p {
        tr:first-of-type {
            background-color: #bbddce;
        }

        .spn-color {
            color: #73ba9b;
        }
    }

    .box-hpwari {
        padding: 12px 1rem;
        font-size: 1.2rem;
        border: 1px solid i.$tertiary;
        border-radius: 6px;

        .spn-att {
            color: i.$red;
            font-size: 1.5rem;
        }

        .spn-sm {
            font-size: 1rem;
            //line-height: 3;

            &::before {
                margin-right: 6px;
                font-family: icomoon;
                font-size: 24px;
                content: "\e91f";
                color: i.$tertiary;
            }

            &::after {
                margin-left: 6px;
                font-family: icomoon;
                font-size: 24px;
                content: "\e91f";
                color: i.$tertiary;
            }
        }
    }
}

.box-res-time-brd {
    padding-top: 1.5rem;
    border-top: 1px solid i.$keycolor-l;
    border-bottom: 1px solid i.$keycolor-l;
}

.box-res-time-reserve {
    table {
        tr:first-of-type {
            background-color: #fdd7c9;

            td {
                width: 33.333%;
                font-size: 20px;

                &:nth-child(2) {
                    border-left: 2px solid white;
                    border-right: 2px solid white;
                }
            }
        }

        td {
            vertical-align: top;
        }

        .td-hour {
            padding: 0 30px;

            @include i.sp {
                padding: 0 10px;
            }
        }
    }

    table.box-schedule-s {
        .spn-color {
            padding: 1rem 0;
            font-size: 20px;
            color: #ec4b10;
        }
    }

    p {
        font-size: 1rem;
        text-align: left;
    }

    span {
        font-size: 1.2rem;
        color: i.$red;
    }
}

.box-res-notice {
    display: block;
    width: 80%;
    margin: 4rem auto 2rem;
    padding: 20px;
    border: 1px solid i.$res-color;

    @include i.tab {
        width: 92%;
    }

    p {
        margin: 0;
        font-size: 0.9rem;
    }
}

.box-sitelogo-res {
    display: block;
    width: 90%;
    margin: 0 auto;
    text-align: center;

    div {
        display: inline-block;
        width: 30%;
        margin: 0 2rem;

        @include i.pad {
            width: 40%;
        }

        @include i.tab {
            display: block;
            width: 80%;
            margin-bottom: 1rem;
        }
    }

    .btn-fjs,
    .btn-fjp {
        display: block;
        padding: 10px 20px;
        border-radius: 10px;
        transition: all, 0.2s;

        &:hover {
            transition: all, 0.2s;
        }
    }

    .btn-fjs {
        border: 1px solid i.$primary;

        &:hover {
            background-color: i.$primary-l-l;
        }
    }

    .btn-fjp {
        border: 1px solid i.$keycolor;

        &:hover {
            background-color: i.$keycolor-l-l;
        }
    }

    img {
        height: 40px;
    }

    span {
        display: block;
        margin-top: 0.5rem;
        font-size: 0.9rem;
    }
}

.footer-res {
    padding: 40px 0;
    text-align: center;
    color: white;
    font-size: 0.9rem;
    background-color: i.$res-color;
}

.ea-bootstrap {
    width: 92%;
    margin: 2rem auto !important;
}


/* ------------------------------------------------------------------------------------------ プライバシーポリシー */

.privacy-box,
.kiyaku-box {
    //width: 90%;
    //margin: 2rem;
    font-size: 16px;

    @include i.pc {
        width: 90%;
        padding: 0 1rem;
        margin: 2rem 0;
    }

    @include i.pad {
        width: 100%;
        padding: 0 1rem;
        margin: 2rem 0;
    }

    @include i.sp {
        padding: 0 8px;
    }

    p {
        padding-left: 40px;
        margin-bottom: 2rem;
        line-height: inherit;
    }
}

/* ------------------------------------------------------------------------------------------ コンタクトフォーム７　ContactForm7 */

.inquiry-sec {
    margin: 0 auto;

    @include i.tab {
        padding: 30px;
    }

    @include i.sp {
        padding: 30px 8px;
    }

    .haveto {
        padding: 4px 6px;
        border-radius: 4px;
        background-color: i.$primary;
        color: #fff;
        font-size: 10px;
        line-height: 1;
    }

    table {
        tr:nth-of-type(2n + 1) {
            background: none;
        }

        tr {
            display: flex;
            flex-direction: row;
            margin-bottom: 1.5rem;

            @include i.sp {
                flex-direction: column;
            }
        }

        th {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            margin-right: 1rem;
            color: i.$gray-800;
            font-weight: normal;
            text-align: left;
            border: none;
            background: white;

            @include i.sp {
                width: 140px;
            }
        }

        td {
            width: 80%;
            padding: 0;
            border: none;

            @include i.sp {
                width: 100%;
            }
        }

        span.wpcf7-list-item {
            //display: block;
            margin-right: 1rem;
            margin-bottom: 1rem;
            position: relative;

            @include i.sp {
                display: block;
            }
        }

        span.wpcf7-list-item.first {
            margin-top: 1rem;
        }

        span.wpcf7-list-item.last {
            margin-bottom: 0;
        }
    }

    input::placeholder {
        color: i.$gray-500;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
        border: 2px solid i.$gray-300;
        font-family: i.$font-family-base;

        &:focus {
            border: 2px solid i.$primary;
        }
    }

    textarea {
        margin-top: 0;
    }

    .wpcf7-form-control-wrap {
        margin-right: 1rem;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-right: 5px;
        opacity: 1;
        width: 26px;
        height: 26px;
        border: 1px solid i.$primary;
        border-radius: 3px;
        appearance: none;
        position: absolute;
    }

    input[type="radio"]+span,
    input[type="checkbox"]+span {
        margin-left: 34px;
    }

    input[type="radio"]:checked,
    input[type="checkbox"]:checked {
        background: i.$primary;
    }

    .wpcf7-form-control-wrap {
        margin-right: 1.5rem;
    }

    .wpcf7-acceptance {
        .wpcf7-list-item {
            display: inherit;
            margin: 0 20px 0 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            margin-right: 5px;
            opacity: 1;
            width: 26px;
            height: 26px;
            border: 1px solid i.$primary;
            border-radius: 3px;
            appearance: none;
            position: absolute;
        }
    }

    #formbtn {
        display: block;
        padding: 15px;
        margin: 25px 0;
        width: 350px;
        color: white;
        font-size: 2rem;
        background: i.$keycolor;
        border: 2px solid i.$keycolor;
        border-radius: 2px;
        transition: 0.3s;

        @include i.tab {
            margin: 25px auto;
        }

        @include i.sp {
            width: 100%;
        }
    }

    #formbtn:hover {
        background: i.$keycolor-l;
        color: i.$keycolor-d;
        border: 2px solid i.$keycolor;
    }

    .submit-check-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: auto;

        @include i.sp {
            font-size: 15px;
        }
    }

    .submit-send-box {
        display: flex;
        justify-content: flex-end;

        @include i.tab {
            justify-content: center;
        }
    }

    .submit {
        position: relative;

        .ajax-loader {
            position: absolute;
            bottom: -1rem;
        }
    }

    .contact-notice-footer {
        margin-top: 20px;
    }

    .box-acceptance {
        display: flex;
        flex-direction: row;
    }
}

.thanks {
    margin-top: 0;
    margin-bottom: 0;
}

/* ------------------------------------------------------------ reCAPTCHA */

.box-recaptcha-outer {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px dotted i.$primary;
}

.box-recaptcha {
    display: block;
    margin-top: 2rem;
    font-size: 0.7rem;

    @include i.sp {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    a {
        color: inherit;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

/* ------------------------------------------------------------------------------------------ サイドバー */

/* ------------------------------------------------------------------------------------------ フッター */

.footer {
    display: block;
    background-image: url(../img/parts/frame-bottom.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    @include i.pad {
        margin-bottom: 46px;
        background-image: url(../img/parts/frame-bottom_t.svg);
    }

    @include i.sp {
        background-image: url(../img/parts/frame-bottom_s.svg);
    }
}

.footer-bottom {
    font-size: 12px;
}

.footer-widgets {
    padding-bottom: 40px;

    @include i.pc {
        padding: 20px;
    }

    @include i.pad {
        display: inherit;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0;
    }
}

.footer-widgets>div {
    @include i.pad {
        width: 85%;
        margin: 0 auto;
    }

    @include i.sp {
        width: 100%;
    }
}


.footer-bottom.fdt-up-and-down .footer-bottom-content {
    padding-right: 1rem;
    color: white;
    text-align: center;

    @include i.pad {
        color: inherit;
    }
}


.box-footer-logo {
    width: 100%;
    text-align: center;

    @include i.sp {
        width: 90%;
        margin: 0 auto;
    }

    img {
        margin: 1rem auto 3rem;
        width: 380px;

        @include i.sp {
            margin-bottom: 1rem;
        }
    }
}

.footer-left,
.footer-right {
    width: 49%;
}

.footer-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid i.$primary;
    border-radius: 12px;
}

.dmfooter {
    position: inherit;
    justify-content: center;
    width: auto;
    //border-bottom: 1px dotted i.$primary;
    border-radius: 0;
    background: none;

    @include i.pad {
        display: inherit;
    }

    .box-drawermenu {
        flex-direction: column-reverse;
        width: auto;
        padding: 12px 0;
        border-radius: 0;
        background: none;

        .box-dm-up {
            flex-direction: column;

            .box-dm-tel {
                width: 90%;
                justify-content: center;
                margin-bottom: 2rem;
            }

            .box-dm-mail {
                width: 90%;
                justify-content: center;
            }
        }

        .spn-sm {
            font-size: 12px;
        }

        .box-btn-extres {
            display: block;
            width: auto;
            margin: 1rem auto;
            text-align: center;

            a {
                width: 100%;
                margin: 0 auto 1rem;
            }

            img {
                width: 360px;

                @include i.sp {
                    width: 280px;
                }
            }
        }
    }

    .spn-notice {
        display: none;
    }

    .spn-notice_footer {
        display: block;
        width: 100%;
        margin-top: 2rem;
        padding-bottom: 8px;
        text-align: center;
        border-bottom: 1px dotted i.$primary;

        &::after {
            display: inline-block;
        }
    }

    .spn-notice2_footer {
        display: block;
        margin-top: 8px;
        font-size: .9rem;
    }
}

.box-footer-info {
    width: 100%;
    margin: 0 auto 2rem;

    ul {
        padding: 0;

        @include i.sp {
            width: 92%;
            margin: 0 auto;
        }
    }

    li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 0;
        list-style: none;
        line-height: 1.4;

        &::before {
            font-family: "icomoon";
            content: "\e909";
            margin-right: 18px;
            font-size: 22px;
            color: i.$primary;
        }

        &:first-of-type {
            border-bottom: 1px dotted i.$primary;
        }

        &:last-of-type {
            border-top: 1px dotted i.$primary;
        }
    }

    .li-footer-map {
        &::before {
            content: "\e913";
        }
    }

    .li-footer-walk {
        &::before {
            content: "\e923";
        }

        a {
            font-size: 0.9rem;
        }
    }

    .li-footer-car {
        span {
            display: block;
            width: 100%;
        }

        .spn-sm {
            margin-top: 6px;
            font-size: 12px;
        }

        &::before {
            content: "\e906";
        }
    }

    .box-img-map {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(390 / 520 * 100%);
        /* calc(画像高さ ÷ 画像横幅 × 100%) */
        background-image: url(../img/image/map.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include i.ssp {
            width: 86%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


.footer-right {
    padding-left: 80px;

    @include i.tab {
        padding: 30px;
    }

    @include i.tab {
        padding: 10px;
    }

    .area-footer-sns {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px dotted i.$primary;

        @include i.pad {
            margin-top: 2rem;
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 60%;
            border-radius: 10px;
            margin-bottom: 1rem;
            text-decoration: none;
            color: i.$gray-800;
            background-color: white;
            transition: all, 0.2s;

            @include i.pc {
                width: 80%;
            }

            @include i.pad {
                width: 50%;
                margin: 0 auto 1rem;
            }

            @include i.sp {
                width: 80%;
            }

            &:hover {
                filter: drop-shadow(4px 4px 4px rgba(i.$black, 0.2));
                transition: all, 0.2s;
            }

            &::before {
                font-family: "icomoon";
                margin-right: 12px;
                font-size: 22px;
            }
        }

        .btn-twt {
            padding: 2px 14px;
            border: 1px solid i.$twitter;

            &::before {
                content: "\e922";
                color: i.$twitter;
            }
        }

        .btn-line {
            padding: 2px 14px;
            border: 1px solid i.$line;

            &::before {
                content: "\e929";
                color: i.$line;
            }
        }

        .btn-fcb {
            padding: 4px 16px;
            border: 1px solid i.$facebook;

            &::before {
                content: "\e90b";
                color: i.$facebook;
            }
        }

        .btn-ytb {
            padding: 4px 16px;
            border: 1px solid i.$red;

            &::before {
                content: "\e917";
                color: i.$red;
            }
        }

        .btn-isg {
            padding: 4px 16px;
            border: 1px solid #CF2E92;

            &::before {
                content: "\ea92";
                color: #CF2E92;
            }
        }
    }

    .widget_nav_menu {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px dotted i.$primary;

        ul li {
            width: 100%;
        }

        ul li a {
            width: 100%;
            padding: 4px;
            transition: all, 0.2s;

            &:hover {
                background-color: i.$primary-l-l;
                transition: all, 0.2s;
            }

            &::before {
                font-family: "icomoon";
                content: "\e915";
                margin-right: 6px;
                font-size: 16px;
                color: i.$primary;
            }
        }
    }

    .box-footer-right2 {
        .area-othershop {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @include i.sp {
                justify-content: center;
                align-items: center;
            }

            .box-othershop-here {
                display: block;
                width: 180px;
                padding: 8px;
                margin-bottom: 1rem;
                color: white;
                font-size: 16px;
                line-height: 1.3;
                text-align: center;
                background-color: i.$pirates-color;
                position: relative;

                img {
                    width: 40px;
                    position: absolute;
                    bottom: -28px;
                    left: 16px;
                }

                .box-image-here {
                    width: 40px;
                    position: absolute;
                    bottom: -28px;
                    left: 16px;
                    height: 30px;
                    //padding-top: calc(80 / 580 * 100%); /* calc(画像高さ ÷ 画像横幅 × 100%) */
                    background-image: url(../img/parts/here-g.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
            }

            .btn-othershop {
                display: block;
                width: 300px;
                padding: 8px;
                border: 1px solid i.$pirates-color;
                border-radius: 10px;
            }

            img {
                width: 300px;
            }

            .box-image-logo {
                //isplay: block;
                width: 100%;
                height: 0;
                padding-top: calc(80 / 580 * 100%);
                /* calc(画像高さ ÷ 画像横幅 × 100%) */
                background-image: url(../img/parts/fs-p_logo_full_gr.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
    }
}

/* ------------------------------------------------------------ モバイル用フッターメニュー */

.mobile-footer-menu-buttons.mobile-menu-buttons {
    z-index: 1200 !important;
}

.mobile-footer-menu-buttons {
    .menu-button:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: i.$primary;
        min-height: 50px;
    }

    .menu-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: i.$keycolor;
        min-height: 50px;
    }

    .menu-button:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: i.$secondary-d;
        min-height: 50px;
    }

    .fa {
        font-size: 24px;
    }

    .menu-close-button {
        font-size: 1.2rem;
        color: white;
    }

    .menu-content {
        background-color: rgba(i.$primary-d-d, 0.9);

        .site-logo-image {
            margin-top: 2rem;
        }
    }

    .menu-drawer a {
        margin-bottom: 12px;
        color: white;
        font-size: 1.2rem;
    }
}

.menu-button-in {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
}

.mobile-menu-buttons .menu-button>a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
}

.mobile-menu-buttons .menu-icon {
    width: auto;
}

.mobile-menu-buttons .menu-caption {
    width: auto;
    margin-left: 4px;
    font-size: 15px;
    font-weight: 700;
    opacity: 1;
}

#menu-cmz-footer-bobile-menu {
    @include i.tab {
        z-index: 300 !important;
    }
}

/* ------------------------------------------------------------ モバイル用ドロワーメニュー */

.mobile-footer-menu-buttons .menu-content {
    .site-name-text {
        display: block;
        width: 240px;
        margin: 0 auto 1rem;
    }

    .spn-icon-clock {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        margin: 0 auto;
        font-size: 16px;

        &::before {
            font-family: "icomoon";
            content: "\e909";
            margin-right: 6px;
            font-size: 16px;
        }
    }

    .box-schedule {
        width: 90%;
        margin: 0 auto 2rem;
    }

    table {
        tr:nth-of-type(2n + 1) {
            background: none;
        }

        td {
            padding: 6px;
            text-align: center;
            border: 1px solid i.$gray-400;
        }
    }

    .navi-menu-close-button {
        margin-bottom: 2rem;
    }
}

.mobile-footer-menu-buttons .menu-drawer {
    a {
        font-size: 1rem;
        margin-bottom: 4px;

        &::before {
            font-family: "icomoon";
            content: "\e926";
            margin-right: 6px;
            font-size: 8px;
            color: white;
            opacity: 0.8;
        }
    }
}

/* ------------------------------------------------------------------------------------------ 本文その他 */
/* ------------------------------------------------------------ 店内ストリートビュー */
.ifrm-gglsv {
    display: block;
    width: 80%;
    margin: 3rem auto 2rem;

    @include i.pad {
        width: 96%;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    @include i.sp {
        width: 98%;
    }

    figcaption {
        text-align: center;
    }

    iframe {
        width: 100%;
        height: 600px;

        @include i.pad {
            height: 450px;
        }

        @include i.sp {
            height: 300px;
        }
    }

}



/* ------------------------------ シェアボタン */

.ss-top .sns-share-buttons a .button-caption,
.ss-bottom .sns-share-buttons a .button-caption {
    @include i.sp {
        display: none;
    }
}

/* ------------------------------------------------------------ トップへ戻る */

.go-to-top-button {
    width: 60px;
    height: 60px;
    background-color: i.$primary-d;
    border: 2px solid white;
    border-radius: 50%;
    transition: all, 0.2s;

    &:hover {
        color: white;
        background-color: i.$primary-l;
        transition: all, 0.2s;
    }
}

/* ------------------------------------------------------------ SNSボタン */

/* ------------------------------------------------------------------------------------------ 問い合わせボタン */

/* ------------------------------------------------------------------------------------------ その他 */

/* ------------------------------------------------------------------------------------------ 印刷用 */

.unghorst-print {
    display: none;
}

@media print {
    body {
        background-color: white;
    }

    .sec-myfile {
        .unghorst-print {
            display: block;

            th {
                width: 300px;
            }

            td {
                width: 80px;
                text-align: center;
            }
        }

        .ghorst-print {
            display: none;
        }

        table {
            font-size: 8px;
        }

        tr:nth-of-type(2n + 1) {
            background-color: white;
        }

        th,
        td {
            padding-top: 0;
            padding-bottom: 0;
        }

        .fs-s {
            font-size: 7px;
        }
    }
}

/*! purgecss end ignore */
